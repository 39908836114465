<template>
  <v-card>
    <v-card-title>
      {{ formTitle }}
    </v-card-title>

    <v-card-text v-if="loaded">
      <v-form @submit.prevent="submit">
        <!-- last_checked_at -->
        <span v-if="editedItem.last_checked_at">Last checked {{ lastCheckedMinutesAgo }} min ago</span>
        <span v-else class="red--text">Never checked</span>
        <v-checkbox v-model="editedItem.enabled" label="Enabled"></v-checkbox>
        <v-text-field
          v-model="editedItem.name"
          label="Name"
          required
        ></v-text-field>
        <v-text-field
          v-model="editedItem.notes"
          label="Notes"
        ></v-text-field>
        <v-select
          v-model="editedItem.triggered_actions"
          :items="getActionsOptions"
          label="Triggered Actions"
          multiple
          required
        ></v-select>
        <!-- template_script select-->
        <v-select
          v-model="editedItem.template_script"
          :items="getTriggerScriptsOptions"
          label="Trigger Script"
          @change="updateSchema"
          required
        ></v-select>

        <schema-form
          ref="schemaForm"
          @refetchSchema="updateSchema"
          v-model="editedItem.parameters"
          :schema="currentSchema"
        />
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-btn v-if="!hideClose" color="error" text @click="close">Cancel</v-btn>
      <v-btn v-if="!hideSave" :disabled="!loaded" color="primary" text @click="save">Save</v-btn>
    </v-card-actions>
    
    <v-progress-circular class="triggerFormSpinner" v-if="!loaded" indeterminate color="primary"
      size="100"></v-progress-circular>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SchemaForm from "./SchemaForm.vue";
export default {
  components: { SchemaForm },
  props: {
    triggerID: {
      type: Number,
      default: -1, // -1 means new action
    },

    formTitle: {
      type: String,
      default: "Action",
    },
    hideSave: {
      type: Boolean,
      default: false,
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {loaded: false,
      editedItem: {
        enabled: true,
        name: "",
        notes: "",
        triggered_actions: [],
        parameters: {},
      },
      defaultItem: {
        enabled: true,
        name: "",
        notes: "",
        triggered_actions: [],
        parameters: {},
      },
      currentSchema: {},
    };
  },
  watch: {
    triggerID: {
      handler: async function () {
        await this.loadForm();
      },
      deep: true,
    },
  },
  async mounted() {
    await this.loadForm();
  },
  computed: {
    ...mapGetters([
      "getTriggerScriptsOptions",
      "getApiAccountsOptions",
      "getActionsOptions",
      "getTriggerById",
      "getActionById",
    ]),
    
    lastCheckedMinutesAgo() {
      if (this.editedItem.last_checked_at) {
        var cDate = new Date(this.editedItem.last_checked_at);
        var diff = new Date() - cDate;
        var minutes = Math.floor(diff / 60000);
        return minutes;
      }
      return "";
    },
  },
  methods: {
    ...mapActions([
      "fetchTriggers",
      "fetchTriggerScripts",
      "fetchApiAccounts",
      "createTrigger",
      "updateTrigger",
      "deleteTrigger",
      "fetchTriggerSchema",
      "fetchActions",
    ]),
    async loadForm() {
      if (this.triggerID) {
        const cAction = this.getTriggerById(this.triggerID);

        this.editedItem = Object.assign({}, cAction);
        await this.updateSchema();
        this.editedItem.parameters = cAction.parameters || {};
      } else {
        this.editedItem = Object.assign({}, this.defaultItem);
        await this.updateSchema();
      }
    },
    async updateSchema() {
      this.loaded = false;
      this.currentSchema = await this.fetchTriggerSchema({
        triggerScriptClass: this.editedItem.template_script,
        params: {
          ...this.editedItem.parameters,
        },
      });
      this.loaded = true;
      /*var cTriggerScript = this.$store.state.triggerScripts.find(
                    (x) => x.class_name === this.editedItem.template_script
                );
                this.currentSchema = cTriggerScript.schema;*/
    },
    close() {
      this.$emit("close");
    },
    async save() {
      if (this.triggerID !== -1) {
        await this.updateTrigger(this.editedItem);
      } else {
        await this.createTrigger(this.editedItem);
      }
      this.close();
    },
  },
};
</script>

<style>
.triggerFormSpinner {
  left: 50%;
  margin-top: 50px;
  margin-left: -50px;
  margin-bottom: 50px;
}
</style>