<template>
  <div>
        <v-select
            v-model="apiAccountId"
            :items="getApiAccountsOptions"
            label="Account">
          </v-select>
  
    <v-tabs v-model="tab" bg-color="primary">
      <v-tab
        v-for="subPortfolio of Object.keys(portfolios)"
        :key="subPortfolio"
        @click="updateViewedTab(subPortfolio)"
        >{{ subPortfolio }}</v-tab
      >
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="subPortfolioKey of Object.keys(portfolios)"
        :key="subPortfolioKey"
      >
        <v-card>
          <v-card-title>{{ subPortfolioKey }}</v-card-title>
          <v-card-text style="width: 200px">
            <canvas :ref="subPortfolioKey + '_chart'"></canvas>
          </v-card-text>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="portfolios[subPortfolioKey]"
              :items-per-page="5"
              class="elevation-1"
            ></v-data-table>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
  
  <script>
import { mapGetters, mapActions } from "vuex";

import Chart from "chart.js/auto";
export default {
  data() {
    return {
      apiAccountId: 2,
      tab: null,

      chartInstance: null,
    };
  },
  watch: {
    apiAccountId(newVal) {
      this.$store.dispatch("fetchAccountsPortfolio", newVal);
      console.log("apiAccountId changed", this.portfolios);
    },
  },
  methods: {
    ...mapActions(["fetchApiAccounts"]),
    updateViewedTab(portfolioKey) {
      this.chartInstance.destroy();
      this.chartInstance = null;
      if (portfolioKey) {
        setTimeout(() => {
          this.createChart(portfolioKey);
        }, 400);
      }
    },
    createChart(portfolioKey) {
      console.log("creating chart", portfolioKey);
      if (!this.chartInstance) {
        const canvas = this.$refs[portfolioKey + "_chart"];

        let notEmptyPortfolios = [];
        if (this.portfolios[portfolioKey])
          notEmptyPortfolios = this.portfolios[portfolioKey].filter(
            (asset) => asset.walletBalance > 0
          );
        const labels = notEmptyPortfolios.map((asset) => asset.coin);
        const data = notEmptyPortfolios.map((asset) => asset.usdtValue);
        this.chartInstance = new Chart(canvas, {
          type: "doughnut",
          data: {
            labels,
            datasets: [
              {
                label: "Total Portfolio Value USDT",
                backgroundColor: [
                  "#FF6384",
                  "#36A2EB",
                  "#FFCE56",
                  "#32CD32",
                  "#8B008B",
                  "#FF4500",
                  "#9932CC",
                  "#00BFFF",
                  "#FFD700",
                  "#808080",
                ],
                data,
              },
            ],
          },
        });
      }
    },
  },
  async mounted() {
    console.log("portfolioview mounted");
    //await this.fetchApiAccounts();
    const firstShownTab = Object.keys(this.portfolios)[0];
    this.createChart(firstShownTab);
    console.log(this.apiAccountId);
    console.log("Portfolios:", Object.keys(this.portfolios));
  },
  computed: {
    ...mapGetters(["getPortfolioByApiAccountId", "getApiAccountsOptions"]),

    portfolios() {
      if (!this.apiAccountId) {
        return [];
      }
      return this.getPortfolioByApiAccountId(this.apiAccountId);
    },
    headers() {
      return [
        {
          text: "Total wallet balance",
          value: "walletBalance",
        },
        {
          text: "Coin",
          value: "coin",
        },
        {
          text: "Free",
          value: "free",
        },
        {
          text: "Locked",
          value: "locked",
        },
        {
          text: "USDT value",
          value: "usdtValue",
        },
      ];
    },
  },
};
</script>
  
  <style scoped>
.v-data-table__wrapper {
  overflow-x: auto;
}
</style>
  