import Vue from 'vue';
import VueRouter  from 'vue-router';
import LoginPage from '@/components/LoginPage.vue';
import DashboardPage from '@/components/DashboardPage.vue';
import ApiAccountsPage from '@/components/ApiAccountsPage.vue';
import ActionsView from '@/components/ActionsView.vue';
import TriggersView from '@/components/TriggersView.vue';
import ChartPage from '@/components/ChartPage.vue';
import PortfolioView from '@/components/PortfolioView.vue';
import EventView from '@/components/EventView.vue';
import PositionsView from '@/components/PositionsView.vue';
import StreamView from '@/components/StreamView.vue';

Vue.use(VueRouter );

export default new VueRouter ({
  routes: [
    {path: '/', redirect: '/chart'},
    // add other routes here
    {
        path: '/login',
        name: 'Login',
        component: LoginPage,
    }, 
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: DashboardPage,
    },
    {
        path: '/dashboard/:id',
        name: 'DashboardId',
        component: DashboardPage,
    
    },
    // api-accounts page
    {
        path: '/api-accounts',
        name: 'ApiAccounts',
        component: ApiAccountsPage,
    },
    // actions page
    {
        path: '/actions',
        name: 'Actions',
        component: ActionsView,
    },
    // trigger page
    {
        path: '/triggers',
        name: 'Triggers',
        component: TriggersView,
    },
    {
        path: '/events',
        name: 'Events',
        component: EventView,
    
    },
    //positions page
    {
        path: '/positions',
        name: 'Positions',
        component: PositionsView,
    },
    // chart page
    {
        path: '/chart',
        name: 'Chart',
        // forward to default chart
        redirect: '/chart/bybit/SPOT/BTCUSDT/1d',
    },
    {
        path: '/chart/:exchange/:market/:symbol/:timeframe',
        name: 'ChartPage',
        component: ChartPage,
    },
    // portfolio page
    {
        path: '/portfolio',
        name: 'Portfolio',
        component: PortfolioView,
    },
    // stream view
    {
        path: '/stream',
        name: 'Stream',
        component: StreamView,
    },

    //logout
    {
        path: '/logout',
        name: 'Logout',
        component: LoginPage,
    },
  ],
});
