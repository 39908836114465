import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
    // generates a key for OHLCV dataset
    function getOHLCVEntryKey(exchange, market, symbol, timeframe) {
        return `${exchange}|${market}|${symbol}|${timeframe}`
    }
        // filters data by timestamp
        function filterDataByTime(data, timestamp, isEarlier) {
            const compareFunc = isEarlier ? (a, b) => a[0] < b[0] : (a, b) => a[0] > b[0]
            return data.filter(item => compareFunc(item, [timestamp]))
        }
const errorHandler = (error, commit) => {
    let errorLog = error.message;

    if (error.message)
        errorLog += " | " + error.message

    if (error.response && error.response.data) {
        // display data properly
        if (typeof error.response.data === 'object') {
            //pretty print json
            errorLog += " | " + JSON.stringify(error.response.data, null, 2)
        } else {
            //errorLog += " | " + error.response.data
        }
    }
    commit('showSnackbar', { text: 'Error loading data | ' + errorLog, color: 'error' })
    console.log("error:", error)
}

let cEnv = process.env.NODE_ENV
console.log('cEnv', cEnv)
console.log("process.env", process.env)

//cEnv = 'production'

//const devBackendUrl = 'http://localhost:8000'
const devBackendUrl = 'https://api.plutos.ujma.it'

let backendUrl = process.env.VUE_APP_PLUTOS_BACKEND_URL//'https://plutosapi.darcade.de'

if (cEnv == 'development') {
    backendUrl = devBackendUrl
}
console.log('backendUrl', backendUrl)
/*
if (cEnv == 'staging') {
    backendUrl = 'https://staging.plutosapi.darcade.de'
} else if (cEnv == 'development') {
    backendUrl = devBackendUrl
}*/


const state = {
    backendUrl: backendUrl,
    users: [],
    apiAccounts: [],
    triggers: [],
    actions: [],
    drawings: [],
    events: [],
    exchanges: [],
    actionScripts: [],
    triggerScripts: [],
    ohlcv: [],
    exchangesDetails: {},
    chartData: [],
    selectedPosition: {
        accountId: '',
        market: '',
        symbol: '',
    },
    // snackbar properties used for display of errors and success messages
    snackbarVisible: false,
    snackbarColor: 'success',
    snackbarText: '',
    snackbarTimeout: 3000,
    dashboards: [],
    selectedDashboard: null,
    loadingCounter: 0,
    orderHistory: {},
    positions: {},
    portfolios: {},
    displayTriggerLineModal: false,
    triggerLineModalData: null,
    chartDataSource: {
        market: 'SPOT',
        symbol: 'BTCUSDT',
        exchange: 'bybit',
        timeframe: '1d'
    },
    tradingStops: {},
    currentTradingDestination: {
        exchange: 'bybit',
        market: 'SPOT',
        symbol: 'BTCUSDT',
    },
    triggerLineOptions: {
        showModalOnCreate: false
    }
}

const mutations = {
    setAccountsPortfolio(state, { apiAccountId, portfolio }) {
        state.portfolios[apiAccountId] = portfolio
    },

    setAccountsPositions(state, { apiAccountId, positions }) {
        state.positions[apiAccountId] = positions
    },
    setChartDataSourceTimeframe(state, timeframe) {
        console.log('setChartDataSourceTimeframe', timeframe)
        state.chartDataSource.timeframe = timeframe
    },
    resetSelectedPosition(state) {
        state.selectedPosition = {
            accountId: '',
            market: '',
            symbol: '',
        }
    },
    setSelectedPosition(state, position) {
        state.selectedPosition = position
    },
    setSelectedPositionAccountId(state, accId) {
        state.selectedPosition.accountId = accId
    },
    setSelectedPositionMarket(state, market) {
        state.selectedPosition.market = market
    },
    setSelectedPositionSymbol(state, symbol) {
        state.selectedPosition.symbol = symbol
    },
    setTradingDestinationExchange(state, exchange) {
        state.currentTradingDestination.exchange = exchange
    },
    setTradingDestinationMarket(state, market) {
        state.currentTradingDestination.market = market
    },
    setTradingDestinationSymbol(state, symbol) {
        state.currentTradingDestination.symbol = symbol
    },
    setPositionTradingStop(state, tradingStopData) {
        const key = `${tradingStopData.exchange}|${tradingStopData.market}|${tradingStopData.symbol}`
        state.tradingStops[key] = tradingStopData
    },
    setDisplayTriggerLineModal(state, { display, data }) {
        state.displayTriggerLineModal = display;
        state.triggerLineModalData = data;
    },
    INCREMENT_LOADING_COUNTER(state) {
        state.loadingCounter = state.loadingCounter + 1;
    },
    DECREMENT_LOADING_COUNTER(state) {
        state.loadingCounter = state.loadingCounter - 1;
    },
    
    // snackbar mutations
    showSnackbar(state, { hide, color, text, timeout }) {
        if (hide === true) {
            state.snackbarVisible = false
            return
        }
        state.snackbarVisible = true
        state.snackbarColor = color
        state.snackbarText = text
        if (timeout !== undefined)
            state.snackbarTimeout = timeout
    },
    // chart data mutations
    SET_CHART_DATA(state, { exchangeKey, symbol, type, data, market }) {
        const index = state.chartData.findIndex(item => item.exchangeKey === exchangeKey && item.symbol === symbol && item.type === type && item.market === market)
        if (index !== -1) {
            state.chartData.splice(index, 1, { exchangeKey, market, symbol, type, data })
        } else {
            state.chartData.push({ exchangeKey, market, symbol, type, data })
        }
    },
    setUsers(state, users) {
        state.users = users
    },
    setApiAccounts(state, apiAccounts) {
        state.apiAccounts = apiAccounts
    },
    setTriggers(state, triggers) {
        state.triggers = triggers
    },
    setActions(state, actions) {
        state.actions = actions
    },
    setActionsSchema(state, { actionId, schema }) {
        const index = state.actions.findIndex(item => item.id === actionId)
        if (index !== -1) {
            state.actions[index].schema = schema
        } else {
            console.log('Action not found | not able to set schema')
        }
    },

    setDrawings(state, drawings) {
        state.drawings = drawings
    },
    setEvents(state, events) {
        state.events = events
    },
    addTrigger(state, trigger) {
        state.triggers.push(trigger)
    },
    addApiAccount(state, apiAccount) {
        state.apiAccounts.push(apiAccount)
    },
    updateApiAccount(state, apiAccount) {
        const index = state.apiAccounts.findIndex(item => item.id === apiAccount.id)
        if (index !== -1) {
            state.apiAccounts.splice(index, 1, apiAccount)
        }
    },
    deleteApiAccount(state, apiAccount) {
        const index = state.apiAccounts.findIndex(item => item.id === apiAccount.id)
        if (index !== -1) {
            state.apiAccounts.splice(index, 1)
        }
    },
    setExchanges(state, exchanges) {
        state.exchanges = exchanges
    },
    setActionScripts(state, actionScripts) {
        state.actionScripts = actionScripts
    },
    addAction(state, action) {
        state.actions.push(action)
    },
    updateAction(state, action) {
        const index = state.actions.findIndex(item => item.id === action.id)
        if (index !== -1) {
            state.actions.splice(index, 1, action)
        }
    },
    deleteAction(state, action) {
        const index = state.actions.findIndex(item => item.id === action.id)
        if (index !== -1) {
            state.actions.splice(index, 1)
        }
    },
    setTriggerScripts(state, triggerScripts) {
        state.triggerScripts = triggerScripts
    },
    updateTrigger(state, trigger) {
        const index = state.triggers.findIndex(item => item.id === trigger.id)
        if (index !== -1) {
            state.triggers.splice(index, 1, trigger)
        }
    },
    deleteTrigger(state, trigger) {
        const index = state.triggers.findIndex(item => item.id === trigger.id)
        if (index !== -1) {
            state.triggers.splice(index, 1)
        }
    },
    // loads OHLCV dataset from local storage
    loadOHLCVFromLocalStorage(state, { exchange, symbol, market, timeframe }) {
        const entryKey = getOHLCVEntryKey(exchange, market, symbol, timeframe)
        const storageKey = "ohlcv_" + entryKey
        const localStorageData = localStorage.getItem(storageKey)
        if (localStorageData) {
            console.warn(`${storageKey} loaded from local storage | data length: ${localStorageData.length}`)
            state.ohlcv[entryKey] = JSON.parse(localStorageData)
        } else {
            console.error("OHLCV data not found in local storage:", storageKey)
        }
        
    },
    // updates OHLCV dataset in the store
    updateOHLCV(state, { exchange, symbol, market, timeframe, data }) {
        const entryKey = getOHLCVEntryKey(exchange, market, symbol, timeframe)
        this.commit('updateOHLCVDataInStore', {entryKey, data})
    },

    // updates OHLCV dataset in the store and stores it in local storage, checks if new data should be prepended or appended
    updateOHLCVDataInStore(state, {entryKey, data}) {
        console.log('updateOHLCVDataInStore', entryKey, data.length)
        const currentStore = state.ohlcv[entryKey]
        if (currentStore && currentStore.length > 0) {
            const earliestStoreTime = currentStore[0][0]
            const latestStoreTime = currentStore[currentStore.length - 1][0]
            //const earliestDataTime = data[0][0];
            //const latestDataTime = data[data.length - 1][0];

            const dataToPrepend = filterDataByTime(data, earliestStoreTime, true)
            const dataToAppend = filterDataByTime(data, latestStoreTime, false)

            if (dataToPrepend.length > 0) {
                state.ohlcv[entryKey] = [...dataToPrepend, ...currentStore]
            }
            if (dataToAppend.length > 0) {
                state.ohlcv[entryKey] = [...currentStore, ...dataToAppend]
            }

            // store the updated data to local storage
            const storeKey = `ohlcv_${entryKey}`
            const storeData = JSON.stringify(state.ohlcv[entryKey])
            localStorage.setItem(storeKey, storeData)
            console.warn("OHLCV data updated in local storage:", storeKey)
        } else {
            state.ohlcv[entryKey] = data

            // store the new data to local storage
            const storeKey = `ohlcv_${entryKey}`
            const storeData = JSON.stringify(state.ohlcv[entryKey])
            localStorage.setItem(storeKey, storeData)
            console.warn("OHLCV data updated in local storage:", storeKey)
        }
    },


    // add mutations for other entities here
    setExchangeDetails(state, exchange) {
        state.exchangesDetails[exchange.exchange_key] = exchange
        //console.log('setExchangeDetails', state.exchangesDetails)
    },
    // dashboard mutations
    setDashboards(state, dashboards) {
        state.dashboards = dashboards
    },
    addDashboard(state, dashboard) {
        state.dashboards.push(dashboard)
    },
    updateDashboard(state, dashboard) {
        const index = state.dashboards.findIndex(item => item.id === dashboard.id)
        if (index !== -1) {
            state.dashboards.splice(index, 1, dashboard)
        }
    },
    deleteDashboard(state, dashboardId) {
        const index = state.dashboards.findIndex(item => item.id === dashboardId)
        if (index !== -1) {
            state.dashboards.splice(index, 1)
        }
    },
    setSelectedDashboard(state, dashboardId) {
        state.selectedDashboard = dashboardId
    },
    setOrderHistory(state, { apiAccountId, market, symbol, orderHistory }) {
        state.orderHistory[`${apiAccountId}-${market}-${symbol}`] = orderHistory
    },
    setTriggerLineModalData(state, triggerLineModalData) {
        state.triggerLineModalData = Object.assign(state.triggerLineModalData, triggerLineModalData)
    },
    setChartDataSource(state, { exchange, market, symbol }) {
        if (exchange) state.chartDataSource.exchange = exchange
        if (market) state.chartDataSource.market = market
        if (symbol) state.chartDataSource.symbol = symbol
    },
    setTriggerLineOptions(state, { showModalOnCreate }) {
        if (showModalOnCreate !== undefined && showModalOnCreate !== null) state.triggerLineOptions.showModalOnCreate = showModalOnCreate
    },
}

const actions = {
    async fetchStreamStatus({ commit, state, rootState }) {
        try {
            commit('INCREMENT_LOADING_COUNTER')
            const response = await axios.get(state.backendUrl + '/api/maintenance/kline', {
                headers: {
                    Authorization: `Token ${rootState.auth.token}`
                }
            })
            return response.data
        } catch (error) {
            errorHandler(error, commit)
        } finally {
            commit('DECREMENT_LOADING_COUNTER')
        }
    },
    async fetchTriggerProcessing({ commit, state, rootState }, { exchange, market, symbol, timeframe }) {
        try {
            commit('INCREMENT_LOADING_COUNTER')
            const response = await axios.get(state.backendUrl + '/api/stored-ohlcv/' + exchange + '/' + market + '/' + symbol + '/' + timeframe + '?append_trigger_processing=short', {
                headers: {
                    Authorization: `Token ${rootState.auth.token}`
                }
            })
            return response.data
        } catch (error) {
            errorHandler(error, commit)
        } finally {
            commit('DECREMENT_LOADING_COUNTER')
        }
    },
    async fetchAccountsPortfolio({ commit, state, rootState }, apiAccountId) {
        try {
            commit('INCREMENT_LOADING_COUNTER')
            const response = await axios.get(state.backendUrl + '/api/api-accounts/' + apiAccountId + '/portfolio', {
                headers: {
                    Authorization: `Token ${rootState.auth.token}`
                }
            })
            commit('setAccountsPortfolio', { apiAccountId, portfolio: response.data })
        }
        catch (error) {
            errorHandler(error, commit)
        } finally {
            commit('DECREMENT_LOADING_COUNTER')
        }
    },
    async fetchAccountsPositions({ commit, state, rootState }, apiAccountId) {
        if (!apiAccountId) {
            console.log("fetchAccountsPositions: apiAccountId not set")
            return
        }
        try {
            commit('INCREMENT_LOADING_COUNTER')
            const response = await axios.get(state.backendUrl + '/api/api-accounts/' + apiAccountId + '/positions', {
                headers: {
                    Authorization: `Token ${rootState.auth.token}`
                }
            })
            commit('setAccountsPositions', { apiAccountId, positions: response.data })
        }
        catch (error) {
            errorHandler(error, commit)
        } finally {
            commit('DECREMENT_LOADING_COUNTER')
        }
    },
    async deleteTriggerLineDependencies({ dispatch }, { longActionIDs, shortActionIDs, longTriggerID, shortTriggerID }) {
        console.log("🚀 ~ file: index.js:229 ~ createTiggerLineDependencies ~ drawingID:", longActionIDs, shortActionIDs, longTriggerID, shortTriggerID)
        const tasks = []
        for (const actionID of longActionIDs) {
            tasks.push(dispatch("deleteAction", { id: actionID }))
        }
        for (const actionID of shortActionIDs) {
            tasks.push(dispatch("deleteAction", { id: actionID }))
        }
        tasks.push(dispatch("deleteTrigger", { id: longTriggerID }))
        tasks.push(dispatch("deleteTrigger", { id: shortTriggerID }))

        Promise.all(tasks).then(() => {
            console.log("Trigger line dependencies deleted")
        }).catch((error) => {
            console.error("Error deleting trigger line dependencies:", error)
        })

    },
    /**
        * @description Create the trigger line dependencies for the current exchange, market, and symbol
        *              Dependencies include:
        *             - Long Trigger + Action
        *             - Short Trigger + Action
        */
    async createTriggerLineDependencies({ state, dispatch }, {drawingID, long, short, chartDataSource}) {
        console.log("🚀 ~ file: index.js:229 ~ createTiggerLineDependencies ~ drawingID:", drawingID, long, short, chartDataSource)
        if (state.apiAccounts.length === 0) {
            console.error("No api accounts found, not creating trigger line dependencies")
            return
        }

        const positionIsSet = state.selectedPosition && state.selectedPosition.accountId && state.selectedPosition.market && state.selectedPosition.symbol

        if (state.apiAccounts.length === 0) {
            console.error("No api accounts found, not creating trigger line dependencies")
            return
        }

        let apiAccountID = state.apiAccounts[0].id;
        let exchange = chartDataSource.exchange
        let market = chartDataSource.market
        let symbol = chartDataSource.symbol
        let usedTimeframe = '1d'


        if (chartDataSource.timeframe) usedTimeframe = chartDataSource.timeframe

        const fullDrawingID = `${exchange}|${market}|${symbol}|${drawingID}`

        if (positionIsSet) {
            console.log("Using selected position for trigger line dependency creation:", state.selectedPosition)
            const apiAccount = state.apiAccounts.find((apiAccount) => apiAccount.id === state.selectedPosition.accountId)
            apiAccountID = apiAccount.id
            exchange = apiAccount.exchange_key
            market = state.selectedPosition.market
            symbol = state.selectedPosition.symbol

        }


        const longOrderAction = await dispatch("createAction", {
            name: `${exchange}|${market}|${symbol}|${drawingID} - Long Action`,
            template_script: "PlaceOrderAction",

            api_account: apiAccountID,
            parameters: {
                executeOnce: true,
                type: "Market",
                side: "Buy",
                symbol: symbol,
                market: market,
                usePercentQuantity: true,
                quantityPercent: 8
            }
        });


        const longTriggerLineTrigger = await dispatch("createTrigger", {
            enabled: long,
            name: `${exchange}|${market}|${symbol}|${drawingID} - Long Trigger`,
            drawingID: fullDrawingID,
            template_script: "DrawingCandleConditionTrigger",
            // TODO add action
            triggered_actions: [longOrderAction.id],
            parameters: {
                drawing: fullDrawingID,
                priceReference: "c",
                triggerOnlyOnNewCandle: true,
                executeOnce: true,
                condition: ">",
                candleSize: usedTimeframe
            }
        });

        const shortOrderAction = await dispatch("createAction", {
            name: `${exchange}|${market}|${symbol}|${drawingID} - Short Action`,
            template_script: "PlaceOrderAction",

            api_account: apiAccountID,
            parameters: {
                executeOnce: true,
                type: "Market",
                side: "Sell",
                symbol: symbol,
                market: market,
                usePercentQuantity: true,
                quantityPercent: 5
            }
        });



        const shortTriggerLineTrigger = await dispatch("createTrigger", {
            enabled: short,
            name: `${exchange}|${market}|${symbol}|${drawingID} - Short Trigger`,
            drawingID: fullDrawingID,
            template_script: "DrawingCandleConditionTrigger",
            // TODO add action
            triggered_actions: [shortOrderAction.id],
            parameters: {
                drawing: fullDrawingID,
                priceReference: "c",
                triggerOnlyOnNewCandle: true,
                executeOnce: true,
                condition: "<",


                candleSize: usedTimeframe,
            }
        });


        return {
            longTriggerID: longTriggerLineTrigger.id,
            shortTriggerID: shortTriggerLineTrigger.id,
            longActionIDs: [longOrderAction.id],
            shortActionIDs: [shortOrderAction.id]
        }

    },
    async markEventsAsViewed({ commit, state, rootState, dispatch }, eventIDs) {
        try {
            commit('INCREMENT_LOADING_COUNTER')
            await axios.post(state.backendUrl + '/api/events/viewed', eventIDs, {
                headers: {
                    Authorization: `Token ${rootState.auth.token}`
                }
            })
            // refetch events
            await dispatch('fetchEvents')

            //commit('showSnackbar', { text: 'Events marked as viewed', timeout: 2000 })
        } catch (error) {
            errorHandler(error, commit)
        } finally {
            commit('DECREMENT_LOADING_COUNTER')
        }
    },
    async fetchUsers({ commit, state, rootState }) {
        try {
            commit('INCREMENT_LOADING_COUNTER')
            const response = await axios.get(state.backendUrl + '/api/users/', {
                headers: {
                    Authorization: `Token ${rootState.auth.token}`
                }
            })
            commit('setUsers', response.data)
            //commit('showSnackbar', { text: 'Users fetched', timeout: 2000 })
        } catch (error) {
            errorHandler(error, commit)
        } finally {
            commit('DECREMENT_LOADING_COUNTER')
        }
    },
    async fetchApiAccounts({ commit, state, rootState }) {
        try {
            commit('INCREMENT_LOADING_COUNTER')
            const response = await axios.get(state.backendUrl + '/api/api-accounts/', {
                headers: {
                    Authorization: `Token ${rootState.auth.token}`
                }
            })
            commit('setApiAccounts', response.data)
            //console.log('fetchApiAccounts', response.data)
            //commit('showSnackbar', { text: 'Api Accounts fetched', timeout: 2000 })
        } catch (error) {
            errorHandler(error, commit)
        } finally {
            commit('DECREMENT_LOADING_COUNTER')
        }
    },
    async fetchTriggers({ commit, state, rootState }) {
        try {
            commit('INCREMENT_LOADING_COUNTER')
            const response = await axios.get(state.backendUrl + '/api/triggers/', {
                headers: {
                    Authorization: `Token ${rootState.auth.token}`
                }
            })
            commit('setTriggers', response.data)
            //commit('showSnackbar', { text: 'Triggers fetched', timeout: 2000 })
        } catch (error) {
            errorHandler(error, commit)
        } finally {
            commit('DECREMENT_LOADING_COUNTER')
        }
    },
    async fetchActions({ commit, state, rootState }) {
        try {
            commit('INCREMENT_LOADING_COUNTER')
            const response = await axios.get(state.backendUrl + '/api/actions/', {
                headers: {
                    Authorization: `Token ${rootState.auth.token}`
                }
            })
            commit('setActions', response.data)
            //commit('showSnackbar', { text: 'Actions fetched', timeout: 2000 })
        } catch (error) {
            errorHandler(error, commit)
        } finally {
            commit('DECREMENT_LOADING_COUNTER')
        }
    },
    async fetchEvents({ commit, state, rootState }) {
        try {
            commit('INCREMENT_LOADING_COUNTER')
            const response = await axios.get(state.backendUrl + '/api/events/', {
                headers: {
                    Authorization: `Token ${rootState.auth.token}`
                }
            })
            commit('setEvents', response.data)
            //commit('showSnackbar', { text: 'Events fetched', timeout: 2000 })
        } catch (error) {
            errorHandler(error, commit)
        } finally {
            commit('DECREMENT_LOADING_COUNTER')
        }
    },
    async fetchExchanges({ commit, state, rootState }) {
        try {
            commit('INCREMENT_LOADING_COUNTER')
            const response = await axios.get(state.backendUrl + '/api/exchanges/', {
                headers: {
                    Authorization: `Token ${rootState.auth.token}`
                }
            })
            commit('setExchanges', response.data)
            //commit('showSnackbar', { text: 'Exchanges fetched', timeout: 2000 })
        }
        catch (error) {
            errorHandler(error, commit)
        } finally {
            commit('DECREMENT_LOADING_COUNTER')
        }
    },
    async fetchActionScripts({ commit, state, rootState }) {
        try {
            commit('INCREMENT_LOADING_COUNTER')
            const response = await axios.get(state.backendUrl + '/api/action-scripts/', {
                headers: {
                    Authorization: `Token ${rootState.auth.token}`
                }
            })
            commit('setActionScripts', response.data)
            //commit('showSnackbar', { text: 'Action Scripts fetched', timeout: 2000 })
        } catch (error) {
            errorHandler(error, commit)
        } finally {
            commit('DECREMENT_LOADING_COUNTER')
        }
    },
    async createTrigger({ commit, state, rootState }, trigger) {
        let response = null
        try {
            commit('INCREMENT_LOADING_COUNTER')
            response = await axios.post(state.backendUrl + '/api/triggers/', trigger, {
                headers: {
                    Authorization: `Token ${rootState.auth.token}`
                }
            })
            commit('addTrigger', response.data)
            response = response.data;

            commit('showSnackbar', { text: 'Trigger created', color: 'success' })
        } catch (error) {
            errorHandler(error, commit)
        } finally {
            commit('DECREMENT_LOADING_COUNTER')
        }
        return response
    },
    async createApiAccount({ commit, state, rootState }, apiAccount) {
        try {
            commit('INCREMENT_LOADING_COUNTER')
            const response = await axios.post(state.backendUrl + '/api/api-accounts/', apiAccount, {
                headers: {
                    Authorization: `Token ${rootState.auth.token}`
                }
            })
            commit('addApiAccount', response.data)
            commit('showSnackbar', { text: 'API Account created', color: 'success' })
        } catch (error) {
            errorHandler(error, commit)
        } finally {
            commit('DECREMENT_LOADING_COUNTER')
        }
    },
    async updateApiAccount({ commit, state, rootState }, apiAccount) {
        try {
            commit('INCREMENT_LOADING_COUNTER')
            const response = await axios.put(state.backendUrl + '/api/api-accounts/' + apiAccount.id + '/', apiAccount, {
                headers: {
                    Authorization: `Token ${rootState.auth.token}`
                }
            })
            commit('updateApiAccount', response.data)
            commit('showSnackbar', { text: 'API Account updated', color: 'success' })
        } catch (error) {
            errorHandler(error, commit)
        } finally {
            commit('DECREMENT_LOADING_COUNTER')
        }
    },
    async deleteApiAccount({ commit, state, rootState }, apiAccount) {
        try {
            commit('INCREMENT_LOADING_COUNTER')
            //console.log("🚀 ~ file: index.js:130 ~ deleteApiAccount ~ apiAccount:", apiAccount)
            await axios.delete(state.backendUrl + '/api/api-accounts/' + apiAccount.id + '/', {
                headers: {
                    Authorization: `Token ${rootState.auth.token}`
                }
            })
            commit('deleteApiAccount', apiAccount)
            commit('showSnackbar', { text: 'API Account deleted', color: 'success' })
        } catch (error) {
            errorHandler(error, commit)
        } finally {
            commit('DECREMENT_LOADING_COUNTER')
        }
    },
    async createAction({ commit, state, rootState }, action) {
        let response = null;
        try {
            commit('INCREMENT_LOADING_COUNTER')
            response = await axios.post(state.backendUrl + '/api/actions/', action, {
                headers: {
                    Authorization: `Token ${rootState.auth.token}`
                }
            })
            commit('addAction', response.data)
            response = response.data;
        } catch (error) {
            errorHandler(error, commit)
        } finally {
            commit('DECREMENT_LOADING_COUNTER')
        }
        return response
    },
    async executeAction({ state, rootState, commit }, action_id) {
        try {
            commit('INCREMENT_LOADING_COUNTER')
            const response = await axios.post(`${state.backendUrl}/api/actions/${action_id}/execute`, null, {
                headers: {
                    Authorization: `Token ${rootState.auth.token}`
                }
            })
            commit('showSnackbar', { text: response.data, color: 'success' })
            console.log("Executed action:", response.data.message)
        } catch (error) {

            errorHandler(error, commit)
        } finally {
            commit('DECREMENT_LOADING_COUNTER')
        }
    },
    async fetchActionSchema({ state, commit, rootState }, { actionScriptClass, params }) {
        try {
            commit('INCREMENT_LOADING_COUNTER')
            const response = await axios.post(`${state.backendUrl}/api/action-scripts/${actionScriptClass}/schema`, {
                headers: {
                    Authorization: `Token ${rootState.auth.token}`
                },
                params
            })
            return response.data
        } catch (error) {
            errorHandler(error, commit)
        } finally {
            commit('DECREMENT_LOADING_COUNTER')
        }
    },
    async updateAction({ commit, state, rootState }, action) {
        try {
            commit('INCREMENT_LOADING_COUNTER')
            const response = await axios.put(state.backendUrl + '/api/actions/' + action.id + '/', action, {
                headers: {
                    Authorization: `Token ${rootState.auth.token}`
                }
            })
            commit('updateAction', response.data)
        } catch (error) {
            errorHandler(error, commit)
        } finally {
            commit('DECREMENT_LOADING_COUNTER')
        }
    },
    async deleteAction({ commit, state, rootState }, action) {
        try {
            commit('INCREMENT_LOADING_COUNTER')
            await axios.delete(state.backendUrl + '/api/actions/' + action.id + '/', {
                headers: {
                    Authorization: `Token ${rootState.auth.token}`
                }
            })
            commit('deleteAction', action)
            commit('showSnackbar', { text: 'Action deleted', color: 'success' })
        } catch (error) {
            errorHandler(error, commit)
        } finally {
            commit('DECREMENT_LOADING_COUNTER')
        }
    },
    // triggers
    async updateTrigger({ commit, state, rootState }, trigger) {
        try {
            commit('INCREMENT_LOADING_COUNTER')
            const response = await axios.put(state.backendUrl + '/api/triggers/' + trigger.id + '/', trigger, {
                headers: {
                    Authorization: `Token ${rootState.auth.token}`
                }
            })
            commit('updateTrigger', response.data)
            commit('showSnackbar', { text: 'Trigger updated', color: 'success' })
        } catch (error) {
            errorHandler(error, commit)
        } finally {
            commit('DECREMENT_LOADING_COUNTER')
        }

    },
    async deleteTrigger({ commit, state, rootState }, trigger) {
        try {
            commit('INCREMENT_LOADING_COUNTER')
            await axios.delete(state.backendUrl + '/api/triggers/' + trigger.id + '/', {
                headers: {
                    Authorization: `Token ${rootState.auth.token}`
                }
            })
            commit('deleteTrigger', trigger)
            commit('showSnackbar', { text: 'Trigger deleted', color: 'success' })
        } catch (error) {
            errorHandler(error, commit)
        } finally {
            commit('DECREMENT_LOADING_COUNTER')
        }
    },
    async fetchTriggerScripts({ commit, state, rootState }) {
        try {
            commit('INCREMENT_LOADING_COUNTER')
            const response = await axios.get(state.backendUrl + '/api/trigger-scripts/', {
                headers: {
                    Authorization: `Token ${rootState.auth.token}`
                }
            })
            commit('setTriggerScripts', response.data)
        } catch (error) {
            errorHandler(error, commit)
        } finally {
            commit('DECREMENT_LOADING_COUNTER')
        }

    },
    async fetchTriggerSchema({ state, commit, rootState }, { triggerScriptClass, params }) {
        try {
            commit('INCREMENT_LOADING_COUNTER')
            const response = await axios.post(`${state.backendUrl}/api/trigger-scripts/${triggerScriptClass}/schema`, { params }, {
                headers: {
                    "Authorization": `Token ${rootState.auth.token}`
                }
            })
            return response.data
        } catch (error) {
            errorHandler(error, commit)
        } finally {
            commit('DECREMENT_LOADING_COUNTER')
        }
    },
    async fetchOHLCV({ commit, state, rootState, getters }, { exchange, symbol, market, timeframe, limit, start, end }) {
        try {
            commit('INCREMENT_LOADING_COUNTER')
            if (start && end) {
                const storedOHLCV = getters.getOHLCVFromTo({ exchange, symbol, market, timeframe, start, end })
                if (storedOHLCV.length > 0) {
                    console.log('returning stored OHLCV')
                    return storedOHLCV
                }
            }
            const response = await axios.get(state.backendUrl + '/api/ohlcv', {
                headers: {
                    Authorization: `Token ${rootState.auth.token}`
                },
                params: {
                    exchange,
                    symbol,
                    market,
                    timeframe,
                    limit,
                    start,
                    end
                }
            })
            commit('updateOHLCV', { exchange, symbol, market, timeframe, data: response.data });
            console.log('returning fetched OHLCV')
            return response.data
        } catch (error) {
            errorHandler(error, commit)
        } finally {
            commit('DECREMENT_LOADING_COUNTER')
        }
    },
    async fetchExchangeDetails({ commit, state, rootState }, exchange) {
        try {
            commit('INCREMENT_LOADING_COUNTER')
            //console.log(state.backendUrl + '/api/exchanges/' + exchange + '/')
            const response = await axios.get(state.backendUrl + '/api/exchanges/' + exchange + '/', {
                headers: {
                    Authorization: `Token ${rootState.auth.token}`
                }
            })

            commit('setExchangeDetails', response.data)
        } catch (error) {
            errorHandler(error, commit)
        } finally {
            commit('DECREMENT_LOADING_COUNTER')
        }


    },
    async fetchChartData({ commit, state, rootState }, { exchange, market, symbol, type }) {
        try {
            commit('INCREMENT_LOADING_COUNTER')
            const response = await axios.get(`${state.backendUrl}/api/chart-data/${exchange}/${market}/${symbol}/${type}`, {
                headers: {
                    Authorization: `Token ${rootState.auth.token}`
                }
            })
            const { data } = response
            commit('SET_CHART_DATA', { exchangeKey: exchange, market, symbol, data, type })
            return data
        } catch (error) {
            errorHandler(error, commit)
        } finally {
            commit('DECREMENT_LOADING_COUNTER')
        }
    },
    async saveChartData({ commit, state, rootState }, { exchange, market, symbol, type, data }) {
        try {
            commit('INCREMENT_LOADING_COUNTER')
            const response = await axios.put(`${state.backendUrl}/api/chart-data/${exchange}/${market}/${symbol}/${type}`, data, {
                headers: {
                    Authorization: `Token ${rootState.auth.token}`
                }
            })
            commit('SET_CHART_DATA', { exchangeKey: exchange, market, symbol, data, type })

            return response.data
            // console.log(response.data.message)
        } catch (error) {
            errorHandler(error, commit)
        } finally {
            commit('DECREMENT_LOADING_COUNTER')
        }
    },
    async deleteChartData({ commit, state, rootState }, { exchange, market, symbol, type }) {
        try {
            commit('INCREMENT_LOADING_COUNTER')
            const response = await axios.delete(`${state.backendUrl}/api/chart-data/${exchange}/${market}/${symbol}/${type}`, {
                headers: {
                    Authorization: `Token ${rootState.auth.token}`
                }
            })
            commit('SET_CHART_DATA', { exchangeKey: exchange, market, symbol, data: [], type })
            return response.data
            // console.log(response.data.message)
        } catch (error) {
            errorHandler(error, commit)
        } finally {
            commit('DECREMENT_LOADING_COUNTER')
        }
    },



    // Dashboard actions
    async fetchAllDashboards({ commit, state, rootState }) {
        try {
            commit('INCREMENT_LOADING_COUNTER')
            const response = await axios.get(state.backendUrl + '/api/dashboards/', {
                headers: {
                    Authorization: `Token ${rootState.auth.token}`
                }
            })
            commit('setDashboards', response.data)
        } catch (error) {
            errorHandler(error, commit)
        } finally {
            commit('DECREMENT_LOADING_COUNTER')
        }
    },
    async createDashboard({ commit, state, rootState }, dashboard) {
        try {
            commit('INCREMENT_LOADING_COUNTER')
            const response = await axios.post(state.backendUrl + '/api/dashboards/', dashboard, {
                headers: {
                    Authorization: `Token ${rootState.auth.token}`
                }
            })
            commit('addDashboard', response.data)
        } catch (error) {
            errorHandler(error, commit)
        } finally {
            commit('DECREMENT_LOADING_COUNTER')
        }
    },
    async updateDashboard({ commit, state, rootState }, dashboard) {
        try {
            commit('INCREMENT_LOADING_COUNTER')
            const response = await axios.put(state.backendUrl + '/api/dashboards/' + dashboard.id + '/', dashboard, {
                headers: {
                    Authorization: `Token ${rootState.auth.token}`
                }
            })
            commit('updateDashboard', response.data)
            commit('showSnackbar', { text: 'Dashboard ' + dashboard.name + ' updated', color: 'success' })
        } catch (error) {
            errorHandler(error, commit)
        } finally {
            commit('DECREMENT_LOADING_COUNTER')
        }
    },
    async deleteDashboard({ commit, state, rootState }, dashboardId) {
        try {
            commit('INCREMENT_LOADING_COUNTER')
            await axios.delete(state.backendUrl + '/api/dashboards/' + dashboardId + '/', {
                headers: {
                    Authorization: `Token ${rootState.auth.token}`
                }
            })
            commit('deleteDashboard', dashboardId)
        } catch (error) {
            errorHandler(error, commit)
        } finally {
            commit('DECREMENT_LOADING_COUNTER')
        }
    },
    async fetchOrderHistory({ commit, state, rootState }, { apiAccountId, market, symbol }) {
        let result;
        try {
            commit('INCREMENT_LOADING_COUNTER')
            const response = await axios.get(state.backendUrl + '/api/api-accounts/' + apiAccountId + '/order/history/' + market + '/' + symbol, {
                headers: {
                    Authorization: `Token ${rootState.auth.token}`
                }
            })
            result = response.data;
            commit('setOrderHistory', { apiAccountId, market, symbol, orderHistory: result });
            
        } catch (error) {
            errorHandler(error, commit)
        } finally {
            commit('DECREMENT_LOADING_COUNTER')
        }
        return result;
    },
    /*
Parameter	    Required	Type	    Comments
category	    true	    string	    Product type    Unified account: linear, inverse
                                                        Normal account: linear, inverse. Please note that category is not involved with business logic
symbol	        true	    string	    Symbol name
takeProfit	    false	    string	    Cannot be less than 0, 0 means cancel TP
stopLoss	    false	    string	    Cannot be less than 0, 0 means cancel SL
trailingStop	false	    string	    Trailing stop by price distance. Cannot be less than 0, 0 means cancel TS
tpTriggerBy	    false	    string	    Take profit trigger price type
slTriggerBy	    false	    string	    Stop loss trigger price type
activePrice	    false	    string	    Trailing stop trigger price. Trailing stop will be triggered when this price is reached only
tpslMode	    false	    string	    TP/SL mode. Full: entire position TP/SL, Partial: partial position TP/SL. As each contract has an initial full TP/SL mode, if it has been modified before, it may be partial. Therefore, if not provided, the system will automatically retrieve the current TP/SL mode configuration for the contract.
tpSize	        false	    string	    Take profit size. Valid in TP/SL partial mode. Note: the value of tpSize and slSize must equal
slSize	        false	    string	    Stop loss size. Valid in TP/SL partial mode. Note: the value of tpSize and slSize must equal
tpLimitPrice	false	    string	    The limit order price when take profit price is triggered. Only works when tpslMode=Partial and tpOrderType=Limit
slLimitPrice	false	    string	    The limit order price when stop loss price is triggered. Only works when tpslMode=Partial and slOrderType=Limit
tpOrderType	    false	    string	    The order type when take profit is triggered. Market(default), Limit. For tpslMode=Full, it only supports tpOrderType=Market
slOrderType	    false	    string	    The order type when stop loss is triggered. Market(default), Limit. For tpslMode=Full, it only supports slOrderType=Market
positionIdx	    true	    integer	    Used to identify positions in different position modes.  0: one-way mode | 1: hedge-mode Buy side | 2: hedge-mode Sell side
    */
    async setPositionTradingStop({ commit, state, rootState }, { apiAccountId, market, symbol, positionIdx, takeProfit, stopLoss, trailingStop, tpTriggerBy, slTriggerBy, activePrice, tpslMode, tpSize, slSize, tpLimitPrice, slLimitPrice, tpOrderType, slOrderType }) {
        let result;
        try {
            commit('INCREMENT_LOADING_COUNTER')
            result = await axios.post(state.backendUrl + '/api/position/trading-stop', {
                apiAccountId,
                market,
                symbol,
                positionIdx,
                takeProfit,
                stopLoss,
                trailingStop,
                tpTriggerBy,
                slTriggerBy,
                activePrice,
                tpslMode,
                tpSize,
                slSize,
                tpLimitPrice,
                slLimitPrice,
                tpOrderType,
                slOrderType
            }, {
                headers: {
                    Authorization: `Token ${rootState.auth.token}`
                }
            })

            commit('setPositionTradingStop', { apiAccountId, market, symbol, positionIdx, takeProfit, stopLoss, trailingStop, tpTriggerBy, slTriggerBy, activePrice, tpslMode, tpSize, slSize, tpLimitPrice, slLimitPrice, tpOrderType, slOrderType });
            commit('showSnackbar', { text: 'Position Trading Stop updated', color: 'success' })
            result = result.data;
        } catch (error) {
            errorHandler(error, commit)
        } finally {
            commit('DECREMENT_LOADING_COUNTER')
        }
        return result;
    },
}



const getters = {
    getUsers: state => state.users,
    getApiAccounts: state => state.apiAccounts,
    getTriggers: state => state.triggers,
    getActions: state => state.actions,
    getDrawings: state => state.drawings,
    getEvents: state => state.events,
    getExchanges: state => state.exchanges,
    getActionScripts: state => state.actionScripts,
    getPortfolioByApiAccountId: (state) => (apiAccountId) => {

        console.log("getPortfolioByApiAccountId", state.portfolios)
        if (state.portfolios && state.portfolios[apiAccountId]) {
            return state.portfolios[apiAccountId]
        }
        return []
    },
    getTriggerById: (state) => (triggerId) => {
        return state.triggers.find(trigger => trigger && trigger.id === triggerId)
    },
    getTriggersForDrawingId: (state) => (exchange, market, symbol, drawingId) => {
        let fullDrawingId = `${exchange}|${market}|${symbol}|${drawingId}`
        //console.log("🚀 ~ file: index.js:599 ~ getTriggersForDrawingId:", fullDrawingId, state.triggers)
        return state.triggers.filter(trigger => trigger.parameters.drawing === fullDrawingId)
    },
    getChartData: (state) => (exchangeKey, market, symbol, type) => {
        //console.log("🚀 getChartData:", exchangeKey, market, symbol, type)
        const foundItem = state.chartData.find(item => item.exchangeKey === exchangeKey && item.symbol === symbol && item.type === type && item.market === market)
        //console.log("🚀 ~ file: index.js:329 ~ foundItem:", foundItem)
        if (foundItem && foundItem.data) {
            return foundItem.data
        }
        return []
    },
    getApiAccountById: (state) => (id) => {
        //console.log("store | getApiAccountById:", id ," all accounts:", state.apiAccounts)
        return state.apiAccounts.find(apiAccount => apiAccount.id === id)
    },

    getApiAccountsOptions: state => {
        return state.apiAccounts.map(apiAccount => {
            return {
                value: apiAccount.id,
                text: apiAccount.name
            }
        })
    },
    getActionScriptsOptions: state => {
        return state.actionScripts.map(actionScript => {
            return {
                value: actionScript.class_name,
                text: actionScript.action_name
            }
        })
    },
    getTriggerScriptsOptions: state => {
        return state.triggerScripts.map(triggerScript => {
            return {
                value: triggerScript.class_name,
                text: triggerScript.trigger_name
            }
        })
    },
    getDashboardsOptions: state => {
        return state.dashboards.map(dashboard => {
            return {
                value: dashboard.id,
                text: dashboard.name
            }
        })
    },
    getActionsOptions: state => {
        return state.actions.map(action => {
            return {
                value: action.id,
                text: action.name
            }
        })
    },
    getActionById: (state) => (actionId) => {
        return state.actions.find(action => action.id === actionId)
    },
    getExchangeOptions: (state) => () => {
        //console.log("getExchangeOptions:", state.exchangesDetails)
        const exchangeKeys = Object.keys(state.exchangesDetails)
        if (exchangeKeys.length === 0) {
            return []
        }
        return exchangeKeys.map(exchangeKey => {
            return {
                value: exchangeKey,
                text: exchangeKey
            }
        })
    },
    getExchangeMarketOptions: (state) => (exchangeKey) => {
        if (!exchangeKey || exchangeKey === '' || !state.exchangesDetails) {
            return []
        }
        //console.log("getExchangeMarketOptions:", state.exchangesDetails)
        const foundExchange = state.exchangesDetails[exchangeKey];
        if (!foundExchange) {
            return []
        }
        return Object.keys(foundExchange.symbols).map(symbol => {
            return {
                value: symbol,
                text: symbol
            }
        })
    },
    getExchangeMarketSymbolOptions: (state) => (exchangeKey, market) => {
        if (!exchangeKey || exchangeKey === '' || !state.exchangesDetails) {
            return []
        }
        //console.log("getExchangeMarketSymbolOptions:", state.exchangesDetails)
        const foundExchange = state.exchangesDetails[exchangeKey];
        if (!foundExchange) {
            return []
        }
        let foundMarket = foundExchange.symbols[market];
        if (!foundMarket) {
            return []
        }
        // order by alphabet
        foundMarket = foundMarket.sort((a, b) => a.symbol.localeCompare(b.symbol))
        return foundMarket.map(pair => {
            return {
                value: pair.symbol,
                text: pair.symbol
            }
        })
    },
    getPositionsByApiAccountID: (state) => (accountId) => {
        if (state.positions && state.positions[accountId]) {
            return state.positions[accountId]
        }
        return []
    },
    getUnviewedEventsCount: (state) => {
        const unviewedEvents = state.events.filter(event => !event.viewed)
        return unviewedEvents.length
    },
    getOHLCV: (state) => ({exchange, symbol, market, timeframe}) => {
        const entryKey = `${exchange}|${market}|${symbol}|${timeframe}`;
        const ohlcvData = state.ohlcv[entryKey];
        if (!ohlcvData) {
            return []
        }
        return ohlcvData
    },
    getOHLCVFromTo: (state) => ({exchange, symbol, market, timeframe, start, end}) => {
        const entryKey = `${exchange}|${market}|${symbol}|${timeframe}`;
        const ohlcvData = state.ohlcv[entryKey];

        if (!ohlcvData) {
            return []
        }

        const fromIndex = ohlcvData.findIndex(item => item[0] >= start);
        const toIndex = ohlcvData.findIndex(item => item[0] >= end);

        if (fromIndex === -1 || toIndex === -1) {
            return []
        }

        return ohlcvData.slice(fromIndex, toIndex);
    }


}

Vue.use(Vuex)

export default new Vuex.Store({
    state,
    mutations,
    actions,
    getters,
    modules: {
        auth
    }
})

