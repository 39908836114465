<template>
  <div>
    <h3>Position Selection</h3>
    <p>Select the account, market and symbol to use in ChartView as default</p>

    <v-select
      label="Position Account"
      :value="selectedAccount"
      :items="getApiAccountsOptions"
      @change="updateSelectedAccount"
    >
    </v-select>

    <!-- Select Marktet in accounts exchange-->

    <v-select
      label="Position Market"
      :value="selectedMarket"
      :items="getExchangeMarketOptions(currentExchangeKey)"
      @change="updateSelectedMarket"
      :disabled="
        selectedAccount === '' ||
        selectedAccount === null ||
        selectedAccount == undefined
      "
    >
    </v-select>

    <!-- Select SYMBOL in market-->

    <v-select
      label="Position Symbol"
      :value="selectedSymbol"
      :items="
        getExchangeMarketSymbolOptions(currentExchangeKey, selectedMarket)
      "
      @change="updateSelectedSymbol"
      :disabled="
        selectedAccount === '' ||
        selectedAccount === null ||
        selectedAccount == undefined ||
        selectedMarket === '' ||
        selectedMarket === null ||
        selectedMarket == undefined
      "
    >
    </v-select>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  watch: {
    selectedAccount: function (newVal, oldVal) {
      console.log("selectedAccount changed", newVal, oldVal);
      this.$store.dispatch("fetchAccountsPositions", newVal);
    },
  },
  mounted() {},
  computed: {
    ...mapGetters([
      "getApiAccountsOptions",
      "getExchangeMarketOptions",
      "getExchangeMarketSymbolOptions",
      "getApiAccountById",
    ]),
    currentAccount() {
      return this.getApiAccountById(this.selectedAccount);
    },
    currentExchangeKey() {
      return this.currentAccount && this.currentAccount.exchange_key;
    },
    selectedAccount() {
      return this.$store.state.selectedPosition.accountId;
    },
    selectedMarket() {
      return this.$store.state.selectedPosition.market;
    },
    selectedSymbol() {
      return this.$store.state.selectedPosition.symbol;
    },
  },
  methods: {
    updateSelectedAccount(value) {
      this.$store.commit("setSelectedPositionAccountId", value);
      this.$emit("update", {
        accountId: value,
        market: this.selectedMarket,
        symbol: this.selectedSymbol,
      });
    },
    updateSelectedMarket(value) {
      this.$store.commit("setSelectedPositionMarket", value);
      this.$emit("update", {
        accountId: value,
        market: this.selectedMarket,
        symbol: this.selectedSymbol,
      });
    },
    updateSelectedSymbol(value) {
      this.$store.commit("setSelectedPositionSymbol", value);
      this.$emit("update", {
        accountId: value,
        market: this.selectedMarket,
        symbol: this.selectedSymbol,
      });
    },
  },
};
</script>

<style>
</style>