<template>
    <!-- Timeframe Selector -->
    <div class="tf-selector">
      <span
        v-for="(tf, i) in timeframes"
        :key="tf"
        class="timeframe"
        :style="value === tf ? {color: '#44c767'} : {}"
        @click="on_click(tf, i)"
      >
        {{ tf }}
      </span>
    </div>
  </template>
  
  <script>
  export default {
    name: 'TfSelector',
    props: {
      value: {
        type: String,
        default: '1d',
      },
    },
    data() {
      return {
        timeframes: ['1m', '5m', '15m', '30m', '1h', '4h', '1d', '1w','1M'],
        selected: 6,
      };
    },
    mounted() {
      this.selected = this.timeframes.indexOf(this.value);
    },
    computed: {
      selected_tf() {
        return this.timeframes[this.selected];
      },
    },
    watch: {
      value(val) {
        this.selected = this.timeframes.indexOf(val);
      },
    },

    methods: {
      on_click(tf, i) {
        this.selected = i;
        this.$emit('selected', {
          name: this.timeframes[this.selected],
          i: this.selected,
        });
        this.$emit('input', this.timeframes[this.selected]);
      },
    },
  };
  </script>
  
  <style>
  .tf-selector {
      position: absolute;
      top: 15px;
      right: 80px;
      font: 16px -apple-system,BlinkMacSystemFont,
          Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,
          Fira Sans,Droid Sans,Helvetica Neue,
          sans-serif;
      /*background: #34363b;*/
      color: #ccc;
      padding: 8px;
      border-radius: 3px;
  }
  .timeframe {
      margin-right: 5px;
      user-select: none;
      cursor: pointer;
      font-weight: 200;
      max-width: 10px;
  }
  .timeframe:hover {
      color: #fff;
  }
  </style>
  