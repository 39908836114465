<template>
  <v-dialog v-model="displayTradeStopModal" max-width="800px" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Trade Stop</span>
      </v-card-title>
      <v-card-text>
        <!-- Form for following params -->
        <div v-if="position">
          <!-- Market -->
          <v-row>
            <v-col cols="3">
              <v-text-field
                disabled
                v-model="localPosition.market"
                label="Market"
              ></v-text-field>
            </v-col>

            <v-col cols="3">
              <v-text-field
                disabled
                v-model="localPosition.symbol"
                label="Symbol"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                disabled
                v-model="localPosition.side"
                label="Side"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                disabled
                v-model="localPosition.leverage"
                label="Leverage"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                disabled
                v-model="localPosition.size"
                label="Size"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                disabled
                v-model="localPosition.avgPrice"
                label="Avg Price"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                disabled
                v-model="localPosition.liqPrice"
                label="Liquidation price"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                disabled
                v-model="localPosition.markPrice"
                label="Market price"
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- Take Profit -->
          <v-row>
            <v-col cols="1">
              <v-checkbox v-model="takeProfitEnabled"></v-checkbox>
            </v-col>
            <v-col cols="11"
              ><!--
              <v-text-field
                :disabled="!takeProfitEnabled"
                v-model="localPosition.takeProfit"
                label="Take Profit"
                type="number"
              ></v-text-field>-->
              <v-slider
                @change="takeProfitSliderChanged"
                v-model="takeProfitPercentSlider"
                :max="150"
                label="Take Profit Percent"
                class="align-center"
                :disabled="!takeProfitEnabled"
              >
                <template v-slot:append>
                  <v-text-field
                    v-model="localPosition.takeProfit"
                    class="mt-0 pt-0"
                    type="number"
                    label="Take Profit price"
                    style="width: 120px"
                  ></v-text-field>
                </template>
              </v-slider>
            </v-col>
            <v-col v-if="Number(takeProfitPercent) != 0" cols="12">
              <span style="color: green;">
                Take Profit Percent: {{ takeProfitPercent.toFixed(2) }}%
              </span>
            </v-col>
          </v-row>

          <!-- Stop Loss -->
          <v-row>
            <v-col cols="1">
              <v-checkbox v-model="stopLossEnabled"></v-checkbox>
            </v-col>
            <v-col cols="11">
              <!--<v-text-field
                :disabled="!stopLossEnabled"
                v-model="localPosition.stopLoss"
                label="Stop Loss"
                type="number"
              ></v-text-field>-->
              <v-slider
                @change="stopLossSliderChanged"
                v-model="stopLossPercentSlider"
                :max="75"
                label="Stop Loss Percent"
                class="align-center"
                :disabled="!stopLossEnabled"
              >
                <template v-slot:append>
                  <v-text-field
                    v-model="localPosition.stopLoss"
                    class="mt-0 pt-0"
                    type="number"
                    label="Stop Loss price"
                    style="width: 120px"
                  ></v-text-field>
                </template>
              </v-slider>
            </v-col>
            <v-col cols="12" v-if="Number(stopLossPercent) != 0">
              <span v-if="Number(stopLossPercent) < 0" style="color: red">Stop Loss Percent: {{ stopLossPercent.toFixed(2) }}%</span>
              <span v-else style="color: green">Stop Loss Percent: {{ stopLossPercent.toFixed(2) }}%</span>
            </v-col>
          </v-row>

          <!-- Trailing Stop 
          <v-row>
            <v-col cols="1">
              <v-checkbox v-model="trailingStopEnabled"></v-checkbox>
            </v-col>
            <v-col cols="11">
              <v-text-field
                :disabled="!trailingStopEnabled"
                v-model="localPosition.trailingStop"
                label="Trailing Stop"
                type="number"
              ></v-text-field>
            </v-col>
          </v-row>-->
        </div>
      </v-card-text>

      <v-card-actions>
        <v-btn color="error" text @click="close">Cancel</v-btn>
        <v-btn color="primary" text @click="save">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapActions } from "vuex";

export default {
  props: {
    /*
        "position":
        {
                "positionIdx": 0,
                "riskId": 1,
                "riskLimitValue": "150",
                "symbol": "BTCUSD",
                "side": "Sell",
                "size": "299",
                "avgPrice": "30004.5006751",
                "positionValue": "0.00996518",
                "tradeMode": 0,
                "positionStatus": "Normal",
                "autoAddMargin": 1,
                "adlRankIndicator": 2,
                "leverage": "10",
                "positionBalance": "0.00100189",
                "markPrice": "26926.00",
                "liqPrice": "999999.00",
                "bustPrice": "999999.00",
                "positionMM": "0.0000015",
                "positionIM": "0.00009965",
                "tpslMode": "Full",
                "takeProfit": "0.00",
                "stopLoss": "0.00",
                "trailingStop": "0.00",
                "unrealisedPnl": "0.00113932",
                "cumRealisedPnl": "-0.00121275",
                "createdTime": "1676538056258",
                "updatedTime": "1684742400015"
            }*/
    position: {
      type: Object,
    },
  },
  data() {
    return {
      takeProfitPercentSlider: 0,
      stopLossPercentSlider: 0,
      displayTradeStopModal: false,
      localPosition: { ...this.position }, // Create a local copy of the position prop
      takeProfitEnabled: false,
      stopLossEnabled: false,
      trailingStopEnabled: false,
    };
  },
  watch: {
    position: function (newVal) {
      this.localPosition = { ...newVal };
    },
  },
  methods: {
    ...mapActions(["setPositionTradingStop"]),
    async save() {
      let request = {
        symbol: this.position.symbol,
        market: this.position.market,
        positionIdx: this.position.positionIdx,
        apiAccountId: this.position.apiAccountId,
      };
      // Check if stopLoss, takeProfit, and trailingStop are disabled
      if (!this.stopLossEnabled) {
        request.stopLoss = "0.00";
      } else {
        request.stopLoss = this.localPosition.stopLoss;
      }
      if (!this.takeProfitEnabled) {
        request.takeProfit = "0.00";
      } else {
        request.takeProfit = this.localPosition.takeProfit;
      }
      if (!this.trailingStopEnabled) {
        request.trailingStop = "0.00";
      } else {
        request.trailingStop = this.localPosition.trailingStop;
      }
      console.log("save", request);
      // Call the setPositionTradingStop action with the localPosition data
      await this.setPositionTradingStop(request);

      this.displayTradeStopModal = false;
      this.calculateProfit();

      this.$emit("onSave", request);
    },
    getStopLossPercent(stopLossPrice) {
      if(Number(stopLossPrice) == 0) {
        return 0;
      }
      if (this.localPosition.side == "Buy") {
        if (this.localPosition.avgPrice != null && stopLossPrice != null) {
          return (
            ((this.localPosition.avgPrice - stopLossPrice) /
              this.localPosition.avgPrice) *
            100 *
            this.position.leverage
          ) * -1;
        }
      } else {
        if (this.localPosition.avgPrice != null && stopLossPrice != null) {
          return (
            ((stopLossPrice - this.localPosition.avgPrice) /
              this.localPosition.avgPrice) *
            100 *
            this.position.leverage
          ) *-1;
        }
      }
      return 0;
    },
    getTakeProfitPercent(takeProfitPrice) {
      if(Number(takeProfitPrice) == 0) {
        return 0;
      }
      if (this.localPosition.side == "Buy") {
        if (this.localPosition.avgPrice != null && takeProfitPrice != null) {
          return (
            ((takeProfitPrice - this.localPosition.avgPrice) /
              this.localPosition.avgPrice) *
            100 *
            this.position.leverage
          );
        }
      } else {
        if (this.localPosition.avgPrice != null && takeProfitPrice != null) {
          return (
            ((this.localPosition.avgPrice - takeProfitPrice) /
              this.localPosition.avgPrice) *
            100 *
            this.position.leverage
          );
        }
      }
      return 0;
    },

    show(position) {
      let usedPosition = position;
      if (position == null) {
        usedPosition = this.position;
      }
      console.log("show", usedPosition);
      if (usedPosition != null) {
        if (usedPosition.takeProfit != null && usedPosition.takeProfit != 0) {
          this.takeProfitEnabled = true;
          this.takeProfitPercentSlider = this.getTakeProfitPercent(
            usedPosition.takeProfit
          );
        } else {
          this.takeProfitEnabled = false;
        }
        if (usedPosition.stopLoss != null && usedPosition.stopLoss != 0) {
          this.stopLossEnabled = true;
          this.stopLossPercentSlider = this.getStopLossPercent(
            usedPosition.stopLoss
          );
        } else {
          this.stopLossEnabled = false;
        }
        
        /*
        if (
          usedPosition.trailingStop != null &&
          usedPosition.trailingStop != 0
        ) {
          this.trailingStopEnabled = true;
        }*/
      }
      this.displayTradeStopModal = true;
    },
    close() {
      this.displayTradeStopModal = false;
    },
    takeProfitSliderChanged() {
      console.log("takeProfitPercentSlider", this.takeProfitPercentSlider);
      if (this.localPosition.side == "Buy") {
        this.localPosition.takeProfit =
          this.localPosition.avgPrice *
          (1 + this.takeProfitPercentSlider / 100 / this.position.leverage);
      } else {
        this.localPosition.takeProfit =
          this.localPosition.avgPrice *
          (1 - this.takeProfitPercentSlider / 100 / this.position.leverage);
      }
    },
    stopLossSliderChanged() {
      console.log("stopLossPercentSlider", this.stopLossPercentSlider);
      if (this.localPosition.side == "Buy") {
        this.localPosition.stopLoss =
          this.localPosition.avgPrice *
          (1 - this.stopLossPercentSlider / 100 / this.position.leverage);
      } else {
        this.localPosition.stopLoss =
          this.localPosition.avgPrice *
          (1 + this.stopLossPercentSlider / 100 / this.position.leverage);
      }
    },
  },
  computed: {
    ...mapState(["tradingStops"]),
    takeProfitPercent() {
      console.log("🚀 ~ file: TradeStopView.vue:290 ~ get ~ value");
      if (this.takeProfitEnabled) {
        return this.getTakeProfitPercent(this.localPosition.takeProfit);
      }
      return 0;
    },
    stopLossPercent: function () {
      if (this.stopLossEnabled) {
        return this.getStopLossPercent(this.localPosition.stopLoss);
      }
      return 0;
    },
  },
};
</script>