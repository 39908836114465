const CROSS = `m512.001 84.853-84.853-84.853-171.147 171.147-171.148-171.147-84.853
84.853 171.148 171.147-171.148 171.148 84.853 84.853 171.148-171.147
171.147 171.147 84.853-84.853-171.148-171.148z`;

export default {
    simpleArrowUp: function(ctx, x, y, color) {
    ctx.beginPath();
    ctx.fillStyle = color;
    ctx.strokeStyle = color;
    ctx.moveTo(x - 5, y + 5);
    ctx.lineTo(x, y);
    ctx.lineTo(x + 5, y + 5);
    ctx.moveTo(x, y);
    ctx.lineTo(x, y + 15);
    ctx.stroke();
    ctx.closePath();
  },
  simpleArrowDown: function(ctx, x, y, color) {
    ctx.beginPath();
    ctx.fillStyle = color;
    ctx.strokeStyle = color;
    ctx.moveTo(x - 5, y - 5);
    ctx.lineTo(x, y);
    ctx.lineTo(x + 5, y - 5);
    ctx.moveTo(x, y);
    ctx.lineTo(x, y - 15);
    ctx.stroke();
    ctx.closePath();
  },
  simpleText: function(ctx, x, y, text, color) {
    ctx.font = "10px Arial";
    ctx.fillStyle = color;
    ctx.fillText(text, x, y);
  },
  draw_label(ctx, x, y, order, label_color, font) {
    ctx.fillStyle = label_color;
    ctx.font = font;
    ctx.textAlign = "center";
    ctx.fillText(order.status + " " + order.side, x, y - 25);
  },
  draw_circle(ctx, x, y, marker_size) {
    ctx.lineWidth = 1.5;
    ctx.beginPath();
    ctx.arc(x, y, marker_size + 0.5, 0, Math.PI * 2, true);
    ctx.fill();
    ctx.stroke();
  },
  draw_cross(ctx, x, y) {
    ctx.save();
    let p = new Path2D(CROSS);
    ctx.lineWidth = 150;
    ctx.translate(x - 5, y - 5);
    ctx.scale(0.0175, 0.0175);
    ctx.stroke(p);
    ctx.fill(p);
    ctx.scale(1, 1);
    ctx.restore();
  }
};