<template>
  <v-container>
    <v-card>
      <v-card-title>Triggers</v-card-title>
      <v-card-text>

        <v-chip-group column v-model="visibleFields" active-class="primary" multiple>
          <v-chip v-for="header in headers" :key="header.value" :value="header.value" class="ma-2">
            {{ header.text }}
          </v-chip>
        </v-chip-group>
        <v-data-table :headers="visibleHeaders" :items="triggers" :items-per-page="10" class="elevation-1">
          <template v-slot:[`item.enabled`]="{ item }">
            <v-checkbox v-model="item.enabled" @click="toggleEnabled(item)"></v-checkbox>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            <!-- Show local browser time  -->
            <span>{{ new Date(item.created_at).toLocaleString() }}</span>
          </template>
          <template v-slot:[`item.updated_at`]="{ item }">
            <!-- Show local browser time  -->
            <span>{{ new Date(item.updated_at).toLocaleString() }}</span>
          </template>
          <template v-slot:[`item.parameters`]="{ item }">
            <pre>{{ item.parameters }}</pre>
          </template>
          <template v-slot:[`item.last_checked_at`]="{ item }">
            <!-- Show local browser time  -->
            <span v-if="item.enabled && item.last_checked_at && lastCheckedMinutesAgo(item.last_checked_at) > 5"
              class="red--text">{{ new Date(item.last_checked_at).toLocaleString() }} ({{
                lastCheckedMinutesAgo(item.last_checked_at) }} min ago)</span>
            <span v-else-if="!item.last_checked_at && item.enabled" class="red--text">Never checked</span>
            <span v-else-if="item.last_checked_at">{{ new Date(item.last_checked_at).toLocaleString() }} ({{
              lastCheckedMinutesAgo(item.last_checked_at) }} min ago)</span>
            <span v-else-if="item.enabled" class="red--text">Never checked</span>
            <span v-else>Never checked</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editTrigger(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteTriggerPrompt(item)">
              mdi-delete
            </v-icon>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" small @click="clearExecutionCount(item)">
                  mdi-refresh
                </v-icon>
              </template>
              <span>Reset exectuion count</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.triggered_actions`]="{ item }">
            <v-chip-group column>
              <v-chip v-for="action in item.triggered_actions" :key="action" color="primary">{{ getActionById(action) &&
                getActionById(action).name }}</v-chip>
            </v-chip-group>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="newTrigger">Create Trigger</v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="dialog" max-width="500px">
      <trigger-form ref="triggerForm" :trigger-i-d="editedID" @close="close" :form-title="formTitle" />
    </v-dialog>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import TriggerForm from "./TriggerForm.vue";

export default {
  components: { TriggerForm },
  data: () => ({
    currentSchema: {},
    dialog: false,
    editedID: -1,
    visibleFields: ["enabled", "name", "triggered_actions", "last_checked_at", "parameters", "execution_count", "actions"],
    headers: [

      { text: "Enabled", value: "enabled" },
      { text: "Name", value: "name" },
      { text: "Description", value: "description" },
      { text: "Trigger Script", value: "template_script" },
      { text: "Triggered Actions", value: "triggered_actions" },
      { text: "Created At", value: "created_at" },
      { text: "Last Checked At", value: "last_checked_at" },
      { text: "Updated At", value: "updated_at" },
      { text: "Parameters", value: "parameters" },
      { text: "Execution count", value: "execution_count" },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),
  computed: {
    ...mapGetters(["getTriggerScriptsOptions", "getApiAccountsOptions", "getActionsOptions", "getActionById"]),
    visibleHeaders() {
      return this.headers.filter(h => this.visibleFields.includes(h.value));
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Trigger" : "Edit Trigger";
    },
    triggers() {
      return this.$store.state.triggers;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {
    this.fetchTriggers();
    this.fetchTriggerScripts();
    this.fetchApiAccounts();
    this.fetchActions();
  },
  methods: {
    ...mapActions(["fetchTriggers", "fetchTriggerScripts", "fetchApiAccounts", "createTrigger", "updateTrigger", "deleteTrigger", "fetchTriggerSchema", "fetchActions"]),
    newTrigger() {
      this.editedID = -1;
      if (this.$refs.triggerForm) this.$refs.triggerForm.loadForm();
      this.dialog = true;
    },
    async editTrigger(item) {
      this.editedID = item.id;
      if (this.$refs.triggerForm) this.$refs.triggerForm.loadForm();
      this.dialog = true;

    },
    lastCheckedMinutesAgo(timestamp) {
      if (timestamp) {
        var cDate = new Date(timestamp);
        var diff = new Date() - cDate;
        var minutes = Math.floor(diff / 60000);
        return minutes;
      }
      return "";
    },
    close() {
      this.editedID = -1;
      if (this.$refs.triggerForm) this.$refs.triggerForm.loadForm();
      this.dialog = false;
    },
    deleteTriggerPrompt(item) {
      const index = this.triggers.indexOf(item);
      const trigger = this.triggers[index];
      if (confirm("Are you sure you want to delete this trigger?")) {
        this.deleteTrigger(trigger);
      }
    },
    async toggleEnabled(item) {
      await this.updateTrigger({
        ...item

      });
    },
    async clearExecutionCount(item) {
      let cTrigger = Object.assign({}, item);
      await this.updateTrigger({
        ...cTrigger,
        execution_count: "0"
      });
    }
  },
};
</script>