<template>
  <v-card>
    <v-card-title>
      {{ formTitle }}
    </v-card-title>

    <v-card-text v-if="loaded">
      <v-form @submit.prevent="submit">
        <v-checkbox v-model="editedItem.enabled" label="Enabled"></v-checkbox>
        <v-text-field
          v-model="editedItem.name"
          label="Name"
          required
        ></v-text-field>
        <v-text-field
          v-model="editedItem.notes"
          label="Notes"
        ></v-text-field>
        <v-select
          v-model="editedItem.api_account"
          :items="getApiAccountsOptions"
          @change="updateSchema"
          label="API Account"
          required
        ></v-select>
        <!-- action_script select-->
        <v-select
          :disabled="!editedItem.api_account"
          v-model="editedItem.template_script"
          :items="getActionScriptsOptions"
          label="Action Script"
          @change="updateSchema"
          required
        ></v-select>

        <schema-form
          ref="schemaForm"
          @refetchSchema="updateSchema"
          v-model="editedItem.parameters"
          :schema="currentSchema"
        />
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-btn v-if="!hideClose" color="error" text @click="close">Cancel</v-btn>
      <v-btn v-if="!hideSave"
        :disabled="!loaded || editedItem.name === '' || editedItem.template_script === '' ||editedItem.api_account === ''"
        color="primary"
        text
        @click="save"
        >Save</v-btn
      >
    </v-card-actions>
        
    <v-progress-circular class="actionFormSpinner" v-if="!loaded" indeterminate color="primary"
      size="100"></v-progress-circular>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SchemaForm from "./SchemaForm.vue";
export default {
  components: { SchemaForm },
  props: {
    actionID: {
      type: Number,
      default: -1, // -1 means new action
    },

    formTitle: {
      type: String,
      default: "Action",
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
    hideSave: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loaded: false,
      editedItem: {
        enabled: true,
        name: "",
        notes: "",
        parameters: {},
      },
      defaultItem: {
        enabled: true,
        name: "",
        notes: "",
        parameters: {},
      },
      currentSchema: {},
    };
  },
  watch: {
    actionID: {
      handler: async function (val) {
        console.log("ActionForm: action changed", val);
        await this.loadForm();
      },
      deep: true,
    },
  },
  async mounted() {

    await this.loadForm();
  },
  computed: {
    ...mapGetters([
      "getActions",
      "getActionScripts",
      "getActionScriptsOptions",
      "getApiAccountsOptions",
      "getActionById"
    ]),
  },
  methods: {
    ...mapActions([
      "fetchActionSchema",
      "fetchActionScripts",
      "createAction",
      "updateAction",
      "deleteAction",
      "fetchActions",
      "fetchApiAccounts",
      "executeAction",
    ]),
    async loadForm(){
        if(this.actionID !== -1){
            console.log("Loading action", this.actionID);
            const cAction = this.getActionById(this.actionID)
            
            
            this.editedItem = Object.assign({}, cAction);
            await this.updateSchema();
            this.editedItem.parameters = cAction.parameters || {};
        } else {
            console.log("Loading new action");
            this.editedItem = Object.assign({}, this.defaultItem);
            await this.updateSchema();
        }
    },
    async updateSchema() {
      this.loaded = false;
      //console.log("Updating schema to ", this.editedItem.template_script);
      this.currentSchema = await this.fetchActionSchema({
        actionScriptClass: this.editedItem.template_script,
        params: {
          apiAccount: this.editedItem.api_account,
          ...this.editedItem.parameters,
        },
      });
      this.loaded = true;
      // load default values if not set
      if (!this.editedItem.parameters) {
        this.editedItem.parameters = {};
      }
      if (this.currentSchema) {
        for (const key in this.currentSchema) {
          if (!this.editedItem.parameters[key] && this.currentSchema[key].default !== undefined & this.currentSchema[key].default !== null) {
            this.editedItem.parameters[key] = this.currentSchema[key].default;
          }
        }
      }
    },
    close() {
      this.$emit("close");
    },
    async save() {
      console.log(
        "🚀 ~ file: ActionsView.vue:167 ~ save ~ this.editedItem:",
        this.editedItem
      );
      if (this.actionID !== -1) {
        await this.updateAction(this.editedItem);
      } else {
        await this.createAction(this.editedItem);
      }
      this.close();
    },
  },
};
</script>

<style>

.actionFormSpinner {
  left: 50%;
  margin-top: 50px;
  margin-left: -50px;
  margin-bottom: 50px;
}
</style>