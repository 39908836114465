<template>
  <div class="chartView">
    <trading-vue ref="tradingVue" :legend-buttons="['display', 'settings', 'up', 'down', 'add', 'remove']"
      :chart-config="{ DEFAULT_LEN: 70 }" :extensions="extensions" :overlays="overlays" :title-txt="market + ' ' + symbol"
      :data="chart" :width="width" :height="height" :toolbar="true" :index-based="index_based"
      :color-back="colors.colorBack" :color-grid="colors.colorGrid" :color-text="colors.colorText" :x-settings="xsett"
      :timezone="timezone" night="true" @object-selected="onSelectionChange"
      @settingsChanged="settingsChanged" />

    <v-progress-circular v-if="!chartInitialized"  id="chartLoadingSpinner"
     indeterminate color="primary" size="100" />

    <!-- TriggerLine Modal -->
    <trigger-line-modal ref="triggerLineModal"/>

  </div>
</template>

<script>
const debug = false;
import { mapState, mapGetters } from "vuex";



import TradingVue from "@plutos/plutos-tradingvuejs/src/TradingVue.vue";
import DataCube from "@plutos/plutos-tradingvuejs/src/helpers/datacube.js";

import Utils  from "@plutos/plutos-tradingvuejs/src/stuff/utils";
import AddWin from "tvjs-xp/src/extensions/legend-buttons/AddWin.vue";


import Overlays from "tvjs-overlays";
import ChartExtensions from "tvjs-xp";
//import ChartEvents from "./chart_addins/ChartEvents.vue";
//import TriggerProcessingResults from "./chart_addins/TriggerProcessingResults.vue";
import MultiSignalOverlay from "./chart_addins/MultiSignalOverlay.vue";
import PositionOverlay from "./chart_addins/PositionOverlay.vue";
import AccountOrderHistory from "./chart_addins/AccountOrderHistory.vue";
import TriggerLine from "./chart_addins/TriggerLine.vue";

import TriggerLineModal from "./TriggerLineModal.vue";
import RsiMfiStochRsiCombined from "./chart_addins/RsiMfiStochRsiCombined.vue";


export default {
  name: "ChartView",
  description: "Displays chart",
  components: {
    TradingVue,
    TriggerLineModal,
  },
  props: {
    exchange: {
      type: String,
    },
    market: {
      type: String,
    },
    symbol: {
      type: String,
    },
    timeframe: {
      type: String,
    },
    /**
     * @description - Object with layout data
     * @example
     * {
     *  "onchart": [ // Displayed ON the chart
     *      {
     *          "name": "<Indicator name>",
     *          "type": "<e.g. EMA, SMA>",
     *          "data": [
     *              [timestamp, ... ], // Arbitrary length
     *              ...
     *          ],
     *          "settings": { } // Arbitrary settings format
     *      },
     *      ...
     *  ],
     *  "offchart": [ // Displayed BELOW the chart
     *      {
     *          "name": "<Indicator name>",
     *          "type": "<e.g. RSI, Stoch>",
     *          "data": [
     *              [timestamp, ... ], // Arbitrary length
     *              ...
     *          ],
     *          "settings": { } // Arbitrary settings format
     *      },
     *      ...
     *  ]
     */
    layout: {
      type: Object,
    }
  },
  data() {
    return {
      timezone: 2,
      chart: new DataCube({ chart: {}, onchart: [], offchart: [] }),
      width: window.innerWidth,
      height: window.innerHeight - 40,
      log_scale: false,
      index_based: false,
      selectedDrawingId: null,
      selectedDrawingTriggers: null,
      chartInitialized: false,
      timeoutId: null,
      extensions: [...Object.values(ChartExtensions)
    ],
      overlays: [
        ...Object.values(Overlays),
        //ChartEvents,
        TriggerLine,
        PositionOverlay,
        AccountOrderHistory,
        RsiMfiStochRsiCombined,
        MultiSignalOverlay
        //TriggerProcessingResults
      ],
      xsett: {
        "grid-resize": { min_height: 30 },
      },
    };
  },
  computed: {
    ...mapGetters([
      "getChartData",
      "getExchangeOptions",
      "getActions",
      "getExchangeMarketOptions",
      "getExchangeMarketSymbolOptions",
      "getTriggersForDrawingId",
      "getActionById",
      "getApiAccountById",
      "getTriggers",
    ]),
    ...mapState([
      "processedTrades",
      "viewedAccount",
      "exchangesDetails",
      "selectedPosition",
    ]),



    colors() {
      return this.$vuetify.theme.dark
        ? {
          colorBack: "#000",
          colorGrid: "#333",
          colorText: "#fff",
        }
        : {
          colorBack: "#fff",
          colorGrid: "#eee",
          colorText: "#333",
        };
    },
  },
  watch: {
    layout: {
      handler: function () {
        // if (debug) console.log("ChartView layout changed", {onchart: this.layout.onchart, offchart: this.layout.offchart});
        // P-382 this line is the problem
        // when first loading the page this keeps overwriting the added /removed overlay
        this.updateLayout();
      },
      deep: true,
    },
    symbol() {
      this.resetChart();
      this.initOHLCV();
    },
    timeframe() {
      this.initOHLCV();
    },
    log_scale(value) {
      if (this.chart.data.chart) {
        this.$set(this.chart.data.chart, "grid", {
          logScale: value,
        });
      }
    }
  },
  async mounted() {
    //if (debug) console.log("ChartView mounted", this.$refs.tradingVue);
    window.addEventListener("resize", this.onResize);
    this.$store.dispatch("fetchExchangeDetails", "bybit").catch((err) => {
      console.error("fetchExchangeDetails error", err);
    });
    this.$store.dispatch("fetchExchangeDetails", "bybit-testnet").catch((err) => {
      console.error("fetchExchangeDetails error", err);
    });
    this.onResize();

    await this.initOHLCV();
    this.updateLayout();

    this.chart.onrange(this.onRangeChange);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    settingsChanged() {
      
      this.$emit('onOrOffChartChanged')
      // emit new layout
      const onchart = this.chart.get("onchart");
      const offchart = this.chart.get("offchart");
      const layout = {
        onchart, offchart
      }
      if(debug) console.log("ChartView settingsChanged", layout)
      this.$emit("update:layout", layout);

    },
    updateLayout(){
      if(debug) console.log("ChartView updateLayout", {onchart: this.layout.onchart, offchart: this.layout.offchart});
      //P-382 here we copy the layout since else layout watcher will be triggered again and overwrite back to previous layout
      if (this.layout) {
          if (this.layout.onchart) {
            this.chart.del("onchart");
            for (let i = 0; i < this.layout.onchart.length; i++) {
              const curr = JSON.parse(JSON.stringify(this.layout.onchart[i]));
              this.chart.add("onchart", curr);
            }
          }
          
          if (this.layout.offchart) {
            this.chart.del("offchart");
            for (let i = 0; i < this.layout.offchart.length; i++) {
              const curr = JSON.parse(JSON.stringify(this.layout.offchart[i]));
              this.chart.add("offchart", curr);
            }
          }
        }
    },
    updateChartState(chartInitialized) {
      this.chartInitialized = chartInitialized;
      this.$emit("chartStateChanged", { chartInitialized });
    },
    showAddOverlayModal() {
      const legendBtnController = this.$refs.tradingVue.controllers.find(
        (item) => item.name == "legend-buttons" && item.widgets
      );

      let id = `AddWin-${Utils.uuid2()}`;
      this.$refs.tradingVue.$set(legendBtnController.widgets, id, {
        id,
        cls: AddWin,
        data: {},
      });
    },

    resetChart() {
      if(debug) console.log("resetChart");
      this.updateChartState(false);
      this.chart.del("chart.data", []);
      this.chart.del("onchart", []);
      this.chart.del("offchart", []);
      this.chartDataChanged = false;

    },
    async initOHLCV(timeframe) {
      try {

        if(debug) console.log("start initOHLCV", timeframe);
      if (
        !this.exchange ||
        !this.market ||
        !this.symbol ||
        !this.timeframe
      ) {
        console.warn("initOHLCV - missing data, ERROR: #561256234");
        return;
      }
      if(debug) console.log("initOHLCV - loading data for", this.exchange, this.market, this.symbol);
      
      //this.chart.del("chart.data", []);

      // fetching OHLCV from local storage
      this.$store.commit('loadOHLCVFromLocalStorage', { exchange: this.exchange, market: this.market, symbol: this.symbol, timeframe: this.timeframe });
      const ohlcvFromStorage = this.$store.getters.getOHLCV({ exchange: this.exchange, market: this.market, symbol: this.symbol, timeframe: this.timeframe })
      this.chart.set("chart.data", ohlcvFromStorage);




      if (debug)
        console.log("🚀 ~ ChartView.vue ~ initOHLCV | fetchOHLCV ", this.timeframe, this.exchange, this.market, this.symbol);
      // eslint-disable-next-line new-cap

      const ohlcv = await this.$store.dispatch("fetchOHLCV", {
        exchange: this.exchange,
        market: this.market,
        symbol: this.symbol,
        timeframe: this.timeframe,
        limit: 10000,
      });


      this.chart.set("chart.data", ohlcv);


      if (debug) console.log("end initOHLCV", this.chart, this.exchange, this.market, this.symbol);
      this.updateChartState(true);
      } catch(e){
        console.error("initOHLCV", e)
      }
      
    },

refreshChart() {
  if(debug) console.log("refreshChart");
  this.initOHLCV().then()
},
    async loadDataChunk(range) {
      if (!this || !this.exchange || !this.market || !this.symbol || !this.timeframe) {
        console.error("loadDataChunk - missing data, ERROR: #561256234");
        return;
      }
      const [t1, t2] = range;

      const chunk = await this.$store.dispatch("fetchOHLCV", {
        exchange: this.exchange,
        market: this.market,
        symbol: this.symbol,
        timeframe: this.timeframe,
        start: t1,
        end: t2,
      });

      return chunk;
    },
    onSelectionChange(selection, drawingId, fullDrawingId, param3, param4) {
      const drawingIdWithoutTimestamp = fullDrawingId.split("-")[0];
      if (debug)
        console.log(
          "Selection changed",
          selection,
          fullDrawingId,
          drawingId,
          param3,
          param4
        );
      this.selectedDrawingId = drawingId;
      const drawingsTriggers = this.getTriggersForDrawingId(
        this.exchange,
        this.market,
        this.symbol,
        drawingIdWithoutTimestamp
      );
      //console.log("🚀 ~ file: ChartView.vue:202 ~ onSelectionChange ~ this.selectedDrawingTriggers:", this.selectedDrawingTriggers)
      this.selectedDrawingTriggers = drawingsTriggers.map((trigger) => {
        return `${trigger.enabled ? "" : "Disabled | "}${trigger.name}  | ${trigger.parameters.priceReference
          } ${trigger.parameters.condition} line |`;
      });
    },
    onResize() {
      //console.log("ChartView onResize");
      const isChartViewPage = this.$route.path.includes("chart");

      if (isChartViewPage) {
        const offset = document.getElementsByTagName("header")[0].clientHeight;

        //console.log("ChartView onResize | matchedEl client width,height", matchedEl.clientWidth , matchedEl.clientHeight);

        //this.width = matchedEl.clientWidth;
        //this.height = matchedEl.clientHeight-offset
        this.width = window.innerWidth;
        this.height = window.innerHeight - (offset + 45);
      } else {
        this.width = this.$refs.chartView.clientWidth;
        this.height = this.$refs.chartView.clientHeight;
      }

      //console.log("ChartView onResize |", this.width, this.height);
    },
    async onRangeChange(range) {
      if(debug) console.log("Range changed", range);
      return await this.loadDataChunk(range);
    }
  },
};
</script>

<style>
.log-scale {
  position: absolute;
  top: 60px;
  right: 80px;
  color: #888;
  font: 11px -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.chartView {
  width: 100%;
  height: 100%;
}

.v-list {
  z-index: 1000;
}

.chartOuterDiv {
  position: fixed;
  /*top: 120px;
bottom: 0;
left: 0;
right: 0;*/
}

#selectedDrawingId {
  position: absolute;
  bottom: 80px;
  right: 280px;
  z-index: 1;
  color: #888;
  font: 11px -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

#selectedDrawingTriggers {
  position: absolute;
  bottom: 60px;
  right: 280px;
  z-index: 1;
  color: #888;
  font: 11px -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

#lastRefresh {
  position: absolute;
  z-index: 2;
  color: #888;
  font: 11px -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

  @media screen and (max-width: 600px) {
    bottom: 38px;
    right: 62px;
  }

  @media screen and (min-width: 600px) {
    bottom: 40px;
    right: 280px;
  }
}

#lastChartSave {
  position: absolute;
  z-index: 1;
  color: #888;
  font: 11px -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

  @media screen and (max-width: 600px) {
    bottom: 52px;
    right: 62px;
  }

  @media screen and (min-width: 600px) {
    bottom: 60px;
    right: 280px;
  }
}

.container {
  padding: 0;
}

#saveChartBtn {
  position: absolute;

  z-index: 1;

  /* On screens that are 600px or less, show btn at bottom */
  @media screen and (max-width: 600px) {
    bottom: 30px;
    right: 250px;
  }

  /* On screens that are 600px or more, show btn at right */
  @media screen and (min-width: 600px) {
    top: 20px;
    right: 250px;
  }
}

#changeChartSrcBtn {
  z-index: 1;
}

#timeframeSelector {
  @media screen and (max-width: 600px) {}

  @media screen and (min-width: 600px) {}

  top: 5px;
  right: 20px;

  font: 12px -apple-system,
  BlinkMacSystemFont,
  Segoe UI,
  Roboto,
  Oxygen,
  Ubuntu,
  Cantarell,
  Fira Sans,
  Droid Sans,
  Helvetica Neue,
  sans-serif;
}

@media only screen and (max-device-width: 480px) {

  .log-scale,
  .gc-mode {
    right: 50px !important;
  }
}

#chartLoadingSpinner {
  position: absolute;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
