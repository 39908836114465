<template>
  <div class="dashboard">

    <!-- Button for creating/updating/selecting other dashboards -->
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn
          style="position: absolute; top: 0; right: 140px; z-index: 1000"
          @click="dashboardSelectionDialog = true"
          text
          v-on="on"
        >
          <v-icon>mdi-view-dashboard</v-icon>
        </v-btn>
      </template>
      <span>Manage dashboards</span>
    </v-tooltip>
    <!--Button for locking dashboard-->
    <v-tooltip v-if="!dashboardLocked" top>
      <template v-slot:activator="{ on }">
        <v-btn
          style="position: absolute; top: 0; right: 0; z-index: 1000"
          @click="lockDashboard"
          text
          v-on="on"
        >
          <v-icon>mdi-lock</v-icon>
        </v-btn>
      </template>
      <span>Lock dashboard to disable dragging and resizing of dashboard.</span>
    </v-tooltip>
    <!--Button for unlocking dashboard with tooltip-->
    <v-tooltip v-else top>
      <template v-slot:activator="{ on }">
        <v-btn
          style="position: absolute; top: 0; right: 0; z-index: 1000"
          @click="unlockDashboard"
          text
          v-on="on"
        >
          <v-icon>mdi-lock-open</v-icon>
        </v-btn>
      </template>
      <span>Unlock dashboard to move or resize items</span>
    </v-tooltip>
    <!-- Checkboxes to control item visibility -->
    <v-dialog v-model="dialog" max-width="500px" v-if="!dashboardLocked">
      <template v-slot:activator="{ on }">
        <v-btn
          style="position: absolute; top: 0; right: 60px; z-index: 1000"
          text
          v-on="on"
        >
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="headline">Dashboard settings</v-card-title>
        <v-card-text>
          <v-checkbox
            v-for="item in items"
            :key="item.id"
            :label="item.type"
            v-model="item.visible"
          ></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog for creating, updating and selecting dashboards -->
    <v-dialog v-model="dashboardSelectionDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline">Available dashboards</v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item
              v-for="dashboard in dashboards"
              :key="dashboard.id"
            >
              <v-list-item-title v-if="editedDashboardId !== dashboard.id">
                <a @click="$router.push(`/dashboard/${dashboard.id}`); dashboardSelectionDialog = false;">
                  {{ dashboard.name }}
                </a>
              </v-list-item-title>
              <v-list-item-title v-else>
                <v-text-field
                  v-model="editedDashboardName"
                  label="Dashboard name"
                  required
                ></v-text-field>
              </v-list-item-title>
              <v-list-item-action>
                <!-- Save changed dashboard -->
                <v-btn
                  icon
                  v-if="editedDashboardId === dashboard.id"
                  @click="
                    updateDashboard({
                      id: dashboard.id,
                      name: editedDashboardName,
                    });
                    editedDashboardId = null;
                    editedDashboardName = '';
                  "
                  ><v-icon>mdi-content-save</v-icon></v-btn
                >
                <div v-else>
                  <v-btn
                    icon
                    @click="
                      editedDashboardId = dashboard.id;
                      editedDashboardName = dashboard.name;
                    "
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    @click="
                      confirmDeleteDialog = true;
                      toBeDeletedDashboard = dashboard;
                    "
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-row>
            <v-col cols="10">
              <v-text-field
                v-model="creatingDashName"
                label="Dashboard name"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-btn
                :disabled="creatingDashName === ''"
                color="primary"
                icon
                @click="
                  createDashboard({ name: creatingDashName });
                  creatingDashName = '';
                "
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="
              dashboardSelectionDialog = false;
              editedDashboardId = null;
            "
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Confirm dashboard delete dialog-->
    <v-dialog v-model="confirmDeleteDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline">Confirm delete</v-card-title>
        <v-card-text>
          Deleting dashboard <b>{{ toBeDeletedDashboard.name }}</b> <br />
          Are you sure you want to delete this dashboard?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="confirmDeleteDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="
              deleteDashboard(toBeDeletedDashboard.id);
              toBeDeletedDashboard = {};
              confirmDeleteDialog = false;
            "
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div
      v-for="item in visibleItems"
      :key="item.id"
      :style="{
        left: item.x + 'px',
        top: item.y + 'px',
        width: item.width + 'px',
        height: item.height + 'px',
      }"
      class="dashboard-item"
      @mousedown="startDrag(item, $event)"
    >
      <div
        v-if="!dashboardLocked"
        class="resize-handle bottom-right"
        @mousedown="startResize(item, $event)"
      ></div>
      <component :is="item.type" :ref="item.ref" />
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import ActionsView from "./ActionsView.vue";
import TriggersView from "./TriggersView.vue";
import ChartView from "./ChartView.vue";
import EventView from "./EventView.vue";
import PortfolioView from "./PortfolioView.vue";
import PositionsView from "./PositionsView.vue";
export default {
  components: {
    ActionsView,
    TriggersView,
    ChartView,
    EventView,PortfolioView, PositionsView
  },
  data() {
    return {
      editedDashboardName: "",
      editedDashboardId: null,
      toBeDeletedDashboard: {},
      confirmDeleteDialog: false,
      creatingDashName: "",
      dashboardLocked: true,
      dashboardSelectionDialog: false,
      dialog: false,
      items: [],
      defaultItems: [
        {
          id: 1,
          visible: true,
          ref: "ChartViewResizableBox",
          type: "ChartView",
          x: 0,
          y: 0,
          width: 800,
          height: 400,
        },

        {
          id: 2,
          visible: true,
          ref: "EventViewResizableBox",
          type: "EventView",
          x: 0,
          y: 400,
          width: 400,
          height: 400,
        },
        {
          id: 3,
          visible: true,
          ref: "PortfolioViewResizableBox",
          type: "PortfolioView",
          x: 400,
          y: 400,
          width: 400,
          height: 400,
        },
        {
          id: 4,
          visible: true,
          ref: "PositionsViewResizableBox",
          type: "PositionsView",
          x: 800,
          y: 0,
          width: 400,
          height: 400,
        },
        {
          id: 5,
          visible: true,
          ref: "TriggersViewResizableBox",
          type: "TriggersView",
          x: 800,
          y: 400,
          width: 400,
          height: 400,
        },
        {
          id: 6,
          visible: false,
          ref: "ActionsViewResizableBox",
          type: "ActionsView",
          x: 1200,
          y: 400,
          width: 500,
          height: 400,
        },
      ],
      draggingItem: null,
      startX: 0,
      startY: 0,
      resizingItem: null,
      startWidth: 0,
      startHeight: 0,
      gridSize: 50
    };
  },
  async mounted() {
    // copy default items
    this.items = this.defaultItems.map((item) => ({ ...item }));

    await this.fetchAllDashboards();
    if (this.currentDashboardId) {
      this.loadDashboard(this.currentDashboardId);
    }

    

    this.rearrangeDashboard()
  },
  watch:{
  '$route': function() {
    
    if (this.currentDashboardId) {
      this.loadDashboard(this.currentDashboardId);
    }
    }
  },
  computed: {
    ...mapState(["dashboards"]),
    currentDashboardId() {
      if(this.$route.params && this.$route.params.id){
        return this.$route.params.id;
      } else {
        return null;
      }
    },
    currentDashboard() {
      return this.dashboards.find(
        (dashboard) => dashboard.id === Number(this.currentDashboardId)
      );
    },
    currentDashboardName() {
      return this.currentDashboard ? this.currentDashboard.name : "";
    },
    visibleItems() {
      return this.items.filter((item) => item.visible);
    },
  },
  methods: {
    ...mapActions([
      "createDashboard",
      "fetchAllDashboards",
      "updateDashboard",
      "deleteDashboard",
    ]),
    ...mapGetters(["getDashboardsOptions"]),

    getDefaultDisabledItems() {
      return this.items.map((item) => {item.visible = false; return item;});
    },
    loadDashboard(dashboardId) {
      console.log('Loading dashbaord...',dashboardId);
      const dashboard = this.dashboards.find(
        (dashboard) => dashboard.id === Number(dashboardId)
      );
      console.log('Loading dashbaord...',dashboard);
      if(dashboard && dashboard.data && Object.keys(dashboard.data).length > 0){
        this.items = dashboard.data;
      }else{
        this.items = this.getDefaultDisabledItems();
      }
      this.dashboardLocked = true;
    },
    snapToGrid(value) {
    return Math.round(value / this.gridSize) * this.gridSize;
  },
    startDrag(item, event) {
      if (this.resizingItem || this.dashboardLocked) return;
      this.draggingItem = item;
      this.startX = event.clientX;
      this.startY = event.clientY;
      document.addEventListener("mousemove", this.dragItem);
      document.addEventListener("mouseup", this.stopDrag);
    },
    dragItem(event) {
    if (!this.draggingItem) return;
    const dx = event.clientX - this.startX;
    const dy = event.clientY - this.startY;
    const snappedX = this.snapToGrid(this.draggingItem.x + dx);
    const snappedY = this.snapToGrid(this.draggingItem.y + dy);
    this.draggingItem.x = snappedX;
    this.draggingItem.y = snappedY;
    this.startX = event.clientX;
    this.startY = event.clientY;
  },
    stopDrag() {
      this.draggingItem = null;
      document.removeEventListener("mousemove", this.dragItem);
      document.removeEventListener("mouseup", this.stopDrag);
    },
    startResize(item, event) {
      //console.log("startResize",item,event);
      this.resizingItem = item;
      this.startWidth = item.width;
      this.startHeight = item.height;
      this.startX = event.clientX;
      this.startY = event.clientY;
      document.addEventListener("mousemove", this.resizeItem);
      document.addEventListener("mouseup", this.stopResize);
    },
  resizeItem(event) {
    if (!this.resizingItem) return;
    const dx = event.clientX - this.startX;
    const dy = event.clientY - this.startY;
    const snappedWidth = this.snapToGrid(this.startWidth + dx);
    const snappedHeight = this.snapToGrid(this.startHeight + dy);
    this.resizingItem.width = Math.max(snappedWidth, 100);
    this.resizingItem.height = Math.max(snappedHeight, 100);
  },
    stopResize() {
      console.log("stopResize");
      // if a component has a resize method, call it
      if (
        this.$refs[this.resizingItem.ref] &&
        this.$refs[this.resizingItem.ref].length > 0 &&
        this.$refs[this.resizingItem.ref][0].onResize
      ) {
        this.$refs[this.resizingItem.ref][0].onResize();
      }
      this.resizingItem = null;
      document.removeEventListener("mousemove", this.resizeItem);
      document.removeEventListener("mouseup", this.stopResize);
    },
    lockDashboard() {
      this.dashboardLocked = true;
      this.updateDashboard({
        id: this.currentDashboardId,
        name: this.currentDashboardName,
        data: this.items,
      });
    },
    unlockDashboard() {
      this.dashboardLocked = false;
    },
  },
};
</script>
<style>
.dashboard {
  position: relative;
  width: 100%;
  height: 100%;
}

.dashboard-item {
  position: absolute;
  overflow: scroll;
  border: 1px solid rgb(150, 150, 150);
}

.resize-handle {
  z-index: 1;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 10px;
  height: 10px;
  background-color: gray;
  cursor: se-resize;
}

.visibility-control {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1000;
  background-color: white;
  padding: 10px;
  border: 1px solid black;
  border-radius: 5px;
  margin: 10px;
}
</style>