/* eslint-disable no-console */

import { register } from 'register-service-worker'


const initialiseState = (reg) => {
  if (!reg.showNotification) {
      console.warn('Showing notifications isn\'t supported ☹️😢');
      return
  }
  if (Notification.permission === 'denied') {
      console.warn('You prevented us from showing notifications ☹️🤔');
      return
  }
  if (!('PushManager' in window)) {
      console.warn("Push isn't allowed in your browser 🤔");
      return
  }
  subscribe(reg);
}



function urlB64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  const outputData = outputArray.map((output, index) => rawData.charCodeAt(index));

  return outputData;
}

const subscribe = async (reg) => {
  const subscription = await reg.pushManager.getSubscription();
  if (subscription) {
      sendSubData(subscription);
      return;
  }

  const key = "BCl7LtJoe5AFSNje4cV2rQPpoqHW3UntljerE8f6vFiLhjUotGkRSwPobqXDQxgqu7vxdor0sQh4xTmGqoPnRlY"
  const options = {
      userVisibleOnly: true,
      // if key exists, create applicationServerKey property
      ...(key && {applicationServerKey: urlB64ToUint8Array(key)})
  };

  const sub = await reg.pushManager.subscribe(options);
  sendSubData(sub)
};

const sendSubData = async (subscription) => {
  const browser = navigator.userAgent.match(/(firefox|msie|chrome|safari|trident)/ig)[0].toLowerCase();
  const data = {
      status_type: 'subscribe',
      subscription: subscription.toJSON(),
      browser: browser,
  };

  const res = await fetch(process.env.PLUTOS_BACKEND_URL+'/webpush/save_information', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
          'content-type': 'application/json',
          'Authorization': 'Token ' + localStorage.getItem("token")
      },
      credentials: "include"
  });

  handleResponse(res);
};

const handleResponse = (res) => {
  console.log(res.status);
};


console.log("registerServiceWorker.js", process.env)
console.log("BACKEND:", process.env.VUE_APP_PLUTOS_BACKEND_URL)
//if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready (reg) {
      initialiseState(reg)
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated () {
      console.log('New content is available; please refresh.')
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
//}
