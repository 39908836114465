<template>
    <!--vuetify modal  -->
    <v-dialog v-model="displayTriggerLineModal" max-width="800px" persistent>
        <v-card>
            <v-card-title>
                <span class="headline">Trigger Line</span>
            </v-card-title>
            <v-card-content>
                <v-tabs v-model="currTab">
                    <v-tab>Long</v-tab>
                    <v-tab>Short</v-tab>
                    <v-tab-item>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <TriggerFormVue ref="longTriggerForm" formTitle="Long trigger" :triggerID="longTriggerID"
                                    hideSave hideClose />
                            </v-col>
                            <v-col v-for="(actionID, index) in longActionIDs" :key="index" cols="12" sm="6">
                                <ActionFormVue :ref="'longActionForm-' + actionID" formTitle="Long action"
                                    :actionID="actionID" hideSave hideClose />
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <TriggerFormVue ref="shortTriggerForm" formTitle="Short trigger" :triggerID="shortTriggerID"
                                    hideSave hideClose />
                            </v-col>
                            <v-col v-for="(actionID, index) in shortActionIDs" :key="index" cols="12" sm="6">
                                <ActionFormVue :ref="'shortActionForm-' + actionID" formTitle="Short action"
                                    :actionID="actionID" hideSave hideClose />
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs>
            </v-card-content>
            <v-card-actions>
                <v-btn color="error" text @click="close">Cancel</v-btn>
                <v-btn color="primary" text @click="save">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import TriggerFormVue from './TriggerForm.vue';
import ActionFormVue from './ActionForm.vue';
import {  mapGetters } from "vuex";

export default {
    name: "TriggerLineModal",
    components: {
        TriggerFormVue,
        ActionFormVue,
    },
    props: {
    },
    data() {
        return {
            currTab: 0,
            displayTriggerLineModal: false,
            longTriggerID: null,
            shortTriggerID: null,
            longActionIDs: [],
            shortActionIDs: [],
            triggerLineRef: null,
        };
    },
    mounted() {
        if (this.$store.state.triggerScripts.length == 0)
            this.$store.dispatch("fetchTriggerScripts");
        if (this.$store.state.actionScripts.length == 0)
            this.$store.dispatch("fetchActionScripts");

    },
    watch: {

        longTriggerID: {
            handler: async function () {
                if (this.$refs.longTriggerForm) this.$refs.longTriggerForm.loadForm();
            },
        },
        shortTriggerID: {
            handler: async function () {
                if (this.$refs.shortTriggerForm) this.$refs.shortTriggerForm.loadForm();
            },
        },
        longActionIDs: {
            handler: async function () {
                for (let actionID of this.longActionIDs) {
                    const actionRef = this.$refs['longActionForm-' + actionID];
                    if (actionRef && actionRef.loadForm)
                        this.$refs['longActionForm-' + actionID].loadForm();
                }
            },
            deep: true,
        },
        shortActionIDs: {
            handler: async function () {
                for (let actionID of this.shortActionIDs) {
                    const actionRef = this.$refs['shortActionForm-' + actionID];
                    if (actionRef && actionRef.loadForm)
                        this.$refs['shortActionForm-' + actionID].loadForm();
                }
            },
            deep: true,
        }
    },
    computed: {

        ...mapGetters([
            'getTriggerById', 'getActionById'
        ]),
    },
    methods: {
        isTriggerAndActionsActive(triggerID, actionIDs) {
            if (triggerID == null && actionIDs.length == 0) {
                return false;
            }

            const trigger = this.getTriggerById(triggerID);
            console.log("trigger", trigger);
            if (trigger == null || !trigger.enabled) return false;

            const actions = actionIDs.map(actionID => this.getActionById(actionID));

            if (actions.length == 0) return false;

            for (let action of actions) {
                if (action == null || !action.enabled) return false;
            }

            return true;
        },
        loadTriggerLineModal(triggerLineRef) {
            const { longTriggerID, shortTriggerID, longActionIDs, shortActionIDs } = triggerLineRef.settings;
            
            if(this.isTriggerAndActionsActive(longTriggerID, longActionIDs)){
                this.currTab = 0;
            } else if(this.isTriggerAndActionsActive(shortTriggerID, shortActionIDs)){
                this.currTab = 1;
            }
            this.longTriggerID = longTriggerID;
            this.shortTriggerID = shortTriggerID;
            this.longActionIDs = longActionIDs;
            this.shortActionIDs = shortActionIDs;
            this.triggerLineRef = triggerLineRef;
            this.displayTriggerLineModal = true;
        },
        save() {
            if (this.$refs.longTriggerForm)
                this.$refs.longTriggerForm.save();
            if (this.$refs.shortTriggerForm)
                this.$refs.shortTriggerForm.save();
            for (let actionID of this.longActionIDs) {
                const cActionRef = this.$refs['longActionForm-' + actionID]
                if (cActionRef && cActionRef[0] && cActionRef[0].save){
                    cActionRef[0].save();
                } else {
                    console.warn("Action form not found for", actionID)
                }
            }
            for (let actionID of this.shortActionIDs) {
                const cActionRef = this.$refs['shortActionForm-' + actionID]
                if (cActionRef && cActionRef[0] && cActionRef[0].save){
                    cActionRef[0].save();
                } else {
                    console.warn("Action form not found for", actionID)
                }
                    
            }

            this.close();
        },
        close() {
            this.displayTriggerLineModal = false;
            this.reset();
        },
        reset() {
            this.longTriggerID = null;
            this.shortTriggerID = null;
            this.longActionIDs = [];
            this.shortActionIDs = [];
        }
    }
}
</script>

<style></style>