<script>
/*
Done: 
* MACD
* RSI
* StochRSI
* MFI
* Parabolic SAR

TODO: 
Bollinger Bands
Stochastic oscillator
Moving averages
ATR
OBV
CCI
ADX
ichimoku cloud
AROON
Fib
Ultimate Oscillator:
Williams %R
CMF
Klinger
ROC
*/
import { Overlay } from 'trading-vue-js'
import drawingTools from './common/drawingTools'

export default {
    name: 'MultiSignalOverlay',
    mixins: [Overlay],
    methods: {
        meta_info() {
            return {
                author: 'Adam Ujma', version: '1.0.1',
                desc: 'Multi signal overlay',
                preset: {
                    name: 'MultiSignal $param_k $param_d $smooth $rsiLength',
                }
            }
        },

        calcIndicatorSignals(preprocessedData){
            const rsiValue = preprocessedData[2];
                const mfiValue = preprocessedData[1];
                const wprCrossUp = preprocessedData[3];
                const wprCrossDown = preprocessedData[4];
                const stochRsiCrossUp = preprocessedData[5];
                const stochRsiCrossDown = preprocessedData[6];
                const sarSignalUp = preprocessedData[7];
                const sarSignalDown = preprocessedData[8];
                const macdSignalUp = preprocessedData[9];
                const macdSignalDown = preprocessedData[10];
                const crossupEmaRibbons = preprocessedData[11];
                const crossdownEmaRibbons = preprocessedData[12];

                
                let result =  {
                    bullish: {
                        mfi: mfiValue < 20,
                        rsi: rsiValue < 20,
                        wpr: wprCrossUp,
                        sar: sarSignalUp,
                        stochRsi: stochRsiCrossUp,
                        macd: macdSignalUp,
                        emaRibbons: crossupEmaRibbons
                    },
                    bearish: {
                        mfi: mfiValue > 80,
                        rsi: rsiValue > 80,
                        wpr: wprCrossDown,
                        sar: sarSignalDown,
                        stochRsi: stochRsiCrossDown,
                        macd: macdSignalDown,
                        emaRibbons: crossdownEmaRibbons
                    }
                }

                // count true bullish and bearish keys
                let bullishCount = 0;
                let bearishCount = 0;
                for (const key in result.bullish) {
                    if (key == 'emaRibbons'){
                        for (const key2 in result.bullish[key]) {
                            if (result.bullish[key][key2]) {
                                bullishCount++;
                            }
                        }
                        continue
                    }
                    if (result.bullish[key]) {
                        bullishCount++;
                    }
                }
                for (const key in result.bearish) {
                    if (key == 'emaRibbons'){
                        for (const key2 in result.bearish[key]) {
                            if (result.bearish[key][key2]) {
                                bearishCount++;
                            }
                        }
                        continue
                    }
                    if (result.bearish[key]) {
                        bearishCount++;
                    }
                }

                result.bullishCount = bullishCount;
                result.bearishCount = bearishCount;

                return result;
        },
        mapSignalNames(signalKey, signals){
            
            let result = '';
            switch (signalKey) {
                case 'wpr':
                    return 'Williams R%';
                case 'mfi':
                    return 'MFI';
                case 'rsi':
                    return 'RSI';
                case 'sar':
                    return 'SAR';
                case 'stochRsi':
                    return 'StochRSI';
                case 'macd':
                    return 'MACD';
                case 'emaRibbons':
                    result='EMA '
                    for (const key in signals.emaRibbons) {
                        if (signals.emaRibbons[key]) {
                            result += key + 'x['+signals.emaRibbons[key]+'] ';
                        }
                    }
                    return result
                default:
                    return signalKey;
            }
        },

        draw(ctx) {
            if (this.$options.propsData.data.length == 0) return
            if (!this.layout) return
            if (!this.layout.candles) return

            const calcData = this.$options.propsData.data;
            //console.log("calcData", calcData);//.map(item => item.slice(3)));

            for (let i = 0; i < this.layout.candles.length; i++) {
                const candle = this.layout.candles[i];
                const x = candle.x -3
                const y_lower = candle.l * 1.15
                const y_higher = candle.h * 0.85

                if (calcData[i] == null) continue;

                const signals = this.calcIndicatorSignals(calcData[i]);
                if (signals.bearishCount >= this.settings.min_shown_sig_count) drawingTools.simpleText(ctx, x, y_higher, signals.bearishCount, 'red')

                if (signals.bullishCount >= this.settings.min_shown_sig_count) drawingTools.simpleText(ctx, x, y_lower, signals.bullishCount, 'green')
            }
        },
        legend(values) {
            const signals = this.calcIndicatorSignals(values);



            const bullishTrueKeys = Object.keys(signals.bullish)
                .filter(key => key == 'emaRibbons' ? Object.keys(signals.bullish[key])>0 :signals.bullish[key])
                .map(key => this.mapSignalNames(key, signals.bullish));
            const bearishTrueKeys = Object.keys(signals.bearish).filter(key => key == 'emaRibbons' ? Object.keys(signals.bearish[key])>0 :signals.bearish[key]).map(key => this.mapSignalNames(key,  signals.bearish));


            //console.log (values[3], values[4]);

            return [
                {
                    value: bullishTrueKeys,
                    color: 'green'
                },
                {
                    value: bearishTrueKeys,
                    color: 'red'
                }
            ]
        },
        use_for() { return ['MultiSignalOverlay'] },
        calc() {
            return {
                props: {
                    min_shown_sig_count: { def: 2, text: 'Minimum signal count shown' },
                    param_k: { def: 14, text: 'StochRSI K' },
                    param_d: { def: 3, text: 'StochRSI D' },
                    smooth: { def: 3, text: 'StochRSI Smooth' },
                    mfi_length: { def: 14, text: 'MFI Length' },
                    rsi_length: { def: 14, text: 'RSI Length' },
                    sar_start: { def: 0.02, text: 'SAR | Start' },
                    sar_inc: { def: 0.02, text: 'SAR | Increment' },
                    sar_max: { def: 0.2, text: 'SAR | Maximum' },
                    macd_fast: { def: 12, text: 'MACD | Fast Length' },
                    macd_slow: { def: 26, text: 'MACD | Slow Length' },
                    macd_smooth: { def: 9, text: 'MACD | Signal EMA' },
                    ema_ribbon_start: { def: 10, text: 'EMA ribbon | Start Length' },
                    ema_ribbon_number: { def: 5, text: 'EMA ribbon | Number of Lines' },
                    ema_ribbon_step: { def: 10, text: 'EMA ribbon | Length Step' },
                    
                    wpr_length: { def: 14, text: 'WPR Length' }
                },
                update: `                    
                    let k = sma(stoch(close, high, low, param_k), smooth)
                    let d = sma(k, param_d)
                    let hlc3 = ts((high[0] + low[0] + close[0]) / 3)

                    // MFI
                    let mfiValues = mfi(hlc3, mfi_length)

                    // RSI
                    let rsiValues = rsi(close, rsi_length)

                    // SAR
                    let sar = sar(sar_start, sar_inc, sar_max)
                    let currSarIsAbove = sar[0] > close[0]
                    let lastSarIsAbove = sar[1] > close[1]
                    let sarSignalUp = !currSarIsAbove && lastSarIsAbove
                    let sarSignalDown = currSarIsAbove && !lastSarIsAbove

                    // MACD
                    let [macd, signal, hist] = macd(close, macd_fast, macd_slow, macd_smooth)
                    let macdSignalUp = crossover(macd, signal)[0]
                    let macdSignalDown = crossunder(macd, signal)[0]



                    // EMA ribbon
                    var ribbons = []
                    var ribbonsLabels = []
                    for (var i = 0; i < ema_ribbon_number; i++) {
                        let l = ema_ribbon_start + i * ema_ribbon_step
                        ribbons.push(ema(close, l))
                        ribbonsLabels.push(l)
                    }

                    var crossupEmaRibbons;
                    var crossdownEmaRibbons;

                    if (!crossupEmaRibbons) {
                        crossupEmaRibbons = {}
                    }

                    if (!crossdownEmaRibbons) {
                        crossdownEmaRibbons = {}
                    }


                    for (var rb = 0; rb < ribbons.length; rb++) {
                        for (var i = 0; i < ribbons.length; i++) {
                            
                            let iLabel = ribbonsLabels[i]
                            let rbLabel = ribbonsLabels[rb]
                            if (i != rb) {
                                if (crossunder(ribbons[rb], ribbons[i])[0]) {
                                    if (!crossupEmaRibbons[rbLabel]) {
                                        crossupEmaRibbons[rbLabel] = []
                                    }
                                    crossupEmaRibbons[rbLabel].push(iLabel)
                                    
                                } 
                                if (crossover(ribbons[rb], ribbons[i])[0]) {
                                    if (!crossdownEmaRibbons[rbLabel]) {
                                        crossdownEmaRibbons[rbLabel] = []
                                    } 
                                    crossdownEmaRibbons[rbLabel].push(iLabel)
                                    
                                }
                            }
                        }
                    }

                    // Williams R%
                    let wr = wpr(wpr_length)

                    // WPR
                    let wprCrossUp = wr[1]<-80 && wr[0] > -80
                    let wprCrossDown = wr[1]> -20 && wr[0] < -20

                    return  [mfiValues[0],rsiValues[0],wprCrossUp, wprCrossDown, crossover(k,d)[0] && k[0] < 25, crossunder(k,d)[0] && k[0] > 75, sarSignalUp, sarSignalDown, macdSignalUp, macdSignalDown, crossupEmaRibbons, crossdownEmaRibbons]
                `// [
                // cross sigs: Stoch RSI, MACD, IchimokuClöoud, Moving Averages
            }
        }
    },
    computed: {
        sett() {
            return this.$props.settings
        }
    }
}
</script>
