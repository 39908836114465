<template>
  <v-container>
    <v-card>
      <v-card-title>
        <h2>API Accounts</h2>
      </v-card-title>
      <v-card-text>
        <v-form v-on:submit.prevent="submitForm">
          <v-text-field
            label="Name"
            v-model="account.name"
            required
          ></v-text-field>
          <v-select
            label="Exchange"
            v-model="account.exchange_key"
            :items="exchanges"
            required
          ></v-select>
          <v-text-field
            label="API Key"
            v-model="account.api_key"
            required
          ></v-text-field>
          <v-text-field
            label="API Secret"
            v-model="account.api_secret"
            required
          ></v-text-field>
          <v-textarea label="Notes" v-model="account.notes"></v-textarea>
          <v-card-actions>
            <v-btn v-if="!editing" type="submit" color="primary">Create</v-btn>
            <v-btn v-else type="submit" color="primary">Update</v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-text>
        <v-list>
          <v-list-item v-for="account in accounts" :key="account.api_key">
            <v-list-item-content @click="editApiAccount(account)">
              <v-list-item-title>
                <v-row>
                  <v-col cols="4">
                    {{ account.name }}
                  </v-col>
                  
                  <v-col cols="4">
                    {{ account.exchange_key }}
                  </v-col>
                  
                  <v-col cols="4">({{
                  account.api_key
                }})
                  </v-col>
                </v-row>
              </v-list-item-title>
              <v-list-item-subtitle>{{ account.notes }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-row>
                <v-col
                  ><!-- Add status icon  -->
                  <v-tooltip v-if="account.status === 'connected'" bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="green">mdi-check-circle</v-icon>
                    </template>
                    <span>Connected using API-Key invalid</span>
                  </v-tooltip>
                  <v-tooltip v-else-if="account.status === 'disconnected'" bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="red">mdi-alert-circle</v-icon>
                    </template>
                    <span>Disconnected - API-Key invalid or connection broken.</span>
                  </v-tooltip>
                </v-col>
                <v-col>
                  <v-btn
                    v-on:click.prevent="deleteApiAccount(account)"
                    color="error"
                    ><v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      account: {
        exchange_key: "",
        api_key: "",
        api_secret: "",
        notes: "",
        name: "",
      },
      editing: false,
    };
  },
  created() {
    //this.fetchApiAccounts();
    //this.fetchExchanges();
  },
  computed: {
    ...mapGetters({
      accounts: "getApiAccounts",
      exchanges: "getExchanges",
    }),
  },
  methods: {
    ...mapActions({
      fetchApiAccounts: "fetchApiAccounts",
      createApiAccount: "createApiAccount",
      updateApiAccount: "updateApiAccount",
      deleteApiAccount: "deleteApiAccount",
      fetchExchanges: "fetchExchanges",
    }),
    submitForm() {
      if (this.editing) {
        this.updateApiAccount(this.account);
      } else {
        this.createApiAccount(this.account);
      }
      this.account = {
        name: "",
        exchange_key: "",
        api_key: "",
        api_secret: "",
        notes: "",
      };
      this.editing = false;
    },
    editApiAccount(account) {
      this.account = { ...account };
      this.editing = true;
    },
  },
};
</script>
