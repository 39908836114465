var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',[_vm._v("Position Selection")]),_c('p',[_vm._v("Select the account, market and symbol to use in ChartView as default")]),_c('v-select',{attrs:{"label":"Position Account","value":_vm.selectedAccount,"items":_vm.getApiAccountsOptions},on:{"change":_vm.updateSelectedAccount}}),_c('v-select',{attrs:{"label":"Position Market","value":_vm.selectedMarket,"items":_vm.getExchangeMarketOptions(_vm.currentExchangeKey),"disabled":_vm.selectedAccount === '' ||
      _vm.selectedAccount === null ||
      _vm.selectedAccount == undefined},on:{"change":_vm.updateSelectedMarket}}),_c('v-select',{attrs:{"label":"Position Symbol","value":_vm.selectedSymbol,"items":_vm.getExchangeMarketSymbolOptions(_vm.currentExchangeKey, _vm.selectedMarket),"disabled":_vm.selectedAccount === '' ||
      _vm.selectedAccount === null ||
      _vm.selectedAccount == undefined ||
      _vm.selectedMarket === '' ||
      _vm.selectedMarket === null ||
      _vm.selectedMarket == undefined},on:{"change":_vm.updateSelectedSymbol}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }