<script>
// Line drawing tool
// TODO: make an angle-snap when "Shift" is pressed

import Overlay from "@plutos/plutos-tradingvuejs/src/mixins/overlay.js";
import Tool from "@plutos/plutos-tradingvuejs/src/mixins/tool.js";
import Pin from "@plutos/plutos-tradingvuejs/src/components/primitives/pin.js";
import Seg from "@plutos/plutos-tradingvuejs/src/components/primitives/seg.js";
import Line from "@plutos/plutos-tradingvuejs/src/components/primitives/line.js";
import Ray from "@plutos/plutos-tradingvuejs/src/components/primitives/ray.js";

import drawTools from "./common/drawingTools.js";

import MyIcons from "./stuff/icons.json";
import { mapGetters, mapActions } from "vuex";
/**
 * TODO:
 * * Delete trigger line when creation of any of the dependencies fails
 * * Remove other dependencies when creation of trigger line fails
 * * Add different modes (ray, extended)
 * * Add different modes (long, short, both)
 */
export default {
  name: "TriggerLineTool",
  mixins: [Overlay, Tool],
  methods: {
    ...mapActions([
      "createTriggerLineDependencies",
      "deleteTriggerLineDependencies",
    ]),
    meta_info() {
      return { author: "Adam", version: "1.1.0" };
    },
    // Defines legend format (values & colors)
    /*legend() {

            return [
                {
                    value: "test"
                }
            ]
        },*/
    tool() {
      return {
        // Descriptor for the tool
        group: "TriggerLines",
        icon: MyIcons["trigger-line-tool.png"],
        type: "Long&Short",
        hint: "Will create a trigger line with a action and trigger on each side",
        data: [], // Default data
        settings: {
          long: true,
          short: true,
        }, // Default settings
        // Modifications
        mods: {

          'Long': {
            // Rewrites the default setting fields
            settings: { long: true, short: false },
            icon: MyIcons["trigger-line-tool-up.png"],
          },
          'Short': {
            // Rewrites the default setting fields
            settings: { short: true, long: false },
            icon: MyIcons["trigger-line-tool-down.png"],
          },
          /*
          'Extended': {
              // Rewrites the default setting fields
              settings: { extended: true },
              icon: Icons['extended.png']
          },
          'Ray': {
              // Rewrites the default setting fields
              settings: { ray: true },
              icon: Icons['ray.png']
          }*/
        },
      };
    },
    // Called after overlay mounted
    init() {
      //window.tv.$on("user-removed-overlay", this.listenOnDeleteGridLayer);
      //window.userRemovedOverlayLiseners.push(this.listenOnDeleteGridLayer);
      addEventListener("user-removed-overlay", this.listenOnDeleteGridLayer);

      // First pin is settled at the mouse position
      this.pins.push(new Pin(this, "p1"));
      // Second one is following mouse until it clicks
      this.pins.push(
        new Pin(this, "p2", {
          state: "tracking",
        })
      );
      this.pins[1].on("settled", async () => {
        let isCreationRun = false;
        // set chartDataSource
        this.settings.chartDataSource = {
          exchange: this.chartView.exchange,
          market: this.chartView.market,
          symbol: this.chartView.symbol,
          timeframe: this.chartView.timeframe,
        }

        if (!this.settings.dependenciesCreated) {
          const { longTriggerID, shortTriggerID, longActionIDs, shortActionIDs } =
            await this.createTriggerLineDependencies({
              drawingID: this.drawingID,
              long: this.settings.long, short: this.settings.short,
              chartDataSource: {
                exchange: this.chartView.exchange,
                market: this.chartView.market,
                symbol: this.chartView.symbol,
                timeframe: this.chartView.timeframe,
              }
            });
          isCreationRun = true;
          this.settings.longActionIDs = longActionIDs;
          this.settings.shortActionIDs = shortActionIDs;
          this.settings.longTriggerID = longTriggerID;
          this.settings.shortTriggerID = shortTriggerID;
          this.settings.dependenciesCreated = true;
        }

        // display modal
        if (this.$store.state.triggerLineOptions.showModalOnCreate || !isCreationRun) this.triggerLineModal.loadTriggerLineModal(this);

        console.log("TriggerLineTool: pins[1] settled");


        //console.log("🚀 ~ file: TriggerLine.vue:71 ~ this.pins[1].on ~ data:",data);
        // Call when current tool drawing is finished
        // (Optionally) reset the mode back to 'Cursor'

        this.set_state("finished");
        this.$emit("drawing-mode-off");
      });
    },
    listenOnDeleteGridLayer(event) {
      const drawingID = event.detail;
      //compare drawingID
      if (this.settings.$uuid == drawingID) {
        const currentDrawingData = this.tradingVue.data.get(this.drawingID);
        if (
          currentDrawingData &&
          currentDrawingData.length == 0 &&
          this.isDrawingCorrectForDataSource
        ) {
          // delete triggers and actions

          this.deleteTriggerLineDependencies({
            longActionIDs: this.settings.longActionIDs,
            shortActionIDs: this.settings.shortActionIDs,
            longTriggerID: this.settings.longTriggerID,
            shortTriggerID: this.settings.shortTriggerID,
          }).catch((e) => {
            console.error("TriggerLineTool: listenOnDeleteGridLayer", e);
          });
        }
      }
      // ensure that the trigger line is deleted since sometimes this event is triggered without delete
    },


    draw(ctx) {
      if (!this.p1 || !this.p2) return;
      //console.log("TriggerLineTool: draw", this.p1, this.p2);
      // draw arrow on pin 2
      //this.drawArrow(ctx, this.pins[0].x, this.pins[0].y, 'right', 2, 'red');
      if (this.isShortActive) {
        const x = this.layout.t2screen(this.p2[0]);
        const y = this.layout.$2screen(this.p2[1]);
        if (this.shortTriggerListening) {
          drawTools.simpleArrowDown(ctx, x, y, "red");
          // draw timeframe next to arrow
          if (this.shortTrigger && this.shortTrigger.parameters && this.shortTrigger.parameters.candleSize) drawTools.simpleText(ctx, x + 10, y - 5, this.shortTrigger.parameters.candleSize, "red");
        } else {
          drawTools.simpleArrowDown(ctx, x, y, "#ffa500");
          // draw timeframe next to arrow
          if (this.shortTrigger && this.shortTrigger.parameters && this.shortTrigger.parameters.candleSize) drawTools.simpleText(ctx, x + 10, y - 5, this.shortTrigger.parameters.candleSize, "#ffa500");
        }
        // print size
        const x2 = this.layout.t2screen(this.p2[0]);
        const y2 = this.layout.$2screen(this.p2[1]) - 20;
        ctx.font = "10px Arial";
        ctx.fillStyle = "red";
        ctx.fillText(
          this.shortSize,
          x2,
          y2
        );
      }
      // draw arrow on pin 1
      if (this.isLongActive) {
        const x1 = this.layout.t2screen(this.p2[0]) + 20;
        const y1 = this.layout.$2screen(this.p2[1]);

        if (this.longTriggerListening) {
          drawTools.simpleArrowUp(ctx, x1, y1, "green");
          // draw timeframe next to arrow
          if (this.longTrigger && this.longTrigger.parameters && this.longTrigger.parameters.candleSize) drawTools.simpleText(ctx, x1 + 10, y1 + 5, this.longTrigger.parameters.candleSize, "green");
        } else {
          // orange
          drawTools.simpleArrowUp(ctx, x1, y1, "#ffa500");
          // draw timeframe next to arrow
          if (this.longTrigger && this.longTrigger.parameters && this.longTrigger.parameters.candleSize) drawTools.simpleText(ctx, x1 + 10, y1 + 5, this.longTrigger.parameters.candleSize, "#ffa500");
        }
        // print size
        const x2 = this.layout.t2screen(this.p2[0]) + 20;
        const y2 = this.layout.$2screen(this.p2[1]) + 20;
        ctx.font = "10px Arial";
        ctx.fillStyle = "green";
        ctx.fillText(
          this.longSize,
          x2,
          y2
        );
      }
      ctx.lineWidth = this.line_width;
      ctx.strokeStyle = this.color;
      ctx.beginPath();

      if (this.sett.ray) {
        new Ray(this, ctx).draw(this.p1, this.p2);
      } else if (this.sett.extended) {
        new Line(this, ctx).draw(this.p1, this.p2);
      } else {
        new Seg(this, ctx).draw(this.p1, this.p2);
      }

      ctx.stroke();
      this.render_pins(ctx);
    },
    use_for() {
      return ["TriggerLineTool"];
    },
    data_colors() {
      return [this.color];
    },
    lastCheckedMinutesAgo(timestamp) {
      if (timestamp) {
        var cDate = new Date(timestamp);
        var diff = new Date() - cDate;
        var minutes = Math.ceil(diff / 60000);
        return minutes;
      }
      return "";
    },
  },
  // Define internal setting & constants here
  computed: {
    ...mapGetters(["getTriggerById", "getActionById"]),
    tradingVue() {
      return this.$parent.$parent.$parent.$parent; // TradingVue
    },
    chartView() {
      // I hope this won't change & break in the future
      // this.$parent = Grid
      // this.$parent.$parent = GridSections
      // this.$parent.$parent.$parent = Canvas
      // this.$parent.$parent.$parent.$parent; // TradingVue
      return this.tradingVue.$parent; // ChartView
    },
    triggerLineModal() {
      return this.chartView.$refs.triggerLineModal; // TriggerLineModal
    },
    isDrawingCorrectForDataSource() {
      const currChartSrc = {
        exchange: this.chartView.exchange,
        market: this.chartView.market,
        symbol: this.chartView.symbol
      };
      const currDrawingSrc = this.settings.chartDataSource;
      const isDrawingCorrectForDataSource = (
        currChartSrc &&
        currDrawingSrc &&
        currChartSrc.exchange === currDrawingSrc.exchange &&
        currChartSrc.symbol === currDrawingSrc.symbol &&
        currChartSrc.market === currDrawingSrc.market
      );
      console.log(
        "TriggerLineTool: isDrawingCorrectForDataSource",
        currChartSrc,
        currDrawingSrc,
        isDrawingCorrectForDataSource
      );
      return isDrawingCorrectForDataSource;
    },
    sett() {
      return this.$props.settings;
    },
    p1() {
      return this.$props.settings.p1;
    },
    p2() {
      return this.$props.settings.p2;
    },
    line_width() {
      // Fat line width for active trigger lines
      if (this.isLongActive || this.isShortActive) return 2.5;
      return this.sett.lineWidth || 0.9;
    },
    color() {
      // Green for long, red for short
      // Orange for both
      if (this.isLongActive && this.isShortActive) return "#ffa500";
      if (this.isLongActive) return "green";
      if (this.isShortActive) return "red";
      return this.sett.color || "#AA22AA";
    },
    drawingID() {
      return this.settings.$uuid
    },
    longSize() {
      // TODO do not use different names for the same thing -> quantity vs size
      try {
        if (this.longAction && this.longAction.parameters && this.longAction.parameters.usePercentQuantity)
          return `${this.longAction.parameters.quantityPercent} %`;
        if (this.longAction && this.longAction.parameters && this.longAction.parameters.quantity)
          return this.longAction.parameters.quantity;
      } catch (e) {
        console.log("TriggerLineTool: longSize", e);
      }
      return 0;
    },
    shortSize() {
      try {
        if (this.shortAction && this.shortAction.parameters && this.shortAction.parameters.usePercentQuantity)
          return `${this.shortAction.parameters.quantityPercent} %`;
        if (this.shortAction && this.shortAction.parameters && this.shortAction.parameters.quantity)
          return this.shortAction.parameters.quantity;
      } catch (e) {
        console.log("TriggerLineTool: shortSize", e);
      }
      return 0;
    },
    longTriggerListening() {
      if (this.longTrigger && this.longTrigger.last_checked_at) {
        return this.lastCheckedMinutesAgo(this.longTrigger.last_checked_at) < 5;
      } else {
        return false;
      }
    },
    shortTriggerListening() {
      if (this.shortTrigger && this.shortTrigger.last_checked_at) {
        return this.lastCheckedMinutesAgo(this.shortTrigger.last_checked_at) < 5;
      } else {
        return false;
      }
    },
    longTrigger() {
      if (this.settings.longTriggerID) {
        return this.getTriggerById(this.settings.longTriggerID);
      } else {
        return null;
      }
    },
    shortTrigger() {
      if (this.settings.shortTriggerID) {
        return this.getTriggerById(this.settings.shortTriggerID);
      } else {
        return null;
      }
    },
    longAction() {
      if (this.settings.longActionIDs && this.settings.longActionIDs.length > 0) {
        // TODO handle multiple actions
        return this.getActionById(this.settings.longActionIDs[0]);
      } else {
        return null;
      }
    },
    shortAction() {
      if (this.settings.shortActionIDs && this.settings.shortActionIDs.length > 0) {
        // TODO handle multiple actions
        return this.getActionById(this.settings.shortActionIDs[0]);
      } else {
        return null;
      }
    },
    isLongActive() {

      if (!this.longAction || !this.longTrigger) return false;
      return this.longAction.enabled && this.longTrigger.enabled;
    },
    isShortActive() {
      if (!this.shortAction || !this.shortTrigger) return false;
      return this.shortAction.enabled && this.shortTrigger.enabled;
    },
  },
  data() {
    return {};
  },
};
</script>