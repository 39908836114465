import axios from "axios";

const state = {
  token: localStorage.getItem("token") || "",
  status: "",
  user: JSON.parse(localStorage.getItem("user")) || {},
};

const getters = {
    isLoggedIn: (state) => !!state.token,
    authStatus: (state) => state.status,
    currentUser: (state) => state.user,
  };
  const mutations = {
    auth_request: (state) => {
      state.status = "loading";
    },
    auth_success: (state, {token, user}) => {
      state.token = token;
      state.user = user;
      state.status = "success";
    },
    auth_error: (state) => {
      state.status = "error";
    },
    logout: (state) => {
      state.token = "";
      state.user = {};
    },
  };
  const actions = {
    login: ({ commit, rootState }, user) => {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        axios
          .post(rootState.backendUrl + "/api/login", user)
          .then((resp) => {
            console.log("logged in", resp);
            const token = resp.data.token;
            const user = {username: resp.data.username, email: resp.data.email, first_name: resp.data.first_name, last_name: resp.data.last_name, id: resp.data.id};
            localStorage.setItem("token", token);
            localStorage.setItem("user", JSON.stringify(user));
            axios.defaults.headers.common["Authorization"] = token;
            commit("auth_success", {token, user});
            resolve(resp);
          })
          .catch((err) => {
            commit("auth_error");
            localStorage.removeItem("token");
            reject(err);
          });
      });
    },
    logout: ({ commit }) => {
      return new Promise((resolve) => {
        commit("logout");
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        delete axios.defaults.headers.common["Authorization"];
        resolve();
      });
    },
  };
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };
  