<script>

import { Overlay } from 'trading-vue-js'

export default {
    name: 'MFIStoch',
    mixins: [Overlay],
    methods: {
        meta_info() {
            return {
                author: 'StdSquad', version: '1.0.1',
                desc: 'Stochastic with MFI and RSI',
                preset: {
                    name: 'Stoch-MFI-RSI $param_k $param_d $smooth $rsiLength',
                    side: 'offchart',
                    settings: {
                        lineWidth: 0.75,
                        rsiLineWidth: 2,
                        mfiLineWidth: 2,
                        kColor: '#3782f2',
                        dColor: '#ff382e',
                        mfiColor: '#fce703', // Yellow mfi
                        rsiColor: '#00ff00', // Green rsi
                        upperBandColor: '#f00',
                        lowerBandColor: '#0f0',
                        backColor: '#381e9c16'
                    }
                }
            }
        },
        draw(ctx) {
            const layout = this.$props.layout
            const upper = layout.$2screen(this.sett.upper || 80)
            const lower = layout.$2screen(this.sett.lower || 20)

            // K
            ctx.lineWidth = this.line_width
            ctx.strokeStyle = this.k_color
            ctx.beginPath()

            for (var p of this.$props.data) {
                let x = layout.t2screen(p[0])
                let y = layout.$2screen(p[1])
                ctx.lineTo(x, y)
            }

            ctx.stroke()

            // D
            ctx.lineWidth = this.line_width
            ctx.strokeStyle = this.d_color
            ctx.beginPath()

            for (var p2 of this.$props.data) {
                let x = layout.t2screen(p2[0])
                let y = layout.$2screen(p2[2])
                ctx.lineTo(x, y)
            }

            ctx.stroke()

            // MFI
            ctx.lineWidth = this.mfi_line_width
            ctx.strokeStyle = this.mfi_color // Use MFI color
            ctx.beginPath()

            for (var p3 of this.$props.data) {
                let x = layout.t2screen(p3[0])
                let y = layout.$2screen(p3[3]) // Assuming MFI values are in p[3]
                ctx.lineTo(x, y)
            }

            ctx.stroke()

            // RSI
            ctx.lineWidth = this.rsi_line_width
            ctx.strokeStyle = this.rsi_color // Use RSI color
            ctx.beginPath()

            for (var p4 of this.$props.data) {
                let x = layout.t2screen(p4[0])
                let y = layout.$2screen(p4[4]) // Assuming RSI values are in p[4]
                ctx.lineTo(x, y)
            }

            ctx.stroke()

            ctx.strokeStyle = this.upper_band_color
            ctx.setLineDash([5]) // Will be removed after draw()
            ctx.beginPath()

            // Fill the area between the bands
            //ctx.fillStyle = this.back_color
            //ctx.fillRect(0, upper, layout.width, lower - upper)

            // Upper band
            ctx.moveTo(0, upper)
            ctx.lineTo(layout.width, upper)
            ctx.stroke()
            ctx.strokeStyle = this.lower_band_color
            ctx.setLineDash([5]) // Will be removed after draw()
            ctx.beginPath()
            // Lower band
            ctx.moveTo(0, lower)
            ctx.lineTo(layout.width, lower)

            ctx.stroke()
        },
        use_for() { return ['MFIStoch'] }, // Update the name to include MFI
        data_colors() { return [this.color, this.mfi_color, this.rsi_color] }, // Include MFI and RSI colors
        y_range(hi, lo) {
            return [
                Math.max(hi, this.sett.upper || 80),
                Math.min(lo, this.sett.lower || 20)
            ]
        },
        calc() {
            return {
                props: {
                    param_k: { def: 14, text: 'StochRSI-K' },
                    param_d: { def: 3, text: 'StochRSI-D' },
                    smooth: { def: 3, text: 'StochRSI-Smooth' },
                    mfi_length: { def: 14, text: 'mfiLength' },
                    rsi_length: { def: 14, text: 'rsiLength' }
                },
                update: `
                    let k = sma(stoch(close, high, low, param_k), smooth)
                    let d = sma(k, param_d)
                    let hlc3 = ts((high[0] + low[0] + close[0]) / 3)
                    let mfiValues = mfi(hlc3, mfi_length)
                    let rsiValues = rsi(close, rsi_length)
                    return [k[0], d[0], mfiValues[0], rsiValues[0]]
                `
            }
        }
    },
    computed: {
        sett() {
            return this.$props.settings
        },
        line_width() {
            return this.sett.lineWidth || 0.75
        },
        rsi_line_width() {
            return this.sett.rsiLineWidth || 0.75
        },
        mfi_line_width() {
            return this.sett.mfiLineWidth || 0.75
        },
        k_color() {
            return this.sett.kColor || '#3782f2'
        },
        d_color() {
            return this.sett.dColor || '#f48709'
        },
        mfi_color() {
            return this.sett.mfiColor || '#00ff00'
        },
        rsi_color() {
            return this.sett.rsiColor || '#fcba03'
        },
        upper_band_color() {
            return this.sett.upperBandColor || '#f00'
        },
        lower_band_color() {
            return this.sett.lowerBandColor || '#0f0'
        },
        back_color() {
            return this.sett.backColor || '#381e9c16'
        }
    }
}
</script>
