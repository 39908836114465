<template>
    <div>
      <h1>Login</h1>
      <form @submit.prevent="login">
        <label>
          Username:
          <input type="text" v-model="username" required>
        </label>
        <label>
          Password:
          <input type="password" v-model="password" required>
        </label>
        <button type="submit">Login</button>
      </form>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        username: '',
        password: ''
      };
    },
    // handle logout route
    beforeRouteEnter(to, from, next) {
      if (to.path === '/logout') {
        next((vm) => {
          vm.$store.dispatch('auth/logout')
            .then(() => {
              // Navigate to login page on successful logout
              vm.$router.push('/');
            })
            .catch((error) => {
              // Show error message to user
              alert('Failed to logout: ' + error.message);
            });
        });
      } else {
        next();
      }
    },
    methods: {
      login() {
        this.$store.dispatch('auth/login', { username: this.username, password: this.password })
          .then(() => {
            // Navigate to dashboard on successful login
            this.$router.push('/dashboard');
          })
          .catch((error) => {
            // Show error message to user
            alert('Failed to login: ' + error.message);
          });
      }
    }
  }
  </script>
  