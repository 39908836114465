<template>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <div
        v-for="field in generateForm()"
        :key="field.key"
      >
        <v-select
          v-if="field.type === 'Select'"
          v-model="field.value"
          :items="field.availableSelections"
          :rules="[v => !!v || 'Field is required']"
          required
          :label="field.description"
          @change="valueChanged"
          @input="updateValue(field.key, $event)"
        />
        <v-select
          v-else-if="field.type === 'DrawingSelection'"
          v-model="field.value"
          :items="field.availableSelections"
          :rules="[v => !!v || 'Field is required']"
          required
          :label="field.description"
          @input="updateValue(field.key, $event)"
        />
  
        <v-text-field
          v-else-if="field.type === 'Number' || field.type === 'String'"
          v-model="field.value"
          :rules="[v => !!v || 'Field is required']"
          required
          :label="field.description"
          @input="updateValue(field.key, $event)"
        />
        <v-checkbox
          v-else-if="field.type === 'Boolean'"
          v-model="field.value"
          :label="field.description"
          @change="updateValue(field.key, $event)"
        />
        <!--
        <v-btn
          v-if="field.type === 'DrawingSelection'"
          @click="localizeDrawing(field.key)"
        >
          Show selected drawing
        </v-btn>-->
      </div>
    </v-form>
  </template>
  
  <script>
  // Create a vue component to generate a form from a JSON schema.
  // props: schema, value
  
  
  // schema will have format:
  /* schema = {
      side: {
        type: 'Select',
        availableSelections: [
          { value: 'buy', label: 'Buy' },
          { value: 'sell', label: 'Sell' },
        ],
        description: 'Side of the order',
        default: 'buy',
        validator: function (val) {
          if (val > 0 && val < 24) return true;
          return false;
        },
      },
      side: {
        type: 'Select',
        description: 'Type of order',
        availableSelections: [
          { value: 'market', label: 'Limit' },
          { value: 'limit', label: 'Market' },
        ],
        default: 'market',
        validator: function (val) {
          if (val > 0 && val < 24) return true;
          return false;
        },
      },
    };
  */
  
  // value will have format:
  /* value = {
      side: 'buy',
      type: 'market',
    };
  */
  
  export default {
    name: 'SchemaForm',
    props: {'schema': {
      type: Object,
      required: true,
    },
    'value': {
      type: Object,
      required: true,
    }},
  
    data() {
      return {
        valid: false,
        updatedVals: {},
      };
    },
    watch: {
      value() {
        this.updatedVals = this.value;
      },
      schema() {
        this.loadDefaultValues();
      },
    },
    computed: {
      schemaKeys() {
        return Object.keys(this.schema);
      },
    },
    methods: {
      valueChanged() {
        console.log('SchemaForm.vue ~ valueChanged ~ this.updatedVals', this.updatedVals);
        this.$emit('changed', this.updatedVals);
      },
      loadDefaultValues() {
        this.schemaKeys.forEach((key) => {
          if (this.value[key] === undefined)
            this.updatedVals[key] = this.schema[key].default;
        });
        this.$emit('input', this.updatedVals);
      },
      updateValue(key, value) {
        console.log('🚀 ~ file: SchemaForm.vue ~ line 90 ~ updateValue ~ key', key, value);
  
        this.updatedVals = this.value;
        this.updatedVals[key] = value;
        this.$emit('input', this.updatedVals);

        if(this.schema[key].refetchSchemaOnUpdate) this.$emit('refetchSchema');
      },
      localizeDrawing(key) {
        const drawingTimeStart = this.value[key].drawingData.settings.p1[0];
        const drawingTimeEnd = this.value[key].drawingData.settings.p2[0];
        const timeLength = drawingTimeEnd - drawingTimeStart;
        window.tv && window.tv.setRange(drawingTimeStart-timeLength, drawingTimeEnd+timeLength);
  
        // unselect all drawings
        const allOnchart = window.dc && window.dc.get('onchart');
        const allOnchartDeselected = allOnchart.map((drawing) => {
          if (drawing.settings.$selected) {
            drawing.settings.$selected = false;
          }
          return drawing;
        });
        console.log('🚀 ~ file: SchemaForm.vue ~ line 140 ~ allOnchartDeselected ~ allOnchartDeselected', allOnchartDeselected);
        // window.dc && window.dc.set('onchart', allOnchartDeselected);
        // refresh chart to deselect everything
        // TODO: find a better way to do this
        window.tv && window.tv.resetChart(false);
        // select the drawing
        const drawingId = this.value[key].drawingData.settings.$uuid;
        const drawingDC = window.dc && window.dc.get(drawingId)[0];
        drawingDC.settings.$selected = true;
        window.dc && window.dc.set(drawingId, drawingDC);
        // refresh chart
        window.tv && window.tv.resetChart(false);
        console.log('🚀 ~ file: SchemaForm.vue ~ line 134 ~ localizeDrawing ~ drawingId', drawingId);
      },
      generateForm() {
        const form = [];
  
        this.schemaKeys.forEach((key) => {
          const field = this.schema[key];
          let fieldData;
          if (this.value) fieldData= this.value[key];
  
          if (fieldData === undefined) {
            fieldData = field.default;
          }
  
  
          const formField = {
            key: key,
            type: field.type,
            description: field.description,
            default: field.default,
            validator: field.validator,
            value: fieldData,
          };
  
          if (field.type === 'Select' || field.type === 'DrawingSelection') {
            formField.availableSelections = field.availableSelections;
          }
  
          form.push(formField);
        });
  
        return form;
      },
      generateSchema() {
        const schema = {};
  
        this.schemaKeys.forEach((key) => {
          // const field = this.schema[key];
          const fieldData = this.value[key];
  
          schema[key] = fieldData;
        });
  
        return schema;
      },
      generateData() {
        const value = {};
  
        this.schemaKeys.forEach((key) => {
          // const field = this.schema[key];
          const fieldData = this.value[key];
  
          value[key] = fieldData;
        });
  
        return value;
      },
      validate() {
        this.valid = true;
        this.schemaKeys.forEach((key) => {
          const field = this.schema[key];
          const fieldData = this.value[key];
  
          if (field.validator) {
            if (!field.validator(fieldData)) {
              this.valid = false;
            }
          }
        });
      },
    },
  };
  </script>
  
  
  <style>
  
  </style>
  