<template>
  <div>
    <v-select
      v-model="apiAccountId"
      :items="getApiAccountsOptions"
      label="Account"
    >
    </v-select>

    <v-tabs v-model="tab" bg-color="primary">
      <v-tab
        v-for="subPortfolio of Object.keys(positions)"
        :key="subPortfolio"
        @click="updateViewedTab(subPortfolio)"
        >{{ subPortfolio }}</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="positionsType of Object.keys(positions)"
        :key="positionsType"
      >
        <v-card>
          <v-card-title>{{ positionsType }}</v-card-title>
          <v-card-text style="width: 200px"> </v-card-text>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="positions[positionsType]"
              :items-per-page="5"
              class="elevation-1"
            ><template v-slot:[`item.side`]="{ item }">
              <v-chip :color="item.side === 'Buy' ? 'green' : 'red'">
                {{ item.side }}
              </v-chip>
            </template>
            <template v-slot:[`item.symbol`]="{ item }">
              <a @click="showSymbolInChart(item)">
              <v-chip color="blue">
                {{ item.symbol }}
              </v-chip></a>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <!-- Update trade stop -->
              <v-btn small
                color="primary"
                @click="showTradeStopModal(item)"
                >
                Trade Stop</v-btn>
            </template>

            
          </v-data-table>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <TradeStopView ref="TradeStopView" :position="tradeStopPosition" @onSave="reloadPositions" />
         
     
  </div>
</template>
  
  <script>
import { mapGetters, mapActions, mapState } from "vuex";



import TradeStopView from "./TradeStopView.vue";

export default {
  data() {
    return {
      apiAccountId: this.apiAccounts && this.apiAccounts[0] && this.apiAccounts[0].id || 1,
      tab: null,
      tradeStopPosition: null,
    };
  },
  components: {
    TradeStopView,
  },
  watch: {
    async apiAccountId(newVal) {
      await this.$store.dispatch("fetchAccountsPositions", newVal);
      this.$forceUpdate();
      console.log("New apiAccountId", this.positions);
      
    },
  },
  methods: {
    ...mapActions(["fetchApiAccounts"]),
    async reloadPositions() {
      await this.fetchApiAccounts();
    },
    showTradeStopModal(position) {
      
      this.tradeStopPosition = position;
      // get categiry string from tab id
      this.tradeStopPosition.market = Object.keys(this.positions)[this.tab];
      this.tradeStopPosition.apiAccountId = this.apiAccountId;
      
      console.log('showTradeStopModal', this.tradeStopPosition);
      this.$refs.TradeStopView.show(this.tradeStopPosition);
    },
    async showSymbolInChart(position) {
      const apiAccount = this.apiAccounts.find((apiAccount) => apiAccount.id === this.apiAccountId);

      if(!this.$route.path.includes('dashboard')){
        this.$router.push(`/chart/${apiAccount.exchange_key}/SPOT/${position.symbol}`); // TODO: get market from position
      } else {
        console.log('dashboard showSymbolInChart', this.$parent);
        this.$store.commit('setChartDataSource', {symbol: position.symbol});
        try {
          await this.$parent.$refs.ChartViewResizableBox[0].loadOHLCV();
        } catch (error) {
          console.log('dashboard showSymbolInChart error', error);
        }
      }
        
      
    },
  },
  async mounted() {
    //await this.fetchApiAccounts();
  },
  computed: {
    ...mapState(["apiAccounts"]),
    ...mapGetters([
      "getPortfolioByApiAccountId",
      "getApiAccountsOptions",
      "getPositionsByApiAccountID",
    ]),
    positions() {
      if (!this.apiAccountId) {
        return [];
      }
      return this.getPositionsByApiAccountID(this.apiAccountId);
    },
    headers() {
      return [
        {
          text: "Symbol",
          value: "symbol",
        },
        {
          text: "Side",
          value: "side",
        },
        {
          text: "size",
          value: "size",
        },
        {
          text: "leverage",
          value: "leverage",
        },
        {
          text: "Average Price",
          value: "avgPrice",
        },
        {
          text: "Liquidation Price",
          value: "liqPrice",
        },
        {
          text: "Stop loss",
          value: "stopLoss",
        },
        {
          text: "Take Profit",
          value: "takeProfit",
        },
        {
          text: "Entry Price",
          value: "markPrice",
        },{
          value: "unrealisedPnl",
          text: "Unrealised PNL",
        },
        {
          text: "Action",
          value: "action",
        },
      ];
    },
  },
};
</script>
  
  <style scoped>
.v-data-table__wrapper {
  overflow-x: auto;
}
</style>
  