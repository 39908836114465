import Vue from 'vue';
import Vuex from 'vuex';
import store from './store';
import router from './router';
import App from './App.vue';
import vuetify from './plugins/vuetify'
import VueResize from 'vue-resize'
import 'vue-resize/dist/vue-resize.css'


import * as Sentry from "@sentry/vue";
import './registerServiceWorker'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: "https://13a83659401f47199dc3dae68fad3dfc@sentry.darcade.de/4",
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay()
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}
Vue.use(Vuex);

Vue.use(VueResize)

Vue.config.productionTip = false

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
