<template>
  <v-container>
    <v-card>
      <v-card-title>Actions</v-card-title>
      <v-card-text>

              <v-chip-group column v-model="visibleFields" active-class="primary" multiple>
              <v-chip v-for="header in headers" :key="header.value" :value="header.value" class="ma-2">
                {{ header.text }}
              </v-chip>
            </v-chip-group>
        <v-data-table
          :headers="visibleHeaders"
          :items="getActions"
          :items-per-page="10"
          class="elevation-1"
        ><template v-slot:[`item.enabled`]="{ item }">
              <v-checkbox v-model="item.enabled" @click="toggleActionEnabled(item)"></v-checkbox>
            </template>
          <template v-slot:[`item.created_at`]="{ item }">
            <!-- Show local browser time  -->
            <span>{{ new Date(item.created_at).toLocaleString() }}</span>
          </template>
          <template v-slot:[`item.template_script`]="{ item }">
            <!-- Show local browser time  -->
            <v-chip color="primary">{{ item.template_script }}</v-chip>
          </template>
          <template v-slot:[`item.updated_at`]="{ item }">
            <!-- Show local browser time  -->
            <span>{{ new Date(item.updated_at).toLocaleString() }}</span>
          </template>
          <template v-slot:[`item.parameters`]="{ item }">
            <pre>{{ item.parameters }}</pre>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="showExecuteActionForm(item.id)">
              mdi-play
            </v-icon>
            <v-icon small class="mr-2" @click="editAction(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteActionPrompt(item)">
              mdi-delete
            </v-icon>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" small @click="clearExecutionCount(item)">
              mdi-refresh
            </v-icon>
              </template>
              <span>Reset exectuion count</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="newAction">Create Action</v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog ref="actionFormDialog" v-model="dialog" max-width="500px">
        <ActionForm ref="actionForm" :action-i-d="editedID" @close="close" :form-title="formTitle"/>
    </v-dialog>
    <!-- Are you sure to execute action dialog -->
    <v-dialog v-model="displayExecuteActionForm" max-width="500px">
      <v-card>
        <v-card-title
          >Are you sure to execute this action? -
          {{ executedAction.template_script }}</v-card-title
        >
        <v-card-text>
          Pressing execute will execute action <br /><br />
          Name: <b>{{ executedAction.name }}</b
          ><br /><br />
          Action: <b>{{ executedAction.template_script }}</b
          ><br /><br />
          parameters:
          <pre>{{ executedAction.parameters }}</pre>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            text
            @click="
              displayExecuteActionForm = false;
              executedAction = {};
            "
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="executeActionHandler(executedAction.id)"
          >
            Execute
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
  
  
  <script>
import { mapActions, mapGetters } from "vuex";

import ActionForm from "./ActionForm.vue";

export default {
  components: {  ActionForm },
  data: () => ({
    visibleFields: ['enabled', 'name',  'template_script', 'execution_count', 'parameters', 'actions'],

    displayExecuteActionForm: false,
    currentSchema: {},
    executedAction: {},
    dialog: false,
    editedID: -1,
    
    headers: [
    { text: "Enabled", value: "enabled" },
      { text: "Name", value: "name" },
      { text: "Description", value: "description" },
      { text: "Action Script", value: "template_script" },
      { text: "Execution count", value: "execution_count" },
      { text: "Created At", value: "created_at" },
      { text: "Updated At", value: "updated_at" },
      { text: "Parameters", value: "parameters" },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),

  computed: {
    ...mapGetters([
      "getActions",
      "getActionScripts",
      "getActionScriptsOptions",
      "getApiAccountsOptions",
    ]),
    formTitle() {
      return this.editedID === -1 ? "New Action" : "Edit Action";
    },
    visibleHeaders() {
      return this.headers.filter((header) => this.visibleFields.includes(header.value));
    },
  },

  methods: {
    ...mapActions([
      "fetchActionSchema",
      "fetchActionScripts",
      "createAction",
      "updateAction",
      "deleteAction",
      "fetchActions",
      "fetchApiAccounts",
      "executeAction",
    ]),

    newAction() {
      this.editedID = -1;
      if(this.$refs.actionForm) this.$refs.actionForm.loadForm();
      this.dialog = true;
    },
    async editAction(item) {
      this.editedID = item.id;
      if(this.$refs.actionForm) this.$refs.actionForm.loadForm();
      this.dialog = true;
    },
    

    close() {
      this.editedID = -1;
      if(this.$refs.actionForm) this.$refs.actionForm.loadForm();
      this.dialog = false;
    },

    deleteActionPrompt(item) {
      console.log(
        "🚀 ~ file: ActionsView.vue:148 ~ deleteActionPrompt ~ item:",
        item
      );
      //const index = this.actions.indexOf(item)
      const confirmDelete = confirm(
        "Are you sure you want to delete this action?"
      );

      if (confirmDelete) {
        this.deleteAction(item);
      }
    },


    async clearExecutionCount(item) {
      console.log("clearExecutionCount", item);
      let cAction = Object.assign({}, item);
      cAction.execution_count = 0;
      console.log("clearExecutionCount", cAction);
      await this.updateAction(cAction);
    },
    async toggleActionEnabled(action) {
      console.log("toggleActionEnabled", action);
      let cAction = Object.assign({}, action);
      //cAction.enabled = !cAction.enabled;
      console.log("toggledActionEnabled", cAction);
      await this.updateAction(action);
    },
    showExecuteActionForm(actionId) {
      this.executedAction = this.getActions.find(
        (action) => action.id === actionId
      );
      this.displayExecuteActionForm = true;
      //this.executeAction(actionId);
    },
    submit() {
      this.$refs.form.validate();
    },
    async executeActionHandler(actionId) {
      try {
        this.displayExecuteActionForm = false;
        await this.executeAction(actionId);
      } catch (e) {
        console.log("Error executing action", e);
      }
      
      this.executedAction = {};
    },
  },

  mounted() {
    this.fetchActionScripts();
    //this.$refs.actionFormDialog.attach  =true;
    console.log("🚀 ~ file: ActionsView.vue:234 ~ mounted ~ this.$refs.actionFormDialog:", this.$refs.actionFormDialog)
    console.log("🚀 ~ file: ActionsView.vue:234 ~ mounted ~ this.$refs.actionForm", this.$refs.actionForm)
    //this.fetchApiAccounts();
    //this.fetchActions();
  },
};
</script>
<style>
</style>
  