<template>
  <div class="chartPage" ref="chartPage">
    <!--Toolbar-->
    <v-app-bar id="chartPageToolbar" color="primary" dark app clipped-left clipped-right height="40">
      <v-btn small id="changeChartSrcBtn" @click="displayChartSourceSelection = true">
        <v-icon>mdi-cog</v-icon>
      </v-btn>
      <v-btn small v-if="chartInitialized" id="addOverlayButton" :disabled="!chartInitialized"
        @click="$refs.chartView.showAddOverlayModal()">
        <v-icon>mdi-plus</v-icon>
      </v-btn>

      <v-btn small v-if="chartInitialized" id="refreshButton" :disabled="!chartInitialized" @click="loadChart()">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      <tf-selector ref="timeframeSelector" id="timeframeSelector" v-model="selectedTimeframe" />
    </v-app-bar>
    <!--
    <span id="selectedDrawingId">{{ selectedDrawingId }}</span>
    <span id="selectedDrawingTriggers">{{ selectedDrawingTriggers }}</span>
    <span v-if="lastRefresh" id="lastRefresh"
      ><v-icon x-small>mdi-refresh</v-icon> 
       {{ lastRefresh.toLocaleString() }}</span
    >
    <span v-if="lastChartSave" id="lastChartSave"
      ><v-icon x-small>mdi-content-save</v-icon>
        {{ lastChartSave.toLocaleString() }}</span
    >-->

    <ChartView v-if="layoutLoaded" :exchange="selectedExchange" :market="selectedMarket" :symbol="selectedSymbol"
      :timeframe="selectedTimeframe" v-bind:layout="layout" @onOrOffChartChanged="onOrOffChartChanged" ref="chartView"
      @chartStateChanged="on_chart_state_changed" />


    <v-dialog v-model="displayChartSourceSelection" max-width="300px" style="z-index: 1000">
      <v-card>
        <v-card-title>
          <span class="headline">Chart source</span>
        </v-card-title>
        <v-card-text>
          <v-chip-group column>
            <v-chip v-for="(item, i) in favouriteChartSources" :key="i" :value="item"
              :color="item.symbol == selectedSymbol && item.market == selectedMarket && item.exchange == selectedExchange ? 'primary' : ''"
              @click="selectedExchange = item.exchange; selectedMarket = item.market; selectedSymbol = item.symbol; symbolChanged()">{{
        item.symbol }}
            </v-chip>
          </v-chip-group>
        </v-card-text>
        <v-card-text>
          <v-select v-model="selectedExchange" :items="getExchangeOptions()" label="Select exchange" />
          <v-select :disabled="!selectedExchange" v-model="selectedMarket" label="Select market"
            :items="getExchangeMarketOptions(selectedExchange)" />
          <v-select :disabled="!selectedExchange || !selectedMarket" v-model="selectedSymbol" label="Select symbol"
            :items="getExchangeMarketSymbolOptions(selectedExchange, selectedMarket)
        " @change="symbolChanged()" />
          <!-- Toggle autorefresh -->
          <v-progress-circular v-if="!(chartInitialized || layoutLoaded)" id="ChartSourceSelectionSpinner" indeterminate
            color="primary" size="60" />
        </v-card-text>
        <v-card-text v-if="(chartInitialized || layoutLoaded)">
          <v-text-field v-if="autoRefresh" v-model="autoRefreshTimeframeSec" label="Auto refresh timeframe (seconds)"
            hide-details @change="updateAutoRefresh()"></v-text-field>
          <v-switch v-model="autoRefresh" label="Auto refresh" hide-details @change="updateAutoRefresh()"></v-switch>
        </v-card-text>
        <v-card-text>
          <v-switch v-model="autoSaveOnDrawingChange" label="Auto save on drawing change" hide-details></v-switch>
          <v-switch v-model="showModalOnCreate" label="Show TriggerLine modal on create" hide-details />
          <br />
          <v-btn small :disabled="!selectedExchange || !selectedMarket || !selectedSymbol"
            @click="openDeleteChartDataModal()">
            Delete chart data and reload
          </v-btn>
        </v-card-text>
        <v-card-text v-if="(chartInitialized || layoutLoaded)">
          <PositionSelection @update="positionChanged" />
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" text @click="displayChartSourceSelection = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Confirm delete chart Data modal -->
    <v-dialog v-model="displayDeleteChartData" max-width="300px" style="z-index: 1000">
      <v-card>
        <v-card-title>
          <span class="headline">Delete chart data </span>
          <span><b>{{ selectedExchange }} {{ selectedMarket }}
              {{ selectedSymbol }}</b></span>
        </v-card-title>
        <v-card-text>
          <span>Are you sure you want to delete chart data for ?</span><br /><br />
          <p><span>The layout of the chart will be reset and all drawings will be
              lost.</span></p>
          <p><strong style="color: red;">Warning this will not delete any Actions or Triggers created by
              TriggerLines</strong></p>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" text @click="displayDeleteChartData = false">Cancel</v-btn>
          <v-btn color="success" text @click="deleteChartDataConfirmed()">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import TfSelector from "./TFSelector.vue";
import ChartView from "./ChartView.vue";
const debug = false;
import PositionSelection from "./PositionSelection.vue";

export default {
  name: "ChartPage",
  description: "Should display correct dates for every timeframe",
  components: {
    TfSelector,
    PositionSelection,
    ChartView,
  },
  props: {
  },
  data() {
    return {
      firstLoad: true,
      autoSaveOnDrawingChange: true,

      lastChartSave: null,
      lastRefresh: null,
      autoRefresh: false,
      autoRefreshTimeframeSec: 60 * 5,
      autoRefreshHandler: null,
      favouriteChartSources: [
        {
          exchange: "bybit",
          market: "SPOT",
          symbol: "BTCUSDT"
        },
        {
          exchange: "bybit",
          market: "SPOT",
          symbol: "ETHUSDT"
        },
        {
          exchange: "bybit",
          market: "SPOT",
          symbol: "MATICUSDT"
        },
        {
          exchange: "bybit",
          market: "SPOT",
          symbol: "XRPUSDT"
        },
        {
          exchange: "bybit",
          market: "SPOT",
          symbol: "AVAXUSDT"
        },
        {
          exchange: "bybit",
          market: "SPOT",
          symbol: "APEUSDT"
        },
        {
          exchange: "bybit",
          market: "SPOT",
          symbol: "DOGEUSDT"
        },
        {
          exchange: "bybit",
          market: "SPOT",
          symbol: "ETHBTC"
        },
      ],
      displayDeleteChartData: false,
      chartDataChanged: false,
      displayChartSourceSelection: false,
      selectedExchange: 'bybit',
      selectedMarket: 'SPOT',
      selectedSymbol: 'BTCUSDT',
      selectedTimeframe: '1d',
      chartInitialized: false,
      layoutLoaded: false,
      layout: {
        onchart: [],
        offchart: [],
      },

    };
  },
  computed: {
    ...mapGetters([
      "getChartData",
      "getExchangeOptions",
      "getExchangeMarketOptions",
      "getExchangeMarketSymbolOptions",
      "getTriggersForDrawingId",
      "getApiAccountById",
    ]),
    ...mapState([
      "exchangesDetails",
      "selectedPosition",
    ]),
    layoutIdentifier() {
      return {
        exchange: this.selectedExchange,
        market: this.selectedMarket,
        symbol: this.selectedSymbol,
        timeframe: this.selectedTimeframe,
      };
    },
    showModalOnCreate: {
      get() {
        return this.$store.state.triggerLineOptions.showModalOnCreate;
      },
      set(value) {
        this.$store.commit("setTriggerLineOptions", { showModalOnCreate: value });
      },
    },

  },
  watch: {
    async $route(to) {
      if (debug) console.log("ChartView $route", to);
      this.loadChartIdentificationFromRoute();
    },
    selectedTimeframe() {
      // Update route if needed
      if (this.$route.path !== `/chart/${this.selectedExchange}/${this.selectedMarket}/${this.selectedSymbol}/${this.selectedTimeframe}`) {
        this.$router.replace(`/chart/${this.selectedExchange}/${this.selectedMarket}/${this.selectedSymbol}/${this.selectedTimeframe}`);
      }
    }
  },
  async mounted() {
    // refresh chart every 5 minutes
    //this.updateAutoRefresh();
    //this.refreshPositions();
    this.loadChartIdentificationFromRoute();
    this.fetchTriggers().catch((e) => console.error(e));
    await this.loadChart();
  },
  methods: {
    ...mapActions([
      "fetchTriggers",
    ]),
    loadChartIdentificationFromRoute() {
      if (this.$route.params && this.$route.params.exchange && this.$route.params.market && this.$route.params.symbol && this.$route.params.timeframe) {
        this.selectedExchange = this.$route.params.exchange;
        this.selectedMarket = this.$route.params.market;
        this.selectedSymbol = this.$route.params.symbol;
        this.selectedTimeframe = this.$route.params.timeframe;
        this.symbolChanged();
      }
    },
    symbolChanged() {
      if (debug) console.log("symbolChanged", this.selectedExchange, this.selectedMarket, this.selectedSymbol, this.selectedTimeframe);
      // is route already set ?
      if (this.$route.params.exchange != this.selectedExchange || this.$route.params.market != this.selectedMarket || this.$route.params.symbol != this.selectedSymbol || this.$route.params.timeframe != this.selectedTimeframe) {
        this.$router.push(`/chart/${this.selectedExchange}/${this.selectedMarket}/${this.selectedSymbol}/${this.selectedTimeframe}`);
      }
      this.loadChart().then(() => {
        if (this.$refs.chartView) this.$refs.chartView.resetChart();
      });
    },
    positionChanged() {
      this.$store.dispatch("saveChartData", {
        type: "position",
        data: this.selectedPosition,
        ...this.layoutIdentifier,
      });
    },
    onOrOffChartChanged() {
      if (debug) console.log("onOrOffChartChanged");
      if (this.chartInitialized == false) return;

      //if(debug) console.log("onOrOffChartChanged", onchart, offchart);
      this.debounce(() => {
        this.chartDataChanged = true
        this.storeChart()
      }, 1000)
    },
    debounce(func, delay) {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(func, delay);
    },
    on_selected(tf) {
      if (debug) console.log("on_selected", tf);
      //this.loadOHLCV(tf.name);
      //this.$refs.tradingVue.resetChart();
      this.log_scale = false;
    },
    on_chart_state_changed(state) {
      if (debug) console.log("on_chart_state_changed", state);
      this.chartInitialized = state.chartInitialized;
    },

    async storeChart() {
      const onchart = this.$refs.chartView.chart.get("onchart");
      const offchart = this.$refs.chartView.chart.get("offchart");
      console.warn("Storing chart");
      if (!this.chartInitialized) {
        console.error("storeChart - chart not initialized, ERROR: #5612446234");
        return;
      }
      if (!this.layoutLoaded) {
        console.error("storeChart - chart layout not loaded, ERROR: #561556234");
        return;
      }

      // store onchart
      /*if (onchart.length > 0) {
        for (let i = 0; i < onchart.length; i++) {
          if (onchart[i].settings.$selected) {
            onchart[i].settings.$selected = false;
          }
        }
      }*/

      this.$store.dispatch("saveChartData", {
        type: "onchart",
        data: onchart,
        ...this.layoutIdentifier,
      });
      // store offchart
      this.$store.dispatch("saveChartData", {
        type: "offchart",
        data: offchart,
        ...this.layoutIdentifier,
      });


      this.lastChartSave = new Date();
      this.chartDataChanged = false;
      console.warn("Stored chart");
    },
    async loadChart() {
      try {

        //if(debug)
        if (debug) console.log("start loadChart()");
        this.fetchTriggers().catch((e) => console.error(e));
        // reset position
        //this.$store.commit("resetSelectedPosition");

        //this.layoutLoaded = false;
        //this.chartInitialized = false;
        /*this.layout = {
          onchart: [],
          offchart: [],
        };*/


        // Set current symbol to window title
        window.document.title = `${this.selectedSymbol} - Plutos`;

        // Load onchart
        this.$store.dispatch("fetchChartData", {
          type: "onchart",
          ...this.layoutIdentifier,
        }).then((data) => {
          this.layout.onchart = data
          if (this.firstLoad) {
            // unselect all drawings
            for (let i = 0; i < this.layout.onchart.length; i++) {
              if (this.layout.onchart[i].settings.$selected)
                this.layout.onchart[i].settings.$selected = false;
            }
          }

          if (this.$refs.chartView) this.$refs.chartView.refreshChart()
        })

        // Load offchart
        this.$store.dispatch("fetchChartData", {
          type: "offchart",
          ...this.layoutIdentifier,
        }).then((data) => {
          this.layout.offchart = data

          if (this.$refs.chartView) this.$refs.chartView.refreshChart()
        })

        // load position
        this.$store.dispatch("fetchChartData", {
          type: "position",
          ...this.layoutIdentifier,
        }).then((position) => {
          if (position && position.accountId && position.market && position.symbol) {
            this.$store.commit("setSelectedPosition", position);
          }
        })

        if (debug) console.log("end loadChart()", this.layout.onchart);

        this.layoutLoaded = true;
        this.firstLoad = false;
      } catch (e) {
        console.error("Error in ChartPage: loadChart ", e)
      }
    },
    updateAutoRefresh() {
      if (this.autoRefresh) {
        console.log("Chart auto refresh enabled");
        clearInterval(this.autoRefreshHandler);
        this.autoRefreshHandler = setInterval(async () => {

          console.log("Chart auto refresh", new Date());
          await this.loadChart();

          console.log("Chart auto refresh", new Date());
        }, this.autoRefreshTimeframeSec * 1000);
      } else {
        console.log("Chart auto refresh disabled");
        clearInterval(this.autoRefreshHandler);
      }
    },
    openDeleteChartDataModal() {
      this.displayDeleteChartData = true;
    },
    async deleteChartDataConfirmed() {
      this.displayDeleteChartData = false;
      await this.$store.dispatch("deleteChartData", {
        exchange: this.selectedExchange,
        market: this.selectedMarket,
        symbol: this.selectedSymbol,
        type: "onchart",
      });
      await this.$store.dispatch("deleteChartData", {
        exchange: this.selectedExchange,
        market: this.selectedMarket,
        symbol: this.selectedSymbol,
        type: "offchart",
      });
      await this.loadChart();
    },
  },
};
</script>

<style>
.log-scale {
  position: absolute;
  top: 60px;
  right: 80px;
  color: #888;
  font: 11px -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.chartView {
  width: 100%;
  height: 100%;
}

.v-list {
  z-index: 1000;
}

.chartOuterDiv {
  position: fixed;
  /*top: 120px;
bottom: 0;
left: 0;
right: 0;*/
}

#selectedDrawingId {
  position: absolute;
  bottom: 80px;
  right: 280px;
  z-index: 1;
  color: #888;
  font: 11px -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

#selectedDrawingTriggers {
  position: absolute;
  bottom: 60px;
  right: 280px;
  z-index: 1;
  color: #888;
  font: 11px -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

#lastRefresh {
  position: absolute;
  z-index: 2;
  color: #888;
  font: 11px -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

  @media screen and (max-width: 600px) {
    bottom: 38px;
    right: 62px;
  }

  @media screen and (min-width: 600px) {
    bottom: 40px;
    right: 280px;
  }
}

#lastChartSave {
  position: absolute;
  z-index: 1;
  color: #888;
  font: 11px -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

  @media screen and (max-width: 600px) {
    bottom: 52px;
    right: 62px;
  }

  @media screen and (min-width: 600px) {
    bottom: 60px;
    right: 280px;
  }
}

.container {
  padding: 0;
}

#saveChartBtn {
  position: absolute;

  z-index: 1;

  /* On screens that are 600px or less, show btn at bottom */
  @media screen and (max-width: 600px) {
    bottom: 30px;
    right: 250px;
  }

  /* On screens that are 600px or more, show btn at right */
  @media screen and (min-width: 600px) {
    top: 20px;
    right: 250px;
  }
}

#changeChartSrcBtn {
  z-index: 1;
}

#timeframeSelector {
  @media screen and (max-width: 600px) {}

  @media screen and (min-width: 600px) {}

  top: 5px;
  right: 20px;

  font: 12px -apple-system,
  BlinkMacSystemFont,
  Segoe UI,
  Roboto,
  Oxygen,
  Ubuntu,
  Cantarell,
  Fira Sans,
  Droid Sans,
  Helvetica Neue,
  sans-serif;
}

@media only screen and (max-device-width: 480px) {

  .log-scale,
  .gc-mode {
    right: 50px !important;
  }
}

#chartPageToolbar {

  top: 60px
}

#ChartSourceSelectionSpinner {
  left: 38%;
}
</style>
