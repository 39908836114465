<script>
import { Overlay } from "@plutos/plutos-tradingvuejs";
import { mapState, mapGetters } from "vuex";
export default {
  name: "PositionOverlay",
  mixins: [Overlay],
  data() {
    return {
      positionForCurrentChart: null,
      loading: true
    }
  },
  mounted() {
    this.$store.dispatch("fetchAccountsPositions", this.accountID).then(this.loadPosition);
  },
  methods: {
    meta_info() {
      return {
        author: "StdSquad",
        version: "1.0.0",
        desc: "Position overlay",
        preset: {
          name: "Position", // $accountID',
          side: "onchart",
          settings: {
            lineWidth: 0.75,
            colors: ["#3bb3e4", "#f7046d"],
          },
        },
      };
    },
    loadPosition() {
      const currAccPositions = this.getPositionsByApiAccountID(this.accountID);
      if (
        this.market && this.symbol && currAccPositions[this.market.toLowerCase()]
      ) {
        this.positionForCurrentChart = currAccPositions[this.market.toLowerCase()].find(
          (pos) => pos.symbol.toLowerCase() === this.symbol.toLowerCase()
        );
      } else {
        this.positionForCurrentChart = null;
      }
      this.loading = false;
    },
    drawHorizontalLinePriceWithText(ctx, price, text, color) {
      const layout = this.$props.layout;

      ctx.lineWidth = 1.5;
      ctx.strokeStyle = color;
      ctx.beginPath();
      let x1 = layout.t2screen(0);
      let y1 = layout.$2screen(price);

      ctx.lineTo(x1, y1);

      let x2 = layout.t2screen(111683223200000);
      let y2 = layout.$2screen(this.pricePosition);
      ctx.lineTo(x2, y2);

      // display text
      ctx.font = "12px Arial";
      ctx.fillStyle = color;
      //  display text
      let timestamp = layout.t2screen(new Date().getTime());
      ctx.fillText(text, timestamp, layout.$2screen(price));

      ctx.stroke();
    },

    draw(ctx) {
      if (!this.positionForCurrentChart) {
        return;
      }

      // draw position line
      let displayedText = `${this.positionForCurrentChart.leverage}x ${this.positionForCurrentChart.side} ${this.positionForCurrentChart.size} @ ${this.positionForCurrentChart.avgPrice} `;
      this.drawHorizontalLinePriceWithText(
        ctx,
        this.pricePosition,
        displayedText,
        this.positionLineColor
      );
      // draw stop loss if set
      if (
        this.positionForCurrentChart.stopLoss &&
        Number(this.positionForCurrentChart.stopLoss) > 0
      ) {
        displayedText = `Stop Loss: ${this.positionForCurrentChart.stopLoss}`;
        this.drawHorizontalLinePriceWithText(
          ctx,
          this.positionForCurrentChart.stopLoss,
          displayedText,
          this.stopLossLineColor
        );
      }
      // draw take profit if set
      if (
        this.positionForCurrentChart.takeProfit &&
        Number(this.positionForCurrentChart.takeProfit) > 0
      ) {
        displayedText = `Take Profit: ${this.positionForCurrentChart.takeProfit}`;
        this.drawHorizontalLinePriceWithText(
          ctx,
          this.positionForCurrentChart.takeProfit,
          displayedText,
          this.takeProfitLineColor
        );
      }
      // draw liquidation price
      if (
        this.positionForCurrentChart.liqPrice &&
        Number(this.positionForCurrentChart.liqPrice) > 0
      ) {
        displayedText = `Liquidation Price: ${this.positionForCurrentChart.liqPrice}@${this.positionForCurrentChart.liqPrice} `;
        this.drawHorizontalLinePriceWithText(
          ctx,
          this.positionForCurrentChart.liqPrice,
          displayedText,
          this.stopLossLineColor
        );
      }
    },
    legend() {
      if (this.loading) {
        return [{ value: "Fetching position..." }];
      }
      if (
        !this.selectedPosition ||
        !this.selectedPosition.accountId ||
        !this.selectedPosition.market ||
        !this.selectedPosition.symbol
      ) {
        return [
          {
            value: "No position selected",
          },
        ];
      } else if (!this.positionForCurrentChart) {
        return [
          {
            value: `No position for ${this.accountName} ${this.market} ${this.symbol}`,
          },
        ];
      }

      return [
        {
          value: this.accountName,
        },
        {
          value: `${this.market} ${this.symbol}`,
          color: this.positionLineColor,
        },
        {
          value: `${this.positionForCurrentChart.side} ${this.positionForCurrentChart.size} @ ${this.positionForCurrentChart.avgPrice}`,
          color:
            this.positionForCurrentChart.unrealisedPnl > 0
              ? "#63df89"
              : "#e11FF1",
        },
        {
          value: "unrealized Pnl:" + this.positionForCurrentChart.unrealisedPnl,
          color:
            this.positionForCurrentChart.unrealisedPnl > 0
              ? "#63df89"
              : "#e11FF1",
        },
      ];
    },
    use_for() {
      return ["PositionOverlay"];
    },
    calc() {
      return {
      };
    },
  },
  computed: {
    ...mapState(["chartDataSource", "selectedPosition"]),
    ...mapGetters(["getPositionsByApiAccountID"]),

    pricePosition() {
      return this.positionForCurrentChart.avgPrice;
    },
    accountID() {
      return this.selectedPosition.accountId;
    },
    accountName() {
      const account = this.$store.getters.getApiAccountById(this.accountID);
      return account ? account.name : "";
    },
    market() {
      return this.selectedPosition.market;
    },
    symbol() {
      return this.selectedPosition.symbol;
    },
    positionLineColor() {
      return this.positionForCurrentChart.side === "Sell"
        ? "#f7046d"
        : "#3bb3e4";
    },
    stopLossLineColor() {
      return "#f7046d";
    },
    takeProfitLineColor() {
      return "#3bb3e4";
    },
  },
};
</script>