<template>
  <div class="stream-view">
    <span id="countdown">{{ countDown }} seconds untill {{ nextSymbol }}</span>
    <ChatView ref="chartview" />
  </div>
</template>

<script>
import ChatView from "./ChartView.vue";
export default {
  name: "StreamView",
  components: {
    ChatView,
  },

  methods: {
    async updateChart() {
      try {
        console.log("this.currSymbolIndex", this.currSymbolIndex);
        this.currSymbolIndex =
          (this.currSymbolIndex + 1) % this.listeningSymbols.length;
        const { exchange, market, symbol } =
          this.listeningSymbols[this.currSymbolIndex];
        this.$store.commit("setChartDataSource", { exchange, market, symbol });

        if (this.$refs.chartview) {
          await this.$refs.chartview.updateChart();
        }
        // wait for 10 seconds
        this.countDown = 60;
        while (this.countDown > 0) {
          await new Promise((resolve) => setTimeout(resolve, 1000));
          this.countDown -= 1;
        }
        
        await this.updateChart();
      } catch (error) {
        console.log('ERRRRRRRRRRRRRRRRRRRRRRRRRRRRRRROOOOOOOOOOOOR',error);
      }
    },
  },
  data() {
    return {
      streamStatus: null,
      currSymbolIndex: 0,
      countDown: 0,
      chartUpdateInitiated: false,
    };
  },
  async mounted() {
    this.streamStatus = await this.$store.dispatch("fetchStreamStatus");
    console.log("------------------", this.listeningSymbols);
    if (this.chartUpdateInitiated) return;
    this.chartUpdateInitiated = true;
    //await this.updateChart();
  },
  computed: {
    nextSymbol() {
      if (!this.listeningSymbols) return "";
      let nextSymbolIndex = this.currSymbolIndex + 1;
      if (this.currSymbolIndex === this.listeningSymbols.length - 1) {
        nextSymbolIndex = 0;
      }
      if (this.listeningSymbols.length === 1) {
        nextSymbolIndex = 0;
      }
      if (this.listeningSymbols.length === 0) {
        return "";
      }
      
      const { exchange, market, symbol } =
        this.listeningSymbols[nextSymbolIndex];
      return `${exchange}:${market}:${symbol}`;
    },
    listeningSymbols() {
        if (!this.streamStatus) return [];
      return this.streamStatus.map((status) => {
        const key = status.key;
        const [exchange, market, symbol, timeframe] = key.split("|");
        return {
          exchange,
          market,
          symbol,
          timeframe,
        };
      });
    },
  },
};
</script>

<style>
#countdown {
    z-index: 100;
  position: absolute;
  bottom: 100px;
  right: 100px;
  padding: 10px;
  background-color: #000;
  color: #fff;
}
</style>