<template>
  <v-app v-if="isLoggedIn() && !isStreamRoute">
    <v-navigation-drawer
      app
      v-model="sidebar"
      :clipped="clipped"
      :disable-resize-watcher="true"
      style="z-index: 1000"
    >
      <!-- logo -->
      <v-row>
        <v-col cols="5">
          <img
            style="margin: 20px"
            src="img/icons/Plutos_Logo_Transparent.png"
            alt="Plutos logo"
            width="80px"
            height="80px"
          />
        </v-col>
        <v-col cols="7">
          <h3 style="margin-top: 12px">Plutos</h3>
          <span style="font-size: smaller"
            >Elevate Your Trades with Algorithmic Precision!</span
          >
        </v-col>
      </v-row>

      <v-list dense>
        <!-- Dashboard selection  -->
        <v-list-item
          :to="{ path: '/dashboard/' + dash.value }"
          v-for="dash in getDashboardsOptions()"
          :key="dash.value + dash.text"
          exact
        >
          <v-list-item-action>
            <v-icon>mdi-view-dashboard-variant</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ dash.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-for="(item, i) in items" :key="i" :to="item.to" exact>
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <div id="navDrawerUser">
          <v-divider></v-divider>
          <v-list-item v-if="isLoggedIn()">
            <v-list-item-action>
              <v-icon>mdi-account</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{
                currentUser().username
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="!isLoggedIn()" @click="loginDialog = true">
            <v-list-item-action>
              <v-icon>mdi-login</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Login</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="isLoggedIn()" @click="logout()">
            <v-list-item-action>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </div>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app color="primary" dark>
      <v-app-bar-nav-icon @click="sidebar = !sidebar"></v-app-bar-nav-icon>

      <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-progress-circular v-if="loadingCounter > 0" indeterminate color="teal"
        ><!--{{ loadingCounter }}--></v-progress-circular
      >

      <v-menu
        v-model="showEventsDialog"
        z-index="9999"
        offset-y
        max-height="600px"
        max-width="400px"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on }">
          <v-btn icon @click="showEventsDialog = !showEventsDialog" v-on="on">
            <v-icon>mdi-bell</v-icon>
            <span
              v-if="getUnviewedEventsCount() > 0"
              class="notification-badge"
              >{{ getUnviewedEventsCount() }}</span
            >
          </v-btn>
        </template>

        <v-card>
          <v-card-title> Notifications </v-card-title>
          <v-card-text>
            <EventView hideTimeframeSelect="true" />
          </v-card-text>
        </v-card>
      </v-menu>

      <!-- Set night or day mode -->
      <v-btn icon @click="toggleDarkMode">
        <v-icon>{{
          dark ? "mdi-white-balance-sunny" : "mdi-weather-night"
        }}</v-icon>
      </v-btn>

    </v-app-bar>
    <!--
      TODO finish this
    <v-list-group v-if="item.title == 'Dashboard'">
              <v-list-item
                :to="{ name: 'Dashboard' }"
                v-for="(dash, i) in getDashboardsOptions()"
                :key="i"
                exact
              >
                <v-list-item-action>
                  <v-icon>mdi-monitor-dashboard</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ dash.text }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>-->
    <v-main ref="main">
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>

    <v-snackbar
      v-model="isSnackbarVisible"
      :timeout="snackbarTimeout"
      :color="snackbarColor"
    >
      {{ snackbarText }}
    </v-snackbar>
  </v-app>
  <v-app id="streamPage" v-else-if="isStreamRoute">

    <v-main ref="main">
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>


  </v-app>
  <v-app id="loginPage" v-else>
    <v-main>
      <v-container>
        <v-row class="loginRow">
          <v-col cols="12" sm="12">
            <v-app-bar app color="primary" dark>
              <v-app-bar-nav-icon>
                <v-icon x-large>mdi-chart-box</v-icon></v-app-bar-nav-icon
              >
              <v-toolbar-title>Plutos plattform</v-toolbar-title>
            </v-app-bar>
          </v-col>
          <v-col cols="12" sm="10">
            <v-card>
              <v-card-title class="headline">Login</v-card-title>
              <v-card-text>
                <v-form @submit.prevent="login">
                  <v-text-field
                    v-model="username"
                    label="Username"
                  ></v-text-field>
                  <v-text-field
                    v-model="password"
                    label="Password"
                    type="password"
                  ></v-text-field>
                  <v-btn color="primary" type="submit">Login</v-btn>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import EventView from "./components/EventView.vue";

export default {
  name: "App",
  components: {
    EventView,
  },
  data: () => ({
    username: "",
    password: "",
    sidebar: false,
    clipped: false,
    showEventsDialog: false,
    dark: false,
    items: [
      { icon: "mdi-monitor-dashboard", title: "Dashboard", to: "/dashboard" },
      { icon: "mdi-finance", title: "Chart", to: "/chart" },

      { icon: "mdi-chart-sankey", title: "Positions", to: "/positions" },
      { icon: "mdi-chart-arc", title: "Portfolio", to: "/portfolio" },
      { icon: "mdi-calendar-multiple-check", title: "Events", to: "/events" },

      { icon: "mdi-alarm-check", title: "Trigger", to: "/triggers" },
      { icon: "mdi-file-chart-check", title: "Actions", to: "/actions" },

      {
        icon: "mdi-account-multiple",
        title: "Api accounts",
        to: "/api-accounts",
      },
    ],
  }),

  computed: {
    ...mapState([
      "snackbarVisible",
      "snackbarText",
      "snackbarTimeout",
      "snackbarColor",
      "loadingCounter",
    ]),
    isDashboardRoute() {
      return this.$route.path.startsWith("/dashboard");
    },
    isChartviewRoute() {
      return this.$route.path.startsWith("/chart");
    },
    selectedDashboard: {
      get() {
        return this.$store.state.selectedDashboard;
      },
      set(value) {
        //console.log("set selectedDashboard", value);
        this.$store.commit("setSelectedDashboard", value);
      },
    },
    isSnackbarVisible: {
      get() {
        return this.snackbarVisible;
      },
      set() {
        //console.log("set snackbarVisible to hide", value);
        this.$store.commit("showSnackbar", { hide: true });
      },
    },
    isStreamRoute() {
      return this.$route.path.startsWith("/stream");
    },
  },
  watch: {
    $route(to) {
      if (to.path.startsWith("/dashboard")) {
        //this.selectedDashboard = to.params.id;
      }
    },
  },
  mounted() {
    //console.log("App mounted");
    const tasks = [
      this.$store.dispatch("fetchApiAccounts"),
      this.$store.dispatch("fetchExchanges"),
      this.$store.dispatch("fetchActions"),
      this.$store.dispatch("fetchTriggers"),
      this.$store.dispatch("fetchAllDashboards"),
      new Promise((resolve) => {
        // P-42 workaround for v-menu of notifications being out of screen
        this.showEventsDialog = true;
        setTimeout(() => {
          this.showEventsDialog = false;
          resolve();
        }, 100);
      }),
    ];
    Promise.all(tasks).then(() => {
      //console.log("Finished loading accounts, exchanges, actions, triggers and dashboards from server");
    }).catch((err) => {
      console.error(err);
      alert("Failed to load accounts, exchanges, actions, triggers and dashboards from server");
    });
  },
  methods: {
    ...mapGetters("auth", ["isLoggedIn", "currentUser"]),
    ...mapGetters(["getDashboardsOptions", "getUnviewedEventsCount"]),
    ...mapGetters([
      "fetchApiAccounts",
      "fetchExchanges",
      "fetchActions",
      "fetchTriggers",
    ]),
    changeDashboard(dashboardId) {
      //console.log("changeDashboard", dashboardId);
      this.$router.push(`/dashboard/${dashboardId}`);
    },
    login() {
      const user = {
        username: this.username,
        password: this.password,
      };

      this.$store
        .dispatch("auth/login", user)
        .then(() => {
          this.loginDialog = false;
          this.username = "";
          this.password = "";
          this.$router.push("/chart");
          window.location.reload();
        })
        .catch((err) => {
          console.error(err);
          alert("Failed to log in");
        });
    },

    logout() {
      this.$store
        .dispatch("auth/logout")
        .then(() => {
          //alert("Logged out successfully");
          console.log("Logged out successfully");
        })
        .catch((err) => {
          console.error(err);
          alert("Failed to log out");
        });
    },
    toggleDarkMode() {
      this.dark = !this.dark;
      this.$vuetify.theme.dark = this.dark;
    },
  },
};
</script>
<style>
.notification-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background-color: red;
  color: white;
  border-radius: 50%;
  font-size: 12px;
  font-weight: bold;
}
#loginLogoIcon {
  font-size: 140px;
  color: #000;
}
#navDrawerUser {
  bottom: 10px;
  position: absolute;
}
</style>