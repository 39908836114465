<template>
  <div>
    <v-container style="max-width: 600px">
      <h3 v-if="events && events.length == 0">No events found.</h3>
      <div v-else>
        <!--<v-select
            v-model="actionFilter"
            :items="getActionsSelectItems"
            label="Filter Actions:"
          />-->
        <v-menu
          ref="dateFilterMenu"
          v-model="dateFilterMenu"
          :close-on-content-click="false"
          :return-value.sync="dateFilter"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template #activator="{ on, attrs }">
            <v-row v-if="hideTimeframeSelect == false">
              <v-col cols="6">
                <v-text-field
                  v-model="dateFilter"
                  label="Date filter"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                />
              </v-col>
              <v-col cols="6">
                <!-- page size -->
                <v-select
                  v-if="getFilteredEvents && getFilteredEvents.length > 0"
                  v-model="pageSize"
                  :items="pageSizes"
                  label="Page Size"
                  class="my-2"
                ></v-select>
              </v-col>
            </v-row>
          </template>
          <v-date-picker v-model="dateFilter" no-title scrollable range>
            <v-spacer />
            <v-btn text color="primary" @click="dateFilterMenu = false">
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.dateFilterMenu.save(dateFilter)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>

        <v-row>
          <v-col cols="6" md="">
            <v-btn
              :disabled="
                getFilteredPageEvents.length == 0 || !onlyDisplayNotViewed
              "
              small
              @click="
                markEventsAsViewed(getFilteredPageEvents.map((e) => e.id))
              "
            >
              <v-icon>mdi-eye-off</v-icon>
              All viewed
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-checkbox
              id="onlyDisplayNotViewed"
              v-model="onlyDisplayNotViewed"
              @change="$forceUpdate()"
              label="Only display not viewed"
            ></v-checkbox>
          </v-col>
        </v-row>
        <!--<v-btn small @click="resetFilters"> Reset all filters </v-btn>-->
        <h3
          v-if="
            getFilteredEvents &&
            getFilteredEvents.length == 0 &&
            onlyDisplayNotViewed
          "
        >
          No not viewed events found.
        </h3>
        <v-timeline
          v-if="groupedEvents && groupedEvents.length > 0"
          dense
          clipped
        >
          <template v-for="(dateGroup, index) in groupedEvents">
            <v-timeline-item
              :color="getDateGroupColor(dateGroup)"
              :key="index"
              hide-dot
            >
              <v-row>
                <v-col cols="12">
                  <strong>{{ formatDateLabel(dateGroup[0].timestamp) }}</strong>
                </v-col>
              </v-row>
            </v-timeline-item>
            <v-timeline-item
              v-for="event in dateGroup"
              :key="event.id"
              :color="getEventColor(event)"
              small
            >
              <v-row v-if="event && event.actionObj" justify="space-between">
                <v-col cols="12">
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <h2 v-on="on">
                        #{{ event.actionObj.ID }} |
                        {{ event.actionObj.actionType }}
                      </h2>
                    </template>

                    <div v-if="event.actionObj">
                      <span
                        v-for="actionKey of Object.keys(event.actionObj)"
                        :key="actionKey"
                      >
                        {{ actionKey }} :
                        <strong> {{ event.actionObj[actionKey] }}</strong
                        ><br />
                      </span>
                    </div>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-row justify="space-between" style="margin-top: 0">
                <v-col cols="8" style="padding-bottom: 0; padding-top: 0">
                  <span>{{ event.type }}</span>

                  <!--<a v-if="!event.viewed" @click="markEventsAsViewed([event.id])"> Mark viewed </a> -->
                  <v-icon
                    style="margin-left: 6px"
                    v-if="!event.viewed"
                    @click="markEventsAsViewed([event.id])"
                    >mdi-eye-off</v-icon
                  >
                </v-col>
                <v-col
                  class="text-right"
                  cols="4"
                  style="padding-bottom: 0; padding-top: 0"
                >
                  <small
                    ><!--{{ new Date(event.timestamp).toLocaleDateString("de-DE") }}
                  <br />-->
                    {{
                      new Date(event.timestamp).toLocaleTimeString("de-DE")
                    }}</small
                  >
                </v-col>
              </v-row>
              <v-row justify="space-between">
                <v-col
                  v-if="event && event.action"
                  cols="12"
                  style="padding-top: 0"
                >
                  <!--Tooltip will copy raw_data on click to clipboard-->
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-chip
                        color="cyan"
                        small
                        v-on="on"
                        @click="copyToClipboard(event.raw_data)"
                      >
                        {{ getActionById(event.action).name }} ({{
                          getActionById(event.action).template_script
                        }})
                      </v-chip>
                    </template>
                    <div v-if="event.raw_data">
                      <pre>{{ printRawData(event) }}</pre>
                      <br />
                    </div>
                  </v-tooltip>
                  <div class="eventParamsBlock">
                    <v-row>
                      <v-col cols="6">
                      <!-- Market & Symbol if avail  TODO symbol into chart at click-->
                      <v-chip
                      
                      v-if="getActionById(event.action).parameters.market && getActionById(event.action).parameters.symbol"
                      small
                    >
                      {{
                        getActionById(event.action).parameters.market +
                          " " +
                          getActionById(event.action).parameters.symbol
                      }}
                    </v-chip>
                    </v-col>
                      <v-col cols="6">
                        <div v-for="param in Object.keys(
                        getActionById(event.action).parameters
                      )"
                      :key="event.id + param"
                    >
                      <v-chip small v-if="param !== 'market' && param !== 'symbol'">
                        
                        {{
                          param == "quantity" || param == "executeOnce"
                            ? param + ":"
                            : ""
                        }}
                        {{ getActionById(event.action).parameters[param] }}
                      </v-chip>
                      </div>
                    </v-col>
                    
                    </v-row>
                    
                    
                    
                  </div>

                  <!--<v-chip
                    v-if="event.actionObj.percent"
                    small
                  >
                    {{ event.actionObj.percent }}%
                  </v-chip>
                  <v-chip
                    v-if="event.actionObj.orderPrice"
                    small
                  >
                    Price:{{ event.actionObj.orderPrice }}
                  </v-chip>
                  <v-chip
                    v-if="event.actionObj.orderQuantity"
                    small
                  >
                    Quantity:{{ event.actionObj.orderQuantity }}
                  </v-chip>
                  <v-chip
                    v-if="event.actionObj.orderType"
                    small
                  >
                    {{
                      event.actionObj.orderType
                    }}
                  </v-chip>
                  <v-chip
                    v-if="event.actionObj.triggerPrice"
                    small
                  >
                    Trigger:{{ event.actionObj.triggerPrice }}
                  </v-chip>
                  <v-chip
                    v-if="event.exchangeResponse && event.exchangeResponse.data && event.exchangeResponse.data.price"
                    :color="event.exchangeResponse.data.side == 'Buy' ? 'green' : 'red'"
                    small
                  >
                    at price {{ event.exchangeResponse.data.price }}
                  </v-chip>-->
                </v-col>
              </v-row>
            </v-timeline-item>
          </template>
        </v-timeline>
        <v-pagination
          v-if="getFilteredEvents && getFilteredEvents.length > 0"
          :length="Math.ceil(getFilteredEvents.length / pageSize)"
          :total-visible="5"
          :current-page.sync="currentPage"
          v-model="currentPage"
        />
      </div>
    </v-container>
  </div>
</template>
  
  <script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  props: ["hideTimeframeSelect"],
  data() {
    return {
      onlyDisplayNotViewed: true,
      actionFilter: null,
      dateFilter: null,
      dateFilterMenu: false,
      currentPage: 1,
      pageSize: 10,
      pageSizes: [5, 10, 20, 50, 100],
    };
  },
  computed: {
    ...mapGetters(["getActionById"]),
    ...mapState(["events"]), //, 'actions', 'error', 'networkStatus']),
    groupedEvents() {
      const grouped = {};
      this.getFilteredPageEvents.forEach((event) => {
        const date = new Date(event.timestamp).toLocaleDateString("de-DE");
        if (!grouped[date]) {
          grouped[date] = [];
        }
        grouped[date].push(event);
      });

      return Object.values(grouped);
    },
    getActionsSelectItems() {
      const actionOptions = [
        {
          value: null,
          text: `All`,
        },
      ];
      for (const action of this.actions) {
        actionOptions.push({
          value: action.ID,
          text: `#${action.ID} - ${action.name}`,
        });
      }
      return actionOptions;
    },

    getFilteredEvents() {
      let filteredEvents = this.events
        .filter(
          function (event) {
            let actionMatching = true;
            let dateMatching = true;
            if (this.actionFilter) {
              actionMatching = false;
              if (event && event.a && event.a.ID == this.actionFilter) {
                actionMatching = true;
              }
            }
            if (this.onlyDisplayNotViewed) {
              if (event && event.viewed) {
                // If event is viewed, we don't want to display it
                return false;
              }
            }
            if (this.dateFilter && this.dateFilter.length == 2) {
              console.log("Checking date for filter");
              const dateRange = this.dateFilter;
              let low = +new Date(dateRange[0]);
              let high = +new Date(dateRange[1]);
              if (+new Date(low) > +new Date(high)) {
                (low = +new Date(dateRange[1])),
                  (high = +new Date(dateRange[0]));
              }
              dateMatching = false;
              if (event && event.t) {
                if (event.t > low && event.t < high) dateMatching = true;
              }
            }
            return actionMatching && dateMatching;
          }.bind(this)
        )
        .sort(function (a, b) {
          return b.t - a.t;
        });

      // Order by date
      // TODO order selected by user
      filteredEvents.sort(function (a, b) {
        const keyA = new Date(a.timestamp);
        const keyB = new Date(b.timestamp);
        // Compare the 2 dates

        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;
        return 0;
      });

      return filteredEvents;
    },
    getFilteredPageEvents() {
      let filteredEvents = this.getFilteredEvents;

      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;

      filteredEvents = filteredEvents.slice(startIndex, endIndex);
      console.log("Returning events", this.events);
      console.log("Returning filteredEvents", filteredEvents);
      return filteredEvents;
    },
  },
  mounted() {
    this.fetchEvents();
    this.fetchActions();
  },
  methods: {
    ...mapActions(["fetchEvents", "fetchActions", "markEventsAsViewed"]),
    formatDateLabel(date) {
      return new Date(date).toLocaleDateString("de-DE", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    getEventColor(event) {
      return event && event.type && event.type.includes("error")
        ? "red"
        : "green";
    },
    getDateGroupColor(dateGroup) {
      const event = dateGroup[0]; // Assuming all events in a date group have the same color logic
      return event && event.type && event.type.includes("error")
        ? "red"
        : "green";
    },
    copyToClipboard(text) {
      console.log("Copying to clipboard", text);
      navigator.clipboard.writeText(text);
    },
    formatDateInput(dateRange) {
      console.log("Formatting date", dateRange);
      let low = dateRange[0];
      let high = dateRange[1];
      if (+new Date(low) > +new Date(high)) {
        (low = dateRange[1]), (high = dateRange[0]);
      }
      return low + " to " + high;
    },
    printRawData(event) {
      console.log("Printing raw data", event);
      try {
        return JSON.stringify(JSON.parse(event.raw_data), null, 2);
      } catch (e) {
        return event.raw_data;
      }
    },
    resetFilters() {
      console.log("Resetting filters");
      this.actionFilter = null;
      this.dateFilter = null;
    },
  },
};
</script>
  
  <style>
.container {
  padding-top: 0;
}
.vcard__title {
  padding-bottom: 0;
}
.v-input {
  margin-top: 0 !important;
}
</style>
  