<script>
import Overlay from "@plutos/plutos-tradingvuejs/src/mixins/overlay";
import { mapActions, mapState } from "vuex";
import drawingTools from '@/components/chart_addins/common/drawingTools.js'
export default {
  name: "AccountOrderHistory",
  mixins: [Overlay],

  data() {
    return {
      orderHistory: null,
      loading: true,
    }
   },
  async mounted() {
    await this.loadHistory();
  },
  methods: {
    ...mapActions(["fetchOrderHistory"]),
    meta_info() {
            return {
                author: 'StdSquad', version: '1.0.0',
                desc: 'Account Order History',
                preset: {
                    name: 'Orders',// $accountID',
                    side: 'onchart',
                    settings: {
                        lineWidth: 0.75,
                        colors: ["#3bb3e4", "#f7046d"]
                    }
                }
            }
        },
    draw(ctx) {
      let layout = this.$props.layout;
      ctx.strokeStyle = "black";
      if (!this.orderHistory) {
        return;
      }
      //console.log("🚀 ~ file: AccountOrderHistory.vue: orderHistory", this.orderHistory)
      for (var order of this.orderHistory) {
        if(order.side == "Buy"){
          ctx.fillStyle = this.buy_color;
        } else {
          ctx.fillStyle = this.sell_color;
        }
        let x = layout.t2screen(order.timestamp); // x - Mapping
        let y = layout.$2screen(order.avgPrice); // y - Mapping

        //if (p[3] === "Stop") {
        //  this.draw_cross(ctx, x, y);
        //} else {
          drawingTools.draw_circle(ctx, x, y, this.marker_size);
        //}
        drawingTools.draw_label(ctx, x, y, order, this.label_color, this.new_font);
        /*if (this.show_label && p[3] && p[3] !== "Stop") {
          this.draw_label(ctx, x, y, p);
        }*/
      }
    },
    async loadHistory() {
      if (!this.apiAccountId || !this.market || !this.symbol) {
        console.warn("No account, market or symbol selected");
        return;
      }

      // only load if last fetch is older than 5 seconds
      if (
        this.lastHistoryFetch &&
        Date.now() - this.lastFetch < 5000
      ) {
        console.log("Skipping history for", this.apiAccountId, this.market, this.symbol);
        return;
      }

      console.log("Loading history for", this.apiAccountId, this.market, this.symbol);
      this.orderHistory = await this.fetchOrderHistory({
        apiAccountId: this.apiAccountId,
        market: this.market,
        symbol: this.symbol,
      });

      this.lastHistoryFetch = Date.now();

      this.loading = false;
    },

    
    use_for() {
      return ["AccountOrderHistory"];
    },
    

    // Defines legend format (values & colors)
    legend(values) { 
      if (this.loading) {
        return [{
          value: "Fetching order history...",
        }]
      }
      if(!this.selectedPosition || !this.selectedPosition.accountId || !this.selectedPosition.market || !this.selectedPosition.symbol) {
        return [{
          value: "No position selected",
        }];
      }
      
      const timestamp = values[0];
      if(!this.orderHistory || this.orderHistory.length == 0)
        return [{
          value: `No order history for ${this.accountName} ${this.market} ${this.symbol}`
        }];
      // find nearest order
      const order = this.orderHistory.reduce((prev, curr) => {
        return Math.abs(curr.timestamp - timestamp) < Math.abs(prev.timestamp - timestamp) ? curr : prev;
      });
      //console.log("🚀 ~ file: AccountOrderHistory.vue:99 ~ legend ~ values:", values)
      

      return [
      {
          value: `${this.accountName}`
        },
        {
          value: `${this.market} ${this.symbol}`,
          color: '#3bb3e4'
        },
        {
          value: `${order.side} ${order.status} ${order.amount} @ ${order.avgPrice}`,
          color: `${order.side == "Buy" ? this.buy_color : this.sell_color}`
        }
      ]
    },
     availAccountsString(){ 
      return this.apiAccounts && this.apiAccounts.map((account) => account.name+"("+account.id+")").join(",")
    },
    calc() {
      //Was not reachable maybe use template
      //console.log(this.availAccountsString())
            return {
              /*
                props: {
                    accountID: { def: this.defaultAccount, text: 'AccountID ' },
                    market: { def: this.defaultMarket, text: 'Market' },	
                    symbol: { def: this.defaultSymbol, text: 'Symbol' },
                },*/
                //conf: { renderer: 'Splines' },
                update: ``//`return 30000`
            }
        }
  },
  watch: {
    async apiAccountId() {
      await this.loadHistory();
    },
    async market() {
      await this.loadHistory();
    },
    async symbol() {
      await this.loadHistory();
    },
  },
  // Define internal setting & constants here
  computed: {
    ...mapState(["apiAccounts", "selectedPosition"]),
    apiAccountId() {
      return this.selectedPosition.accountId
    },
    accountName() {
      const account = this.$store.getters.getApiAccountById(this.apiAccountId)
      return account ? account.name : ""
    },
    market() {
      return this.selectedPosition.market
    },
    
    symbol() {
      return this.selectedPosition.symbol
    
    },

    sett() {
      return this.$props.settings;
    },
    default_font() {
      return "12px " + this.$props.font.split("px").pop();
    },
    buy_color() {
      return this.sett.buyColor || "#63df89";
    },
    sell_color() {
      return this.sett.sellColor || "#ec4662";
    },
    label_color() {
      return this.sett.labelColor || this.colors.text;
    },
    marker_size() {
      return this.sett.markerSize || 5;
    },
    show_label() {
      return this.sett.showLabel !== false;
    },
    new_font() {
      return this.sett.font || this.default_font;
    },
  },
};
</script>